import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { CustomLogging } from '../utils/CustomLogging';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }
  
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure  
      let erro = `${operation} Failed: ${error.message}`
      new CustomLogging(erro, 'red', '0.9rem').log();

      switch (error instanceof HttpErrorResponse) {
        case error.status == 404: {
          return throwError(error);
        }
        case error.status == 401: {
          return throwError(error);
        }
        // Let the app keep running by returning an empty result.
        default: {
          return of(result as T);
        }
      }
    };
  }
  
}
