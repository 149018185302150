<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="projects-section">

<div class="section-header">
  <h1 class="h2"> {{title}} </h1> 
</div>
  
<div class="form-group col-md-12">
  <label for="SistCod">Sistema</label>
  <ng-select appearance="outline" id="SistCod" labelForId="SistCod" [(ngModel)]="sistCod" class="col-md-4"
    (change)="onChangeSistema($event)" placeholder="Selecione...">
    <ng-option *ngFor="let otp of optSistema" [value]="otp.Cod">
      {{ otp.Nome }}
    </ng-option>
  </ng-select>
</div>
<div>

<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">

  <ngx-spinner size="medium" type="pacman"></ngx-spinner>

  <div class="btn-toolbar mb- mb-md-0">

    <div class="input-group me-2">
      <input type="text" class="form-control form-control-sm ml-2" placeholder="Pesquisar" name="search"
        [(ngModel)]="search" />

      <button class="btn btn-sm btn-outline-secondary" type="button" (click)="loadData()" placement="top"
        ngbTooltip="Pesquisar">
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>

    </div>

    <div class="btn-group me-2">

      <button class="btn btn-sm btn-outline-primary" type="button" (click)="onRefresh()" placement="top"
        ngbTooltip="Refresh">
        <i class="fa fa-refresh"></i>
      </button>

      <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button class="btn btn-sm btn-outline-secondary" ngbDropdownToggle>{{pageSize}}</button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button *ngFor="let opt of optionsQtd" ngbDropdownItem (click)="onSelectedQtd(opt)">{{ opt }}</button>
        </div>
      </div>

      <button class="btn btn-sm btn-outline-success" [routerLink]="['/operacao-rota-form']" placement="top" ngbTooltip="Novo">
        <i class="fa fa-plus"></i>
      </button>

    </div>

  </div>
</div>

<div class="table-responsive">
  <table class="table table-striped table-hover">
    <thead class="table-dark">
      <tr>
        <th scope="col" sortable="SistCod" (sort)="onSort($event)">Sistema</th>
        <th scope="col" sortable="Cod" (sort)="onSort($event)">Cod</th>
        <th scope="col" sortable="Descricao" (sort)="onSort($event)">Descrição</th>
        <th scope="col">Opções</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data ">
        <th>{{ item.Sistema.Nome }}</th>
        <th>{{ item.Cod }}</th>
        <td>{{ item.Descricao }}</td>
        <td>
          <button class="btn btn-sm btn-outline-danger me-2" (click)="open(content, item )" placement="top"
            ngbTooltip="Excluir">
            <i class="fa fa-fw fa-trash"></i>
          </button>
          <a [routerLink]="['/operacao-rota-form', item.SistCod, item.Cod]" class="btn btn-sm btn-outline-info me-2"
            placement="top" ngbTooltip="Editar">
            <i class="fa fa-fw fa-edit"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-between p-2">
  <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadData()">
  </ngb-pagination>
  <!--<select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (change)="loadData($event)">
    <option [ngValue]="5">5</option>
    <option [ngValue]="10">10</option>
    <option [ngValue]="15">15</option>
    <option [ngValue]="20">20</option>
  </select>-->
</div>

</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deseja excluir registro?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <p>Código: {{selected.Cod}}</p>
    <p>Nome: {{selected.Sistema.Nome }}</p>
    <p>Descrição: {{selected.Descricao}}</p>
  </div>
  <div class="modal-footer">
    
    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" style="height: 40px;" class="custom-btn custom-btn-vermelho" (click)="modal.close()">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" style="height: 40px;" class="custom-btn" (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>
    
  </div>
</ng-template>