<div class="projects-section">

  <div class="section-header">
    <h1 class="h2"> {{title}} </h1>
  </div>

  <div [hidden]="saved" style="width: 400px;">
    <form [formGroup]="modelForm" (ngSubmit)="onSubmit()" novalidate>

      <div class="mb-3">
        <label class="form-label" for="Nome">Nome</label>
        <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nome.errors }" id="Nome"
          formControlName="Nome" name="Nome" />
        <div *ngIf="submitted && f.Nome.errors" class="invalid-feedback">
          <div *ngIf="submitted && f.Nome.errors.required">Nome é obrigatório</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="Dominio">Domínio</label>
        <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Dominio.errors }" id="Dominio"
          formControlName="Dominio" name="Dominio" />
        <div *ngIf="submitted && f.Dominio.errors" class="invalid-feedback">
          <div *ngIf="submitted && f.Dominio.errors.required">Domínio é obrigatório</div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-6">
          <div class="d-grid gap-2">

            <button type="submit" class="custom-btn custom-btn-azul">
              <i class="fa fa-save mx-2"></i>
              Gravar
            </button>

          </div>
        </div>
        <div class="col-6">
          <div class="d-grid gap-2">

            <button class="custom-btn custom-btn-cinza" [routerLink]="['/sistema-lista']">
              <!-- <i class="fa fa-list"></i> -->
              Cancelar
            </button>

          </div>
        </div>
      </div>

    </form>
  </div>
  <br />

  <div [hidden]="!saved">
    <div class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>Registro!</strong> salvo com sucesso.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      <hr>
      <p class="mb-0">
        <button class="btn btn-success" (click)="newSistema()">
          <i class="fa fa-plus"></i>
          Novo
        </button>
      </p>
    </div>
  </div>



</div>