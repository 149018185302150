import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";

/* Aplicação */
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

import { HomeComponent } from "./page/home/home.component";
import { LoginComponent } from "./page/login/login.component";
import { EsqueciMinhaSenhaComponent } from "./page/senha-acesso/esqueci-minha-senha/esqueci-minha-senha.component";
import { MenuListaComponent } from "./page/menu/menu-lista/menu-lista.component";

import { SistemaFormComponent } from "./page/sistema/sistema-form/sistema-form.component";
import { SistemaListaComponent } from "./page/sistema/sistema-lista/sistema-lista.component";
//import { SistemaLoginComponent } from "./page/sistema/sistema-login/sistema-login.component";

import { UsuarioListaComponent } from "./page/usuario/usuario-lista/usuario-lista.component";
import { UsuarioFormComponent } from "./page/usuario/usuario-form/usuario-form.component";
import { UsuarioMenuComponent } from "./page/usuario/usuario-menu/usuario-menu.component";

import { AuthGuardRouterService } from "./services/auth-guard-router.service";
import { NgPickerComponent} from "./component/ng-picker/ng-picker.component";
import { OperacaoRotaListaComponent } from "./page/operacao-rota/operacao-rota-lista/operacao-rota-lista.component";
import { OperacaoRotaFormComponent } from "./page/operacao-rota/operacao-rota-form/operacao-rota-form.component";
import { RecuperarSenhaComponent } from "./page/senha-acesso/recuperar-senha/recuperar-senha.component";

const routesAdmin: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  // {
  //   path: "sistema-login",
  //   component: SistemaLoginComponent,
  // },
  {
    path: "sistema-lista",
    component: SistemaListaComponent,
  },
  {
    path: "sistema-form",
    component: SistemaFormComponent,
  },
  {
    path: "sistema-form/:id",
    component: SistemaFormComponent,
  },
  {
    path: "operacao-rota-lista",
    component: OperacaoRotaListaComponent,
  },
  {
    path: "operacao-rota-form",
    component: OperacaoRotaFormComponent,
  },
  {
    path: "operacao-rota-form/:sistCod/:cod",
    component: OperacaoRotaFormComponent,
  },
  {
    path: "menu-lista",
    component: MenuListaComponent,
  },
  {
    path: "usuario-lista",
    component: UsuarioListaComponent,
  },
  {
    path: "usuario-form",
    component: UsuarioFormComponent,
  },
  {
    path: "usuario-form/:numero",
    component: UsuarioFormComponent,
  },
  {
    path: "usuario-menu",
    component: UsuarioMenuComponent,
  },
];

const routesAuth: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "EsqueciMinhaSenha",
    component: EsqueciMinhaSenhaComponent,
  },
  {
    path: "SenhaDeAcesso/RecuperarSenha",
    component: RecuperarSenhaComponent,
  },
];

const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminComponent,
    children: routesAdmin,
    canActivate: [AuthGuardRouterService],
  },
  {
    path: "",
    component: AuthComponent,
    children: routesAuth,
  },
];
@NgModule({
  declarations: [],

  imports: [
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: "legacy" }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
