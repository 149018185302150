import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITreeOptions, IActionMapping } from '@circlon/angular-tree-component';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

import { SistemaService } from '../../../api/RH/sistema.service';
import { MenuService } from '../../../api/RH/menu.service';
import { UsuarioSistemaService } from '../../../api/RH/usuario-sistema.service';

import { PessoasFisicasService } from '../../../api/RH/pessoas-fisicas.service';

import { Menu } from '../../../model/RH/menu';
import { Paginator } from '../../../model/paginator';

import { ToastService } from '../../../toast/toast.service';
import { CustomLogging } from '../../../utils/CustomLogging';
import { UsuarioSistema } from 'src/app/model/RH/usuario-sistema';
import { Sistema } from 'src/app/model/RH/sistema';
import { UsuarioService } from 'src/app/api/RH/usuario.service';
import { MenuUsuarioResponse } from 'src/app/model/poco/menu-usuario-response';
import { PessoaFisica } from 'src/app/model/RH/pessoa-fisica';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})
export class UsuarioFormComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    private formBuilder: UntypedFormBuilder,

    private apiSistema: SistemaService,
    private usuarioService: UsuarioService,
    private apiPessoa: PessoasFisicasService,
    private usuarioSistemaService: UsuarioSistemaService,
    private apiMenu: MenuService,
  ) {
  }

  title: string = "Novo Usuário";
  msgErro: string = "Erro ao recuperar os dados";
  pfisNumero: number;
  submitted: boolean = false;
  saved: boolean = false;

  closeResult: string = '';
  selected: any = {} as any;
  optQtd: number[] = [10, 20, 30, 50];
  page: number = 1;
  pageSize: number = 10;
  data: any[] = [];
  search: string = '';
  column: string = 'NUMERO';
  direction: string = 'asc';
  total: number;

  novoSistemaUsuario: UsuarioSistema;
  optSistema: Sistema[];
  optUniOrg: any[];

  optionsQtd: number[] = [10, 20, 30, 50];
  tableSisUsuAss = {
    page: 1,
    perPage: 10,
    sortBy: 'SistCod',
    sortDirection: '',
    total: 0,
    search: '',
    rows: []
  };

  onSort(event){

  }

  // menu$: Observable<SistemaUsuarioMenu[]>;
  // selectedMenu: SistemaUsuarioMenu[];

  // people$: Observable<SistemaUsuarioGrupo[]>;
  // selectedPeople: SistemaUsuarioGrupo[];

  reactiveForm = this.formBuilder.group(
    {
      Numero: [null, [Validators.required, Validators.maxLength(7)]],
      Nome: ['', [Validators.required, Validators.maxLength(80)]],
      Cpf: ['', [Validators.required, Validators.maxLength(11)]],
      Sexo: ['', Validators.required],
    }
  );

  get f() { return this.reactiveForm.controls; }

  ngOnInit() {
    this.pfisNumero = this.route.snapshot.params['numero'];
    if (!this.pfisNumero) {
      this.title = "Novo Usuário";
      return;
    }
    this.reactiveForm.value.Numero = this.pfisNumero;

    this.usuarioService.getById(this.pfisNumero).subscribe(
      (data) => {
        if (data != undefined) {
          console.log(data)
          this.title = "Editar Usuário"
          this.reactiveForm.patchValue(data);
          this.getUsuarioSistemaAssoPagination();
          this.getSistema();
        }
        else {
          new CustomLogging(this.msgErro, 'red', '0.8rem').log();
        }
      },
      (error) => { //Called when error
        new CustomLogging("Erro do subscribe de dados", 'red', '0.8rem').log();
      });
  }


  loadSistemaUsuarioMenu(sistCod: number, pfisNumero: number): void {
    this.apiMenu.getByUser(sistCod, pfisNumero)
      .subscribe((res: MenuUsuarioResponse[]) => {
        this.nodes = res;
      }, err => {
        console.log(err);
      });
  }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  newSistema(): void {
    if (this.pfisNumero) {
      this.router.navigate(['/usuario-form']);
    }
    else {
      this.submitted = false;
      this.saved = false;
      this.title = "Novo Usuário"
      this.reactiveForm.reset();
      this.reactiveForm.patchValue({ Id: 0, IsActive: false });
    }
  }

  save(): void {
    this.usuarioSistemaService
      .create(this.reactiveForm.value)
      .subscribe(
        (data) => { this.saved = true; },
        (erro) => { console.log(erro) }
      );
  }

  update(): void {
    this.usuarioSistemaService
      .update(0, this.reactiveForm.value.Numero, this.reactiveForm.value)
      .subscribe(
        (data) => { this.saved = true; },
        (erro) => { console.log(erro) }
      );
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.reactiveForm.invalid) {
      return;
    }

    if (this.pfisNumero) {
      this.update()
    }
    else {
      this.save();
    }
  }

  // open(content) {
  //   this.modalService
  //     .open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' })
  //     .result
  //     .then(
  //       (result) => {
  //         this.reactiveForm.get("Id").setValue(result.NUMERO);
  //         //this.reactiveForm.get("Number").setValue(result.NUMERO);
  //         this.reactiveForm.get("Email").setValue(result.EMAIL);
  //         this.reactiveForm.get("Name").setValue(result.NOME_COMPLETO);
  //         this.reactiveForm.get("Cpf").setValue(result.CPF);
  //       },
  //       (reason) => {
  //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       }
  //     );
  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onRefresh() {
    this.page = 1;
    this.pageSize = 10;
    this.search = '';
    this.loadData();
  }
  onSelectedQtd(value): void {
    this.pageSize = value;
    this.loadData();
  }

  loadData(): void {
    this.spinner.show();
    this.apiPessoa.getPagination(this.search, this.column, this.direction, this.page, this.pageSize)
      .subscribe((res: Paginator<PessoaFisica>) => {
        this.data = res.Rows;
        this.total = res.Total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

  getUsuarioSistemaAssoPagination() {
    this.spinner.show();
    this.usuarioSistemaService.getSistemaUsuarioPaginationPorUsuario(
      this.reactiveForm.value.Numero,
      this.tableSisUsuAss.search,
      this.tableSisUsuAss.sortBy,
      this.tableSisUsuAss.sortDirection,
      this.tableSisUsuAss.page,
      this.tableSisUsuAss.perPage
    ).subscribe((res: Paginator<UsuarioSistema>) => {
      this.tableSisUsuAss.rows = res.Rows;
      this.tableSisUsuAss.total = res.Total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      console.log(err);
    });

  }

  formNovoSistemaUsuario: UntypedFormGroup;
  mSubmitted: boolean;
  get m() {
    return this.formNovoSistemaUsuario.controls;
  }

  onAdicionarSistemaUsuario(content) {
    this.mSubmitted = false;
    this.formNovoSistemaUsuario = this.formBuilder.group(
      {
        PfisNumero: new FormControl<number|null>(this.reactiveForm.value.Numero, [Validators.required]),
        SistCod: new FormControl<number|null>(null, [Validators.required]),
        Mestre: new FormControl<boolean>(false, [Validators.required]),
        Ativo: new FormControl<boolean>(true, [Validators.required])
      }
    );

    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    modalRef.result.then((result) => {
      console.log(this.formNovoSistemaUsuario.value)
      this.usuarioSistemaService.create(this.formNovoSistemaUsuario.value)
        .subscribe((res: UsuarioSistema) => {
          this.toastService.show('Registro adicionado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.getUsuarioSistemaAssoPagination();
        }, err => {
          this.toastService.show('Registro não pode adicionado.', { classname: 'bg-danger text-light', delay: 10000 });
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  onSubmitAddModal(modal) {
    this.mSubmitted = true; 
    console.log(this.formNovoSistemaUsuario.invalid)
    if (this.formNovoSistemaUsuario.invalid) {
      return;
    }
    modal.close();    
  }
  onSubmitEditModal(modal) {
    this.mSubmitted = true;
    if (this.formNovoSistemaUsuario.invalid) {
      return;
    }
    modal.close();
  }

  onRemoverSistemaUsuario(content, item: UsuarioSistema) {
    this.selected = item;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with`;
      this.usuarioSistemaService.delete(item.PfisNumero, item.SistCod)
        .subscribe((res: UsuarioSistema) => {
          this.toastService.show('Registro excluido com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.getUsuarioSistemaAssoPagination();
        }, err => {
          this.toastService.show('Registro não pode ser excluído.', { classname: 'bg-danger text-light', delay: 10000 });
          console.log(err);
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getSistema(): void {
    this.spinner.show();
    this.apiSistema.getAll()
      .subscribe((res: Sistema[]) => {
        this.optSistema = res;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

  onEditMenu(content, item: UsuarioSistema): void {
    this.selected = item;
    this.loadSistemaUsuarioMenu(item.SistCod, item.PfisNumero);
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result
      .then((result) => {
        //this.selected.SystemUserMenu = this.maperToSistemaUsuarioMenu(this.nodes, item.SistCod, item.PfisNumero);
        this.usuarioSistemaService.update(item.SistCod, item.PfisNumero, this.selected)
          .subscribe((res: UsuarioSistema) => {
            this.toastService.show('Registro alterado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
            this.getUsuarioSistemaAssoPagination();
          }, err => {
            this.toastService.show('Registro não pode ser alterado.', { classname: 'bg-danger text-light', delay: 10000 });
            console.log(err);
          });

      }, (reason) => {

      });

  }

  nodes: MenuUsuarioResponse[] = [];

  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node) => this.check(node, !node.data.checked)
    }
  };

  options: ITreeOptions = {
    actionMapping: this.actionMapping,
    idField: 'Id',
    displayField: 'Title',
    childrenField: 'Children',
  };

  public check(node, checked) {
    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.realParent);
  }

  public updateChildNodeCheckbox(node, checked) {
    node.data.checked = checked;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }

  public updateParentNodeCheckbox(node) {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.checked || child.data.indeterminate) {
        allChildrenChecked = false;
      }
      if (child.data.checked) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.checked = true;
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      node.data.checked = false;
      node.data.indeterminate = false;
    } else {
      node.data.checked = true;
      node.data.indeterminate = true;
    }
    this.updateParentNodeCheckbox(node.parent);
  }

}
