import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Paginator } from 'src/app/model/paginator';
import { SortEvent } from 'src/app/sortable/sort-event';
import { NgdbSortableHeaderDirective } from 'src/app/sortable/ngdb-sortable-header.directive';
import { ToastService } from 'src/app/toast/toast.service';
import * as $ from 'jquery';
import { OperacaoRotaService } from 'src/app/api/RH/operacao-rota.service';
import { OperacaoRota } from 'src/app/model/RH/operacao-rota';
import { SistemaService } from 'src/app/api/RH/sistema.service';
import { Sistema } from 'src/app/model/RH/sistema';

@Component({
  selector: 'app-operacao-rota-lista',
  templateUrl: './operacao-rota-lista.component.html',
  styleUrls: ['./operacao-rota-lista.component.scss']
})
export class OperacaoRotaListaComponent implements OnInit {

  @ViewChildren(NgdbSortableHeaderDirective) headers: QueryList<NgdbSortableHeaderDirective>;
  constructor(
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private operacaoRotaService: OperacaoRotaService,
    private sistemaService: SistemaService
  ) { }

  title: string = 'Operação de Rota';
  closeResult: string;
  selected: OperacaoRota;
  page: number = 1;
  pageSize: number = 10;
  total: number = 0;
  data: OperacaoRota[] = [];
  search: string = '';
  column: string = '';
  direction: string = '';

  optionsQtd: number[] = [10, 20, 30, 50];
  optSistema: Sistema[] = [];
  sistCod?: number;

  ngOnInit() {
    this.loadData();
    this.getSistema();
  }

  getSistema(): void {
    this.sistemaService.getAll()
      .subscribe((res: any) => {
        this.optSistema = res;

      }, err => {
        console.log(err);
      });
  }
  
  onChangeSistema(event): void {
    this.loadData();
  }

  onRefresh() {
    this.page = 1;
    this.pageSize = 10;
    this.search = '';
    this.loadData();
  }

  onSelectedQtd(value: number): void {
    this.pageSize = value;
    this.loadData();
  }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  onSort({ column, direction }: SortEvent): void {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.column = column;
    this.direction = direction;
    this.loadData();
  }

  open(content, item: OperacaoRota): void {
    this.selected = item;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${item.SistCod} ${item.Cod}`;
      this.operacaoRotaService.delete(item.SistCod, item.Cod)
        .subscribe((res: OperacaoRota) => {
          this.toastService.show('Registro excluido com sucesso.', { classname: 'bg-success text-light', delay: 5000 });
          this.loadData();
        }, err => {
          this.toastService.show('Registro não pode ser excluído.', { classname: 'bg-danger text-light', delay: 5000 });
          console.log(err);
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadData(): void {
    this.spinner.show();
    this.operacaoRotaService.getPagination(this.search, this.column, this.direction, this.page, this.pageSize, this.sistCod)
      .subscribe((res: Paginator<OperacaoRota>) => {
        this.data = res.Rows;
        this.total = res.Total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

}
