import { UsuarioLogado } from "./usuario-logado";

class TokenSai implements UsuarioLogado {
  aud: string;
  exp: Date;
  iss: string;
  GUID: string;
  
  Nome: string;
  Numero: number;
  CPF: string;
  Sexo: string;

  /**
   * Creates an instance of TokenSai.
   * @memberof TokenSai
   */
  constructor(token: string) {
    const tokenJson = JSON.parse(decodeURIComponent(atob(token.split('.')[1].replace('-', '+').replace('_', '/')).split('').map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join('')));
    this.aud = tokenJson.aud;
    this.exp = new Date(tokenJson.exp * 1000);
    this.iss = tokenJson.iss;
    this.Numero = Number(tokenJson.numero);
    this.Nome = String(tokenJson.nome);
    this.CPF = String(tokenJson.cpf);
    this.GUID = tokenJson.guid;
    this.Sexo = String(tokenJson.sexo);
  }
  get active() : boolean{
    return this.exp > new Date();
  }
  get expired() : boolean{
    return this.exp < new Date();
  }
  get milliSeconds() : number {
    return (this.exp.valueOf() - new Date().valueOf())
  }
  get time() : string{
    const agora = new Date().valueOf();
    const seg = (this.exp.valueOf() - agora) / 1000;
    const diffMin = Math.floor(seg/60); // minutes
    const diffSeg = Math.round(seg % 60); // segundos
    const tSeg = diffSeg.toString().padStart(2,"0");
    return `${diffMin}:${tSeg}`;
  }
}

export default TokenSai;
