import { Component, OnInit, Directive, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { Paginator } from '../../../model/paginator';
import { Sistema } from '../../../model/RH/sistema';
import { NgdbSortableHeaderDirective } from '../../../sortable/ngdb-sortable-header.directive';
import { SortEvent } from '../../../sortable/sort-event';
import { ToastService } from '../../../toast/toast.service';
import { SistemaService } from 'src/app/api/RH/sistema.service';

@Component({
  selector: 'app-sistema-lista',
  templateUrl: './sistema-lista.component.html',
  styleUrls: ['./sistema-lista.component.scss']
})
export class SistemaListaComponent implements OnInit {

  @ViewChildren(NgdbSortableHeaderDirective) headers: QueryList<NgdbSortableHeaderDirective>;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private sistemaService: SistemaService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  title = 'Sistemas';
  closeResult: string;
  selected: Sistema;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  data: Sistema[] = [];
  search = '';
  column = '';
  direction = '';

  optionsQtd: number[] = [10, 20, 30, 50];

  onRefresh() {
    this.page = 1;
    this.pageSize = 10;
    this.search = '';
    this.loadData();
  }

  onSelectedQtd(value: number): void {
    this.pageSize = value;
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.sistemaService.getPagination(this.search, this.column, this.direction, this.page, this.pageSize)
      .subscribe((res: Paginator<Sistema>) => {
        this.data = res.Rows;
        this.collectionSize = res.Total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

  loadPage(page: number) {
    this.loadData();
  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.column = column;
    this.direction = direction;
    this.loadData();
  }


  toggleNavbar() {
    $("#wrapper").toggleClass("toggled");
  }

  open(content, item: Sistema) {
    this.selected = item;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${item.Cod}`;
      this.sistemaService.delete(item.Cod)
        .subscribe((res: Sistema) => {
          this.toastService.show('Registro excluido com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.loadData();
        }, err => {
          this.toastService.show('Registro não pode ser excluído.', { classname: 'bg-danger text-light', delay: 10000 });
          console.log(err);
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
