<!-- <label for="dp" class="form-label">
  {{label}}
</label> -->
<div class="wrap-inputDate input-group" [formGroup]="modelForm">
  <input class="form-control  input-EsqueciMinhaSenha " placeholder="dd/mm/aaaa" name="dp" ngbDatepicker
    #d="ngbDatepicker" formControlName="{{name}}" [ngClass]="{ 'is-invalid': !f.pristine && f.errors }" [readonly]=false
    (click)="d.toggle()" (input)="formatDate($event)" (keydown.enter)="$event.preventDefault();d.toggle()" />
  <div class="btn btn-Input" (click)="d.toggle()" aria-hidden="true">
    <i class="fas fa-calendar-days"></i>
  </div>
  <div *ngIf="!f.pristine && f.errors" class="invalid-feedback">
    <div *ngIf="f.errors.required">
      {{label}} é obrigatório
    </div>
    <div *ngIf="f.errors.pattern || f.errors.ngbDate">
      {{label}} inválida.
    </div>
  </div>
</div>