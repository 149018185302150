
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>

<div class="limiter">
    <div class="container">
        
        <div class="p-4 wrap-login100">  
            
                <form class="login100-form m-3" [formGroup]="modelForm" (ngSubmit)="onSubmit()" novalidate *ngIf="locationPermission">
                  

                    <div class="d-flex justify-content-center align-items-center" >
                        <div> 
                            <h5 class="card-title mb-2 font-weight-normal">
                                Esqueci minha Senha / Primeiro acesso
                            </h5>
                            <div class="alert alert-warning alert-dismissible" *ngIf="!locationPermission">
                                Por favor, para continuar ative a <strong>localização</strong> no seu Navegador.
                            </div>
                        </div>
                    </div> 

                    <div *ngIf="!sucessoRecuperarSenha">
                        <div class="mb-3">

                            <!-- <label for="Cpf" class="form-label">
                                CPF
                            </label> -->

                                <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                    <input type="text"
                                        class="form-control input-EsqueciMinhaSenha"
                                        [ngClass]="{ 'is-invalid': !f.Cpf.pristine && f.Cpf.errors }"
                                        id="Cpf"
                                        name="Cpf"
                                        formControlName="Cpf"
                                        placeholder="CPF"
                                        mask="000.000.000-00"
                                        (change)="changeCPF()"
                                        />
                                    <span class="focus-input100"></span>
                                    <!-- <span class="symbol-input100">
                                        <i class="fas fa-id-card" aria-hidden="true"></i>
                                    </span> -->
                                </div>

                            <div *ngIf="!f.Cpf.pristine && f.Cpf.errors" class="invalid-feedback">
                                <div *ngIf="f.Cpf.errors.required">
                                    Cpf é obrigatório
                                </div>
                                <div *ngIf="f.Cpf.errors.minlength">
                                    Cpf deve possuir minímo de {{f.Cpf.errors.minlength.requiredLength}} caracteres.
                                </div>
                                <div *ngIf="f.Cpf.errors.maxlength">
                                    Cpf deve possuir máximo de {{f.Cpf.errors.maxlength.requiredLength}} caracteres.
                                </div>
                                <div *ngIf="f.Cpf.errors.cpfInvalido">
                                    Cpf inválido.
                                </div>                                
                            </div>
                        </div>

                        <div *ngIf="existePessoaFisica">
                            <div class="mb-3">
                                <ng-date-picker [modelForm]="modelForm" name="DataNascimento" label="Data de Nascimento"></ng-date-picker>                                
                            </div>

                            <div class="mb-3">
                                <!-- <label for="RG" class="form-label">
                                    RG
                                </label> -->
                                

                                    <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input type="text"
                                            class="form-control input-EsqueciMinhaSenha"
                                            [ngClass]="{ 'is-invalid': !f.RG.pristine && f.RG.errors }"
                                            id="RG"
                                            name="RG"
                                            formControlName="RG"
                                            placeholder="RG"/>
                                        <span class="focus-input100"></span>
                                        <!-- <span class="symbol-input100">
                                            <i class="fas fa-calendar-days" aria-hidden="true"></i>
                                        </span> -->
                                    </div>

                                <div *ngIf="!f.RG.pristine && f.RG.errors" class="invalid-feedback">
                                    <div *ngIf="f.RG.errors.required">
                                        RG é obrigatório
                                    </div>        
                                </div>
                            </div>
                            
                            <div class="mb-3">
                                <label for="Captcha" class="label-Captcha">
                                    {{captcha}}
                                </label>
                                <div class="input-group">
                                    <input type="text"
                                        class="form-control input-Captcha"
                                        [ngClass]="{ 'is-invalid': !f.Captcha.pristine && f.Captcha.errors }"
                                        id="Captcha"
                                        name="Captcha"
                                        formControlName="Captcha"
                                        placeholder="Captcha"/>

                                        

                                    <div class="btn btn-Input" (click)="getCaptcha()" aria-hidden="true" >
                                        <i class="fa fa-refresh"></i>
                                    </div>
                                    <div *ngIf="!f.Captcha.pristine && f.Captcha.errors" class="invalid-feedback">
                                        <div *ngIf="f.Captcha.errors.required">
                                            Preencha o campo com o valor da soma.
                                        </div>              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    <div *ngIf="sucessoRecuperarSenha">
                        <p>
                            Foi enviado um link de acesso para seu o e-mail cadastrado.
                        </p>
                    </div>

                    <div class="row">
                        <div class="col-6" *ngIf="!sucessoRecuperarSenha">
                            <div class="d-grid gap-2">
                                <button class="custom-btn custom-btn-azul" type="submit" [disabled]="modelForm.invalid">
                                    <i class="fa-solid fa-check m-2"></i>
                                    Confirmar
                                </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-grid gap-2">
                                <button class="custom-btn custom-btn-cinza" type="button" (click)="back()">
                                    <i class="fa-solid fa-arrow-left m-2"></i>
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

        </div>
        
    </div>
</div>


