import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { CustomLogging } from 'src/app/utils/CustomLogging';
import { OperacaoRotaService } from 'src/app/api/RH/operacao-rota.service';
import { Sistema } from 'src/app/model/RH/sistema';
import { SistemaService } from 'src/app/api/RH/sistema.service';

@Component({
  selector: 'app-operacao-rota-form',
  templateUrl: './operacao-rota-form.component.html',
  styleUrls: ['./operacao-rota-form.component.scss']
})
export class OperacaoRotaFormComponent implements OnInit {

  edit: boolean = false;
  saved: boolean = false;
  submitted: boolean = false;
  title: string = "Novo Operação de Rota";
  msgErro: string = "Erro ao recuperar os dados";
  optSistema: Sistema[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private sistemaService: SistemaService,
    private operacaoRotaService: OperacaoRotaService,
  ) { 
    this.getSistema();
  }

  modelForm = this.formBuilder.group(
    {
      SistCod: new FormControl<number|null>(null,[Validators.required]),
      Cod: new FormControl<number|null>(0),
      Descricao: new FormControl<string|null>('', [Validators.required, Validators.maxLength(200)])
    }
  );
  getSistema(): void {
    this.sistemaService.getAll()
      .subscribe((res: any) => {
        this.optSistema = res;

      }, err => {
        console.log(err);
      });
  }

  ngOnInit() {
    this.modelForm.value.SistCod = this.route.snapshot.params['sistCod'];
    this.modelForm.value.Cod = this.route.snapshot.params['cod'];

    if (!this.modelForm.value.SistCod) {
      this.title = "Nova Operação de Rota";
      return;
    }

    this.operacaoRotaService.getById(this.modelForm.value.SistCod, this.modelForm.value.Cod).subscribe(
      (data) => {
        if (data != undefined) {
          this.title = "Editar Operação de Rota"
          this.edit = true;
          this.modelForm.patchValue(data);
        }
        else {
          new CustomLogging(this.msgErro, 'red', '0.8rem').log();
        }
      },
      (error) => { //Called when error    
        new CustomLogging("Erro do subscribe de dados", 'red', '0.8rem').log();
      });
  }

  get f() { return this.modelForm.controls; }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  newSistema(): void {

    if (this.modelForm.value.SistCod) {
      this.router.navigate(['/operacao-rota-form']);
    }
    else {
      this.submitted = false;
      this.saved = false;
      this.title = "Novo Operação de Rota";
      this.modelForm.reset();
      this.modelForm.patchValue({ SistCod: 0, Cod: 0 });
    }
  }

  save(): void {
    this.operacaoRotaService.create(this.modelForm.value).subscribe(
      (data) => { this.saved = true; },
      (erro) => { console.log(erro) }
    );
  }

  update(): void {
    this.operacaoRotaService.update(this.modelForm.value.SistCod, this.modelForm.value.Cod, this.modelForm.value).subscribe(
      (data) => { this.saved = true; },
      (erro) => { console.log(erro) }
    );
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.modelForm.invalid) {
      return;
    }

    if (this.modelForm.value.SistCod && this.modelForm.value.Cod) {
      this.update();
    }
    else {
      this.save();
    }
  }

}
