import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MenuUsuarioResponse } from 'src/app/model/poco/menu-usuario-response';
import { IconeMenu } from 'src/app/model/RH/icone-menu';
import { Menu } from 'src/app/model/RH/menu';
import { HttpCustomService } from 'src/app/services/http-custom.service';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../error.service';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

  private readonly apiUrl = `${ environment.apiUrl }/Menu`;
  
  constructor(
    private http: HttpCustomService,
    private errorService: ErrorService
  ) { }

  getAll(): Observable<Menu[]> {
    return this.http
      .get<Menu[]>(this.apiUrl)
      .pipe(
        tap((retorno: Menu[]) => console.log('Obter menus')),
        catchError(this.errorService.handleError('getAll', []))
      );
  }

  getById(sistCod: number, cod: number): Observable<Menu> {
    return this.http
      .get<Menu>(`${ this.apiUrl }/Sistema/${sistCod}/Cod/${cod}`)
      .pipe(
        tap((retorno: Menu) => console.log(`Obter menus Sistema/${sistCod}/Cod/${cod}`)),
        catchError(this.errorService.handleError<Menu>(`getById Sistema/${sistCod}/Cod/${cod}`))
      );
  }

  getBySystemId(sistCod: number): Observable<Menu[]> {
    return this.http
      .get<Menu[]>(`${ this.apiUrl }/Sistema/${sistCod}`)
      .pipe(
        tap((retorno: Menu[]) => console.log(`Obter menus Sistema/${sistCod}`)),
        catchError(this.errorService.handleError<Menu[]>(`getById Sistema/${sistCod}`))
      );
  }

  getByUser(pfisNumero: number, sistCod: number): Observable<MenuUsuarioResponse[]> {

    const url = `${ this.apiUrl }/Sistema/${sistCod}/Usuario/${pfisNumero}`;

    return this.http
      .get<MenuUsuarioResponse[]>(url)
      .pipe(
        tap((retorno: MenuUsuarioResponse[]) => console.log(`Obter menus Sistema/${sistCod}/Usuario/${pfisNumero}`)),
        catchError(this.errorService.handleError<MenuUsuarioResponse[]>(`getById Sistema/${sistCod}/Usuario/${pfisNumero}`))
      );
  }

  create(instance: Menu): Observable<Menu> {
    return this.http
      .post<Menu>(this.apiUrl, instance)
      .pipe(
        tap((retorno: Menu) => console.log(`Incluir menus w/ Sistema/${retorno.SistCod}/Cod/${retorno.Cod}`)),
        catchError(this.errorService.handleError<Menu>('create'))
      );
  }

  update(sistCod: number, cod: number, instance: Menu): Observable<Menu> {
    return this.http
      .put(`${ this.apiUrl }/Sistema/${sistCod}/Cod/${cod}`, instance)
      .pipe(
        tap((retorno: Menu) => console.log(`updated menus Sistema/${sistCod}/Cod/${cod}`)),
        catchError(this.errorService.handleError<any>('update'))
      );
  }

  delete(sistCod: number, cod: number): Observable<Menu> {
    return this.http
      .delete<Menu>(`${ this.apiUrl }/Sistema/${sistCod}/Cod/${cod}`)
      .pipe(
        tap((retorno: Menu) => console.log(`deleted menus Sistema/${sistCod}/Cod/${cod}`)),
        catchError(this.errorService.handleError<Menu>('delete'))
      );
  }

  drag(sistCod: number, cod: number, ordem: number, to?: number): Observable<Menu> {

    const _to = to ? `/to/${ to }` : '';

    const url = `${ this.apiUrl }/Sistema/${sistCod}/Cod/${cod}/Ordem/${ordem}${ _to }`;

    return this.http
      .put(url, {})
      .pipe(
        tap((retorno: any) => console.log(`updated menus Sistema/${sistCod}/Cod/${cod}`)),
        catchError(this.errorService.handleError<any>('update'))
      );

  }

  getIconMenuById(sistCod: number, cod: number): Observable<IconeMenu> {
    return this.http
      .get<IconeMenu>(`${ this.apiUrl }/IconMenu/Sistema/${sistCod}/Cod/${cod}`)
      .pipe(
        tap((retorno: IconeMenu) => console.log(`Obter IconMenu Sistema/${sistCod}/Cod/${cod}`)),
        catchError(this.errorService.handleError<IconeMenu>(`Error IconMenu Sistema/${sistCod}/Cod/${cod}`))
      );
  }
}
