import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgdbSortableHeaderDirective } from 'src/app/sortable/ngdb-sortable-header.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/toast/toast.service';
import { SortEvent } from 'src/app/sortable/sort-event';
import * as $ from 'jquery';
import { AutenticacaoService } from 'src/app/api/SAI/autenticacao.service';
import { Sistema } from 'src/app/model/RH/sistema';
import { UsuarioSistemResponse } from 'src/app/model/poco/usuario-sistema-response';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {

  typeSelected: string;

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private autenticacaoService: AutenticacaoService
  ) {
    this.typeSelected = "pacman";
  }

  @ViewChildren(NgdbSortableHeaderDirective)
  headers: QueryList<NgdbSortableHeaderDirective>;

  title = "Sistemas";
  selected: Sistema;
  grid = {
    page: 1,
    pageSize: 10,
    collectionSize: 0,
    data: [] as UsuarioSistemResponse[],
    search: "",
    column: "",
    direction: "asc",
    optionsQtd: [10, 20, 30, 50] as number[],
  };

  loadPage() {}
  loadData(): void {
    const param = {
      search: this.grid.search,
      sortColumn: this.grid.column,
      sortDirection: this.grid.direction,
      page: this.grid.page,
      perPage: this.grid.pageSize,
    };

    this.spinner.show();

    this.autenticacaoService.sistemasDoUsuario(param).subscribe(
      (res: any) => {
        this.grid.data = res.Rows;
        this.grid.collectionSize = res.Total;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  onRefresh(): void {
    this.grid.page = 1;
    this.grid.pageSize = 10;
    this.grid.search = "";
    this.loadData();
  }

  onSelectedQtd(value: number): void {
    this.grid.pageSize = value;
    this.loadData();
  }

  onSort({ column, direction }: SortEvent): void {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.grid.column = column;
    this.grid.direction = direction;
    this.loadData();
  }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  open(data: UsuarioSistemResponse): void {
    this.spinner.show();
    this.autenticacaoService.sistemaUsuario(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        //window.location.href = `${data.Dominio}/Account?token=${res.access_token}`;
        window.open(`${data.Dominio}/Account?token=${res.access_token}`,'');
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  ngOnInit() {
    this.loadData();
  }
}
