import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpCustomService } from 'src/app/services/http-custom.service';
import { ErrorService } from '../error.service';
import { Paginator } from '../../model/paginator';
import { Usuario } from 'src/app/model/poco/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private readonly apiUrl = `${ environment.apiUrl }/Usuario`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
  ) { }

  getById(numero: number): Observable<Usuario> {
    return this.http
      .get<Usuario>(`${ this.apiUrl }/${ numero }`)
      .pipe(
        tap((data: any) => console.log(`Obter Usuario numero = ${numero}`)),
        catchError(this.errorService.handleError<Usuario>(`erro Usuario getById`))
      );
  }

  getPagination(sistCod: number|undefined, search: string, sortColumn: string, sortDirection: string, page: number, perPage: number)
    : Observable<Paginator<Usuario>> {

    let params = new HttpParams()
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());
    if(sistCod)
      params = params
      .set('sistCod', sistCod)

    return this.http.get<Paginator<Usuario>>(`${ this.apiUrl }/Pagination`, { params })
    .pipe(
      tap((data: any) => console.log('Obter Usuario Pagination', data as Paginator<Usuario>)),
      catchError(this.errorService.handleError('getPagination Usuario', []))
    );
  }

}
