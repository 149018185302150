import { Injectable } from '@angular/core';
import { MenuSAI } from '../model/menuSAI';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuStorageService {

  private readonly KEY = 'menu';

  constructor(private localStorage: LocalStorageService) {
  }

  get menus() : MenuSAI[] {
    return this.localStorage.getObject(this.KEY);
  }

  set menus(value: MenuSAI[] | undefined) {
    value ? this.localStorage.setObject(this.KEY, value) : this.localStorage.remove(this.KEY);
  }
}
