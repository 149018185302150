import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpCustomService } from 'src/app/services/http-custom.service';
import { Paginator } from 'src/app/model/paginator';
import { UsuarioSistemResponse } from 'src/app/model/poco/usuario-sistema-response';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  private readonly apiUrl = `${ environment.apiUrl }/Autenticacao`;

  constructor(
    private http: HttpCustomService
  ) { }

  sistemaUsuario(data: UsuarioSistemResponse): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Sistema`, data );
  }

  sistemasDoUsuario(
    {search, sortColumn, sortDirection, page, perPage}: 
    {search: string, sortColumn: string, sortDirection: string, page: number, perPage: number}
  ): Observable<Paginator<UsuarioSistemResponse>> {
    
    const params = new HttpParams()
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());

    return this.http.get<Paginator<UsuarioSistemResponse>>(`${ this.apiUrl }/SistemasDoUsuario`, { params });
  }
}
