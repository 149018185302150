<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>

<div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title mb-3 font-weight-normal">
            Recuperar Senha
          </h5>
          <div class="alert alert-warning alert-dismissible" *ngIf="!locationPermission">
            Por favor, para continuar ative a <strong>localização</strong> no seu Navegador.
          </div>

          <form class="form-signin" [formGroup]="modelForm" (ngSubmit)="onSubmit()" novalidate
            *ngIf="locationPermission">
            
            <div class="mb-3">
              <label for="NovaSenha" class="form-label">
                Nova Senha (8 dígitos)
              </label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': !f.NovaSenha.pristine && f.NovaSenha.errors }" id="NovaSenha"
                name="NovaSenha" formControlName="NovaSenha" placeholder="Nova Senha" />
              <div *ngIf="!f.NovaSenha.pristine && f.NovaSenha.errors" class="invalid-feedback">
                <div *ngIf="f.NovaSenha.errors.required">
                  Preencha o campo Nova Senha.
                </div>
                <div *ngIf="f.NovaSenha.errors.minlength || f.NovaSenha.errors.maxlength">
                  Campo deve conter (8 dígitos).
                </div>
                <div *ngIf="f.NovaSenha.errors.letra">
                  Campo deve conter 1 letra.
                </div>
                <div *ngIf="f.NovaSenha.errors.caracterespecial">
                  Campo deve conter 1 caracter especial.
                </div>
                <div *ngIf="f.NovaSenha.errors.numero">
                  Campo deve conter 1 número.
                </div>
              </div>
              <div *ngIf="!f.NovaSenha.pristine && f.NovaSenha.errors" class="validation-success">
                <div *ngIf="!f.NovaSenha.errors.letra">
                  Campo deve conter 1 letra.
                </div>
                <div *ngIf="!f.NovaSenha.errors.caracterespecial">
                  Campo deve conter 1 caracter especial.
                </div>
                <div *ngIf="!f.NovaSenha.errors.numero">
                  Campo deve conter 1 número.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="NovaSenhaConfirma" class="form-label">
                Confirme Nova Senha
              </label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': !f.NovaSenhaConfirma.pristine && (f.NovaSenhaConfirma.errors || modelForm?.errors?.senhasNaoCoincidem) }"
                id="NovaSenhaConfirma" name="NovaSenhaConfirma" formControlName="NovaSenhaConfirma"
                placeholder="Confirme a nova senha" />
              <div class="invalid-feedback">
                <div *ngIf="!f.NovaSenhaConfirma.pristine && f.NovaSenhaConfirma.errors">
                  <div *ngIf="f.NovaSenhaConfirma.errors.required">
                    Preencha o campo Nova Senha.
                  </div>
                  <div *ngIf="f.NovaSenhaConfirma.errors.minlength || f.NovaSenhaConfirma.errors.maxlength">
                    Campo deve conter (8 dígitos).
                  </div>
                </div>
                <div *ngIf="modelForm?.errors?.senhasNaoCoincidem">
                  A nova senha e a senha de confirmação não coincidem.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="Captcha" class="form-label">
                {{captcha}}
              </label>
              <div class="input-group">
                <input type="number" class="form-control"
                  [ngClass]="{ 'is-invalid': !f.Captcha.pristine && f.Captcha.errors }" id="Captcha" name="Captcha"
                  formControlName="Captcha" placeholder="Captcha" min="0" />
                <div class="btn btn-outline-secondary" (click)="getCaptcha()" aria-hidden="true">
                  <i class="fa fa-refresh"></i>
                </div>
                <div *ngIf="!f.Captcha.pristine && f.Captcha.errors" class="invalid-feedback">
                  <div *ngIf="f.Captcha.errors.required">
                    Preencha o campo com o valor da soma.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="d-grid gap-2">
                  <button class="btn btn-lg btn-success" type="submit" [disabled]="modelForm.invalid">
                    <i class="fa-solid fa-check"></i>
                    Confirmar
                  </button>
                </div>
              </div>
              <div class="col-6">
                <div class="d-grid gap-2">
                  <button class="btn btn-lg btn-outline-secondary" type="button" (click)="back()">
                    <i class="fa-solid fa-arrow-left"></i>
                    Voltar
                  </button>
                </div>
              </div>
            </div>
            
          </form>

        </div>
      </div>
    </div>
  </div>
</div>