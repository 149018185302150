import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry, switchMap } from "rxjs/operators";
import { KongService } from "../services/kong-service";

@Injectable({
  providedIn: 'root'
})
export class KongInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private KongService: KongService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //if(httpRequest.url.endsWith('Captcha/Show'))return next.handle(httpRequest);
    if(!httpRequest.url.includes('auth/realms/ms/protocol/openid-connect/token')){
      if(this.KongService.isActive()) {
        httpRequest = this.setHeader(httpRequest, this.KongService.token);
        return next.handle(httpRequest);
      }
      else{
        return this.KongService.login().pipe(
          switchMap((data) => {
            this.KongService.token = data.access_token;
            httpRequest = this.setHeader(httpRequest, data.access_token);
            this.isRefreshing = false;

            return next.handle(httpRequest);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.status == '403') {
              //this.eventBusService.emit(new EventData('logout', null));
            }

            return throwError(() => error);
          })
        );
      }
    }
    return next.handle(httpRequest);
  }

  private setHeader(httpRequest: HttpRequest<any>, token: string){
    //console.log('Kong setHeader')
    httpRequest = httpRequest.clone({ setHeaders: { Authorization: `Bearer ${token}` }});
    // httpRequest = httpRequest.clone({
    //   headers: httpRequest.headers.set('Authorization', `Bearer ${token}`)
    // });
    return httpRequest
  }
}