import { TranslationWidth } from '@angular/common';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  'pt-br': {
      weekdays: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  },
};

@Injectable()
export class I18n {
  language = 'pt-br';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return 'Day';
  }
  constructor(private _i18n: I18n) {
      super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
      return I18N_VALUES[this._i18n.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
      return this.getMonthShortName(month);
  }
}

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '-';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10)
			};
		}
		return null;
	}
  padNumber(value: number) {
    return `0${value}`.slice(-2);
  }
	toModel(date: NgbDateStruct | null): string | null {
		return date ? date.year + this.DELIMITER + this.padNumber(date.month) + this.DELIMITER + this.padNumber(date.day) : null;
	}
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}
  format(date: NgbDateStruct | null): string {
		return date ? this.padNumber(date.day) + this.DELIMITER + this.padNumber(date.month) + this.DELIMITER + date.year : '';
	}
  padNumber(value: number) {
    return `0${value}`.slice(-2);
  }
}

@Component({
  selector: "ng-date-picker",
  templateUrl: "./ng-date-picker.component.html",
  styleUrls: ["./ng-date-picker.component.scss", "./../../../stylesLogin.scss"],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class NgDatePickerComponent implements OnInit {

  @Input() 
  public modelForm: UntypedFormGroup;

  @Input() 
  name: string;

  @Input() 
  label: string;
  
  get f() {
    return this.modelForm.controls[this.name];
  }
  ngOnInit(): void {
  }
  originalDateAlias:any;
  
  formatDate(event: any) {
    
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 2) {
        value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length > 5) {
        value = value.substring(0, 5) + '/' + value.substring(5, 9);
    } 
    if(event.inputType == "deleteContentBackward"){
      input.value = this.backspaceStrategy();
      return;
    }

    this.originalDateAlias = value
    input.value = value;
  }
  backspaceStrategy(){
        var modifiedValue = this.originalDateAlias.substring(0,this.originalDateAlias.length-1);
        this.originalDateAlias = modifiedValue;
        return modifiedValue;
  }
}