import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import TokenSai from '../model/tokenSAI';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class KongService {
  
  private readonly tokenKey = 'token';

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  get token() {
    return this.localStorage.get(this.tokenKey);
  }

  set token(value) {
    value
      ? this.localStorage.set(this.tokenKey, value)
      : this.localStorage.remove(this.tokenKey);
  }

  isLoggedIn(): boolean {
    return this.token != null;
  };

  isActive(): boolean {
    const token = this.token;
    return token
      ? new TokenSai(token).active
      : false;
  };

  login() {
    const url = environment.api_token_credentials.api_token;

    const params = new HttpParams()
      .set('grant_type', environment.api_token_credentials.grant_type)
      .set('client_id', environment.api_token_credentials.client_id)
      .set('client_secret', environment.api_token_credentials.client_secret);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return this.http.post<any>(url, params.toString(), httpOptions);
  }
}