import { Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpCustomService } from '../../services/http-custom.service';
import { Sistema } from '../../model/RH/sistema';
import { Paginator } from '../../model/paginator';
import { ErrorService } from './../error.service';

@Injectable({ providedIn: 'root' })

export class SistemaService {

  private readonly apiUrl = `${ environment.apiUrl }/Sistema`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
  ) { }  

  getAll(): Observable<Sistema[]> {
    return this.http.get<Sistema[]>(this.apiUrl).pipe(
      tap((sistema: Sistema[]) => console.log('Obter sistemas')),
      catchError(this.errorService.handleError('getAll', []))
    );    
  }

  getPagination(search: string, sortColumn: string, sortDirection: string, page: number, perPage: number)
    : Observable<Paginator<Sistema>> {

    const params = new HttpParams()
      .set('search', search )
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('page', page)
      .set('perPage', perPage);

    const url = `${ this.apiUrl }/Pagination`;

    return this.http.get<Paginator<Sistema>>(url, { params }).pipe(
      tap((resp: any) => console.log('Obter sistemas Pagination', resp as Paginator<Sistema>)),
      catchError(this.errorService.handleError('getPagination', []))
    );
  }

  getById(cod: number): Observable<Sistema> {
    return this.http.get<Sistema>(`${ this.apiUrl }/${ cod }`).pipe(
      tap((sistema: Sistema) => console.log(`Obter sistemas cod=${cod}`)),
      catchError(this.errorService.handleError<Sistema>(`getById cod=${cod}`))
    );
  }

  create(sistema: Sistema): Observable<Sistema> {
    return this.http.post<Sistema>(this.apiUrl, sistema).pipe(
      tap((sistema: Sistema) => console.log(`Sistema incluído w/ Cod = ${sistema.Cod}`)),
      catchError(this.errorService.handleError<Sistema>('Inserindo Sistema'))
    );    
  }

  update(cod: number, sistema: Sistema): Observable<Sistema> {
    return this.http.put(`${ this.apiUrl }/${ cod }`, sistema).pipe(
      tap((sistema: Sistema) => console.log(`Sistema alterado Cod = ${cod}`)),
      catchError(this.errorService.handleError<any>('Alterando Sistema'))
    );      
  }

  delete(cod: number): Observable<Sistema> {
    return this.http.delete<Sistema>(`${ this.apiUrl }/${ cod }`).pipe(
      tap((sistema: Sistema) => console.log(`Sistema deletado Cod = ${sistema.Cod}`)),
      catchError(this.errorService.handleError<Sistema>('Deletando Sistema'))
    );
  }

}
