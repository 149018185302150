import { Injectable } from '@angular/core';
import { HttpCustomService } from 'src/app/services/http-custom.service';
import { ErrorService } from '../error.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SistemaUsuarioRotaOperacao } from 'src/app/model/RH/sistema-usuario-rota-operacao';

@Injectable({
  providedIn: 'root'
})
export class RotaUsuarioService {

  private readonly apiUrl = `${ environment.apiUrl }/RotaUsuario`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
  ) { }

  postTree(instance: SistemaUsuarioRotaOperacao): Observable<SistemaUsuarioRotaOperacao> {

    const url = `${ this.apiUrl }/tree`;

    return this.http.post<SistemaUsuarioRotaOperacao>(url, instance).pipe(
      tap((data: any) => console.log(`Obter`)),
      catchError(this.errorService.handleError<SistemaUsuarioRotaOperacao>(`post`))
    );
  }

  putTree(pfisNumero: number, sistCod: number, menuCod: number, rotaCod: number, instance: SistemaUsuarioRotaOperacao)
  : Observable<SistemaUsuarioRotaOperacao> {

    const url = `${ this.apiUrl }/tree/Usuario/${ pfisNumero }/Sistema/${ sistCod }/Menu/${ menuCod }/Rota/${ rotaCod }`;

    return this.http.put<SistemaUsuarioRotaOperacao>(url, instance).pipe(
      tap((data: SistemaUsuarioRotaOperacao) => console.log(`put product`, data)),
      catchError(this.errorService.handleError<SistemaUsuarioRotaOperacao>('put'))
    );

  }

  deleteTree(pfisNumero: number, sistCod: number, menuCod: number, rotaCod: number): Observable<SistemaUsuarioRotaOperacao> {

    const url = `${ this.apiUrl }/tree/Usuario/${ pfisNumero }/Sistema/${ sistCod }/Menu/${ menuCod }/Rota/${ rotaCod }`;

    return this.http.delete<SistemaUsuarioRotaOperacao>(url).pipe(
      tap((data: SistemaUsuarioRotaOperacao) => console.log(`deleted product`, data)),
      catchError(this.errorService.handleError<SistemaUsuarioRotaOperacao>('delete'))
    );

  }
}
