import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case true:
        return 'Ativo';
      case false:
        return 'Inativo';
      default:
        return null;
    }
  }

}
