import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CustomValidations } from 'src/app/utils/CustomValidations';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss", "./../../../stylesLogin.scss"],
})
export class LoginComponent implements OnInit {
  public submitted: boolean = false;
  public modelForm: UntypedFormGroup;
  public redirectAfterLogin: string[] = ["/home"];
  public strAmbiente: string;

  typeSelected: string;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    public toastService: ToastService,
    private spinner: NgxSpinnerService
  ) {
    this.typeSelected = "pacman";

    this.strAmbiente = environment.ambiente;

    this.modelForm = this.formBuilder.group({
      Cpf: [
        "",
        [
          Validators.required,
          // Validators.minLength(7),
          // Validators.maxLength(11),
          CustomValidations.ValidaCpf
        ],
      ],
      Senha: [
        "",
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
    });
  }

  get f() {
    return this.modelForm.controls;
  }

  ngOnInit() {
    this.authService.clearSession();
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.modelForm.invalid)
      return;
    this.spinner.show();
    this.authService.login(this.modelForm.value).subscribe(
      (value: any): void => {
        this.router.navigate(this.redirectAfterLogin);
        this.spinner.hide();
      },
      (erro: HttpErrorResponse) => {
        if(erro.status == 404)
          this.toastService.show('CPF/Senha inválidos', { classname: 'bg-warning ', delay: 10000 });

        this.spinner.hide();
      }
    );
  }

}
