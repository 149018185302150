import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Paginator } from '../../model/paginator';
import { tap, catchError } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { HttpCustomService } from 'src/app/services/http-custom.service';
import { UsuarioSistema } from 'src/app/model/RH/usuario-sistema';

@Injectable({
  providedIn: 'root'
})
export class UsuarioSistemaService {

  private readonly apiUrl = `${ environment.apiUrl }/UsuarioSistema`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
) { }

  getPagination(
    {search, sortColumn, sortDirection, page, perPage}: 
    {search: string, sortColumn: string, sortDirection: string, page: number, perPage: number}
  ): Observable<Paginator<UsuarioSistema>> {
    
    const params = new HttpParams()
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());

    const url = `${ this.apiUrl }/Pagination`;

    return this.http.get<Paginator<UsuarioSistema>>( url, { params });
  }
  
  // Page Home
  getPaginationByUser(
    {pfisNumero ,search, sortColumn, sortDirection, page, perPage}: 
    {pfisNumero: number ,search: string, sortColumn: string, sortDirection: string, page: number, perPage: number}
  ): Observable<Paginator<UsuarioSistema>> {
    
    const params = new HttpParams()
      .set('pfisNumero', pfisNumero)
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());

    return this.http.get<Paginator<UsuarioSistema>>(`${ this.apiUrl }/PaginationByUser`, { params });
  }

  
  // getById(pfisNumero: number, sistCod: number): Observable<UsuarioSistema> {

  //   const url = `${ this.apiUrl }/Sistema/${ sistCod }/User/${ pfisNumero }`;

  //   return this.http
  //     .get<UsuarioSistema>(url)
  //     .pipe(
  //       tap((data: any) => console.log(`Obter sistemas usuario systemId=${sistCod}-userId=${pfisNumero}`)),
  //       catchError(this.errorService.handleError<UsuarioSistema>(` erro sistemas usuario getById`))
  //     );
  // }

  create(instance: UsuarioSistema): Observable<UsuarioSistema> {
    return this.http
      .post<UsuarioSistema>(this.apiUrl, instance)
      .pipe(
        tap((data: any) => console.log(`Incluir sistema w/ id=${data.SistCod}`)),
        catchError(this.errorService.handleError<UsuarioSistema>('create'))
      );
  }

  update(pfisNumero: number, sistCod: number, sistema: UsuarioSistema): Observable<UsuarioSistema> {

    const url = `${ this.apiUrl }/Usuario/${ pfisNumero }/Sistema/${ sistCod }`;

    return this.http
      .put<UsuarioSistema>(url, sistema)
      .pipe(
        tap((data: UsuarioSistema) => console.log(`updated UsuarioSistema systemId=${sistCod}-userId=${pfisNumero}`)),
        catchError(this.errorService.handleError<any>('update'))
      );
  }

  delete(pfisNumero: number, sistCod: number): Observable<UsuarioSistema> {

    const url = `${ this.apiUrl }/Usuario/${ pfisNumero }/Sistema/${ sistCod }`;

    return this.http
      .delete<UsuarioSistema>(url)
      .pipe(
        tap((data: UsuarioSistema) => console.log(`deleted product systemId=${sistCod}-userId=${pfisNumero}`)),
        catchError(this.errorService.handleError<UsuarioSistema>('delete'))
      );
  }

  getSistemaUsuarioPaginationPorUsuario(pfisNumero: number, search: string, sortColumn: string, sortDirection: string, page: number, perPage: number)
    : Observable<Paginator<UsuarioSistema>> {

    const params = new HttpParams()
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());

    return this.http
      .get<Paginator<UsuarioSistema[]>>(`${ this.apiUrl }/Usuario/${ pfisNumero }/Pagination`, { params })
      .pipe(
        tap((data: any) => console.log('Obter sistemas Pagination', data as Paginator<UsuarioSistema>)),
        catchError(this.errorService.handleError('getPagination', []))
      );
  }
}
