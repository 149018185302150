<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2"> {{title}} </h1>
  </div>



  <div class="view-actions pb-3">

    <div class="input-wrapper">
      <input class="custom-input" (keydown.enter)="loadData()" type="text" placeholder="Pesquisar" name="search"
        [(ngModel)]="grid.search">
      <button class="feather feather-search" type="button" (click)="loadData()" placement="top" ngbTooltip="Pesquisar">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <defs></defs>
          <circle cx="11" cy="11" r="8"></circle>
          <path d="M21 21l-4.35-4.35"></path>
        </svg>
      </button>

    </div>

    <button class="view-btn list-view" (click)="onRefresh()" title="Refresh">
      <i class="fa fa-refresh feather feather-list"></i>
    </button>

  </div>




  <div class="project-boxes jsListView">

    <div *ngIf="grid.data.length > 0; else noData">

      <div class="project-box-wrapper" *ngFor="let item of grid.data ">
        <div class="project-box" style="background-color: #d5deff;">

          <div class="project-box-content-header"  style="width: 30%;">
            <p class="box-content-header">{{item.Nome}}</p>
          </div>

          <div class="box-dominio-wrapper"  style="width: 60%;">
            <p class="box-progress-header">{{item.Dominio}}</p>
          </div>

          <div class="project-box-footer"  style="width: 10%;">
            <button class="btn-sistemas" (click)="open( item )" placement="top">
              <!-- <i class="fa fa-play" aria-hidden="true"></i> -->
              <span>Iniciar</span>
            </button>
          </div>

        </div>
      </div>

    </div>

    <ng-template #noData>
      <div class="project-box-wrapper">
        <div class="project-box" style="background-color: #d5deff;">

          <div class="box-dominio-wrapper">
            <p class="box-progress-header">Não há sistema disponíveis.</p>
          </div>

        </div>
      </div>
    </ng-template>

  </div>


  <!-- <div class="d-flex justify-content-between p-2">
    <ngb-pagination  [collectionSize]="grid.collectionSize" [(page)]="grid.page" [pageSize]="grid.pageSize"
      (pageChange)="loadPage()">
    </ngb-pagination>
  </div> -->

</div>