import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultHttpHeadersService  {

  constructor(
    private localStorage: LocalStorageService) {
  }

  httpOptions(headers?: string | { [name: string]: string | string[]; }): { headers: HttpHeaders } {

    // if(this.localStorage.get('token-sai')) {
    //   return {
    //     headers: headers ? new HttpHeaders(headers) : new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.localStorage.get('token')}`,
    //         'Authorization-Jwt': `Bearer ${this.localStorage.get('token-sai')}`,
    //       }
    //     )
    //   };
    // }

    // if(this.localStorage.get('token')) {
    //   return {
    //     headers: headers ? new HttpHeaders(headers) : new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.localStorage.get('token')}`,
    //       }
    //     )
    //   };
    // }

    return {
      headers: headers ? new HttpHeaders(headers) : new HttpHeaders(
        {
          'Content-Type': 'application/json',
        }
      )
    };   
  }
    
  httpOptionsCors(headers?: string | { [name: string]: string | string[]; }): { headers: HttpHeaders } {
    return {
      headers: headers ? new HttpHeaders(headers) : new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': environment.BASE_URL,
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Content-Type'
        }
      )
    };
  }
}

