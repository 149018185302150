import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuService } from 'src/app/api/RH/menu.service';
import { FileBase64 } from 'src/app/component/ng-file-img/ng-file-img.component';

@Component({
  selector: 'menu-imagem',
  templateUrl: './menu-imagem.component.html',
  styleUrls: ['./menu-imagem.component.scss']
})
export class MenuImagemComponent implements OnInit {

  @Input() 
  public show?: boolean = true;
  
  @Input() 
  public sistCod?: number;
  @Input() 
  public menuCod?: number;
  @Input() 
  public file?: FileBase64;
  @Output() 
  public fileChange = new EventEmitter<FileBase64>();
  
  public image?: string;
  public loaded? = false;

  constructor(private api: MenuService) {}

  ngOnInit(): void {
    if(!this.sistCod) {
      this.loaded = true;
      return;
    }
    this.api
      .getIconMenuById(this.sistCod, this.menuCod)
      .subscribe((res: any) => {
        this.image = res?.Binario;
        this.loaded = true;
      }, (err: any) => {
        this.loaded = true;
      });
  }

  public onChangeFile(event: any) {
    this.fileChange.emit(event);
  }

}
