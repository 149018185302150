import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidations {
  static ValidaCpf(controle: AbstractControl) {
    if(!controle.value)
      return null;
      
    const cpf = controle.value;

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');
    if(cpf.length < 11)
      return null;

    if (
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      !regex.test(cpf)
    )
      valido = false;
    else {
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      // if (resto != parseInt(cpf.substring(9, 10)))
      //   valido = false;
      valido = resto == parseInt(cpf.substring(9, 10))

      if(valido) {
        soma = 0;
        for (let i = 1; i <= 10; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;
        
        if (resto == 10 || resto == 11) resto = 0;
        // if (resto != parseInt(cpf.substring(10, 11))) valido = false;
        // valido = true;
        valido = resto == parseInt(cpf.substring(10, 11))
      }
    }

    if (valido) return null;

    return { cpfInvalido: true };
  }

  static MaiorQue18Anos(controle: AbstractControl) {
    const nascimento = controle.value;
    const [ano, mes, dia] = nascimento.split('-');
    const hoje = new Date();
    const dataNascimento = new Date(ano, mes, dia, 0, 0, 0);
    const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...

    if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
      return null;

    return { menorDeIdade: true };
  }

  static SenhasCombinam: ValidatorFn = (control:AbstractControl): ValidationErrors|null => {
    const senha = control.get('NovaSenha');
    const senhaConfirma = control.get('NovaSenhaConfirma');
    if(senha && senhaConfirma && senha.value != senhaConfirma.value) {
      //senhaConfirma.errors = Object.assign(senhaConfirma.errors, { senhasNaoCoincidem: true });
      return { senhasNaoCoincidem: true }
    }
    return null;
  }
  
  static PadraoSenha(controle: AbstractControl) {
    if(!controle.value)
      return {
        letra: true,
        caracterespecial: true,
        numero: true
      };
      
    const texto = controle.value;
    let auxLetra: number = 0;
    let auxNumero: number = 0;
    let auxEspecial: number = 0;
    
    let letrasMaiusculas = /[A-Z]/;
    let letrasMinusculas = /[a-z]/;
    let numeros = /[0-9]/;
    let caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    for(let i = 0; i < texto.length; i++){
      if (letrasMaiusculas.test(texto[i]) || letrasMinusculas.test(texto[i]))
          auxLetra++;
      else if (numeros.test(texto[i]))
          auxNumero++;
      else if (caracteresEspeciais.test(texto[i]))
          auxEspecial++;
    }
    const retLetra = auxLetra == 0
    const retCaracterespecial = auxEspecial == 0
    const retNumero = auxNumero == 0
    if(retLetra||retCaracterespecial||retNumero)
      return {
        letra: retLetra,
        caracterespecial: retCaracterespecial,
        numero: retNumero
      };
    return null;
  }
}