import { Component, ElementRef, EventEmitter, Input, OnInit, Output, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { Type } from '@angular/compiler';

interface IIcons {
  id: string;
  name: string;
  iconName: string;
  prefix: string;
  filter: string[];
}

const ICONS = (): IIcons[] => {
  const icons: IIcons[] = [];
  icons.push({
    "id": "fa-solid fa-",
    "name": "empty",
    "iconName": "empty",
    "prefix": "fas",
    "filter": [
      "",
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield-cat",
    "name": "shield-cat",
    "iconName": "shield-cat",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "animals",
      "shield-cat",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lungs-virus",
    "name": "lungs-virus",
    "iconName": "lungs-virus",
    "prefix": "fas",
    "filter": [
      "lungs-virus",
      "medical",
      "solid",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-land-mine-on",
    "name": "land-mine-on",
    "iconName": "land-mine-on",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "humanitarian",
      "land-mine-on"
    ]
  });
  icons.push({
    "id": "fa-solid fa-suitcase-rolling",
    "name": "suitcase-rolling",
    "iconName": "suitcase-rolling",
    "prefix": "fas",
    "filter": [
      "solid",
      "suitcase-rolling",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-hiking",
    "name": "person-hiking",
    "iconName": "person-hiking",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "sports",
      "nature",
      "person-hiking",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-slash",
    "name": "users-slash",
    "iconName": "users-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "users",
      "users-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-minus",
    "name": "file-circle-minus",
    "iconName": "file-circle-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-circle-minus",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-circle-xmark",
    "name": "road-circle-xmark",
    "iconName": "road-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "road-circle-xmark",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-right-left",
    "name": "right-left",
    "iconName": "right-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "right-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-icons",
    "name": "icons",
    "iconName": "icons",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "social",
      "design",
      "icons",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shuttle-space",
    "name": "shuttle-space",
    "iconName": "shuttle-space",
    "prefix": "fas",
    "filter": [
      "solid",
      "astronomy",
      "shuttle-space",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-screwdriver",
    "name": "screwdriver",
    "iconName": "screwdriver",
    "prefix": "fas",
    "filter": [
      "solid",
      "screwdriver",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-computer",
    "name": "computer",
    "iconName": "computer",
    "prefix": "fas",
    "filter": [
      "computer",
      "solid",
      "devices",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-short-wide",
    "name": "arrow-up-short-wide",
    "iconName": "arrow-up-short-wide",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-up-short-wide",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-plus",
    "name": "square-plus",
    "iconName": "square-plus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "square-plus",
      "maps",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-xmark",
    "name": "heart-circle-xmark",
    "iconName": "heart-circle-xmark",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "humanitarian",
      "heart-circle-xmark"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cat",
    "name": "cat",
    "iconName": "cat",
    "prefix": "fas",
    "filter": [
      "halloween",
      "solid",
      "cat",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car",
    "name": "car",
    "iconName": "car",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "car",
      "logistics",
      "automotive",
      "travel",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding-droplet",
    "name": "hand-holding-droplet",
    "iconName": "hand-holding-droplet",
    "prefix": "fas",
    "filter": [
      "hand-holding-droplet",
      "solid",
      "hands",
      "charity"
    ]
  });
  icons.push({
    "id": "fa-solid fa-briefcase-medical",
    "name": "briefcase-medical",
    "iconName": "briefcase-medical",
    "prefix": "fas",
    "filter": [
      "briefcase-medical",
      "medical",
      "solid",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person",
    "name": "person",
    "iconName": "person",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "person",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square",
    "name": "square",
    "iconName": "square",
    "prefix": "fas",
    "filter": [
      "square",
      "solid",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-baseball",
    "name": "baseball",
    "iconName": "baseball",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "baseball"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microphone-lines-slash",
    "name": "microphone-lines-slash",
    "iconName": "microphone-lines-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "microphone-lines-slash",
      "audio",
      "video",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-sun",
    "name": "cloud-sun",
    "iconName": "cloud-sun",
    "prefix": "fas",
    "filter": [
      "solid",
      "cloud-sun",
      "nature",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-kaaba",
    "name": "kaaba",
    "iconName": "kaaba",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "kaaba",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-h",
    "name": "square-h",
    "iconName": "square-h",
    "prefix": "fas",
    "filter": [
      "square-h",
      "medical",
      "solid",
      "maps",
      "health",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pump-soap",
    "name": "pump-soap",
    "iconName": "pump-soap",
    "prefix": "fas",
    "filter": [
      "pump-soap",
      "solid",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-seedling",
    "name": "seedling",
    "iconName": "seedling",
    "prefix": "fas",
    "filter": [
      "solid",
      "charity",
      "nature",
      "science",
      "vegetables",
      "seedling",
      "food",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table",
    "name": "table",
    "iconName": "table",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "business",
      "table"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-wink",
    "name": "face-grin-wink",
    "iconName": "face-grin-wink",
    "prefix": "fas",
    "filter": [
      "face-grin-wink",
      "solid",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trowel-bricks",
    "name": "trowel-bricks",
    "iconName": "trowel-bricks",
    "prefix": "fas",
    "filter": [
      "solid",
      "trowel-bricks",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-baby",
    "name": "baby",
    "iconName": "baby",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "baby",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cubes",
    "name": "cubes",
    "iconName": "cubes",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "cubes",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volume-low",
    "name": "volume-low",
    "iconName": "volume-low",
    "prefix": "fas",
    "filter": [
      "volume-low",
      "media playback",
      "solid",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-meatball",
    "name": "cloud-meatball",
    "iconName": "cloud-meatball",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "cloud-meatball",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-arrow-right",
    "name": "magnifying-glass-arrow-right",
    "iconName": "magnifying-glass-arrow-right",
    "prefix": "fas",
    "filter": [
      "marketing",
      "magnifying-glass-arrow-right",
      "solid",
      "business",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sheet-plastic",
    "name": "sheet-plastic",
    "iconName": "sheet-plastic",
    "prefix": "fas",
    "filter": [
      "solid",
      "sheet-plastic",
      "construction",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-from-ground-water",
    "name": "arrow-up-from-ground-water",
    "iconName": "arrow-up-from-ground-water",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-up-from-ground-water",
      "construction",
      "humanitarian",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-door-closed",
    "name": "door-closed",
    "iconName": "door-closed",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "door-closed",
      "household",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-torii-gate",
    "name": "torii-gate",
    "iconName": "torii-gate",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "torii-gate",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-check-double",
    "name": "check-double",
    "iconName": "check-double",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "political",
      "symbols",
      "check-double",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-earth-oceania",
    "name": "earth-oceania",
    "iconName": "earth-oceania",
    "prefix": "fas",
    "filter": [
      "earth-oceania",
      "solid",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-columns",
    "name": "building-columns",
    "iconName": "building-columns",
    "prefix": "fas",
    "filter": [
      "building-columns",
      "solid",
      "maps",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toggle-off",
    "name": "toggle-off",
    "iconName": "toggle-off",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle-off",
      "toggle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-children",
    "name": "children",
    "iconName": "children",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "children",
      "humanitarian",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-earth-asia",
    "name": "earth-asia",
    "iconName": "earth-asia",
    "prefix": "fas",
    "filter": [
      "solid",
      "travel",
      "earth-asia"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bell",
    "name": "bell",
    "iconName": "bell",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "maps",
      "alert",
      "social",
      "household",
      "time",
      "bell",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-scissors",
    "name": "hand-scissors",
    "iconName": "hand-scissors",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-scissors"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-audio",
    "name": "file-audio",
    "iconName": "file-audio",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-audio",
      "files",
      "audio",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge-circle-exclamation",
    "name": "bridge-circle-exclamation",
    "iconName": "bridge-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "bridge-circle-exclamation",
      "solid",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-earth-europe",
    "name": "earth-europe",
    "iconName": "earth-europe",
    "prefix": "fas",
    "filter": [
      "earth-europe",
      "solid",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chevron-right",
    "name": "chevron-right",
    "iconName": "chevron-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "chevron-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vest-patches",
    "name": "vest-patches",
    "iconName": "vest-patches",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "clothing",
      "vest-patches"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ban-smoking",
    "name": "ban-smoking",
    "iconName": "ban-smoking",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "ban-smoking",
      "health",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-align-justify",
    "name": "align-justify",
    "iconName": "align-justify",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "align-justify"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lemon",
    "name": "lemon",
    "iconName": "lemon",
    "prefix": "fas",
    "filter": [
      "lemon",
      "solid",
      "maps",
      "vegetables",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking-luggage",
    "name": "person-walking-luggage",
    "iconName": "person-walking-luggage",
    "prefix": "fas",
    "filter": [
      "solid",
      "travel",
      "person-walking-luggage",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bolt-lightning",
    "name": "bolt-lightning",
    "iconName": "bolt-lightning",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "weather",
      "bolt-lightning"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-flag",
    "name": "building-flag",
    "iconName": "building-flag",
    "prefix": "fas",
    "filter": [
      "solid",
      "building-flag",
      "buildings",
      "political",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-egg",
    "name": "egg",
    "iconName": "egg",
    "prefix": "fas",
    "filter": [
      "solid",
      "egg",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tape",
    "name": "tape",
    "iconName": "tape",
    "prefix": "fas",
    "filter": [
      "solid",
      "tape",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stamp",
    "name": "stamp",
    "iconName": "stamp",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "design",
      "stamp"
    ]
  });
  icons.push({
    "id": "fa-solid fa-baby-carriage",
    "name": "baby-carriage",
    "iconName": "baby-carriage",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "baby-carriage",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plate-wheat",
    "name": "plate-wheat",
    "iconName": "plate-wheat",
    "prefix": "fas",
    "filter": [
      "solid",
      "plate-wheat",
      "food",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bowl-food",
    "name": "bowl-food",
    "iconName": "bowl-food",
    "prefix": "fas",
    "filter": [
      "solid",
      "bowl-food",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sliders",
    "name": "sliders",
    "iconName": "sliders",
    "prefix": "fas",
    "filter": [
      "images",
      "media playback",
      "solid",
      "audio",
      "sliders",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment-slash",
    "name": "comment-slash",
    "iconName": "comment-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "comment-slash",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-align-left",
    "name": "align-left",
    "iconName": "align-left",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "align-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-and-venus-burst",
    "name": "mars-and-venus-burst",
    "iconName": "mars-and-venus-burst",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "mars-and-venus-burst",
      "humanitarian",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-chalkboard",
    "name": "person-chalkboard",
    "iconName": "person-chalkboard",
    "prefix": "fas",
    "filter": [
      "person-chalkboard",
      "solid",
      "education",
      "business",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-headphones",
    "name": "headphones",
    "iconName": "headphones",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "headphones",
      "audio",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hashtag",
    "name": "hashtag",
    "iconName": "hashtag",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "symbols",
      "hashtag"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ruler-horizontal",
    "name": "ruler-horizontal",
    "iconName": "ruler-horizontal",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "ruler-horizontal",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lock",
    "name": "lock",
    "iconName": "lock",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "lock"
    ]
  });
  icons.push({
    "id": "fa-solid fa-video-slash",
    "name": "video-slash",
    "iconName": "video-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "video-slash",
      "video",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-arrow-down-to-line",
    "name": "person-arrow-down-to-line",
    "iconName": "person-arrow-down-to-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-arrow-down-to-line",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-large-slash",
    "name": "user-large-slash",
    "iconName": "user-large-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "users",
      "user-large-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-signature",
    "name": "signature",
    "iconName": "signature",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "signature",
      "writing",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cube",
    "name": "cube",
    "iconName": "cube",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "design",
      "cube",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-pointer",
    "name": "arrow-pointer",
    "iconName": "arrow-pointer",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-pointer"
    ]
  });
  icons.push({
    "id": "fa-solid fa-map-location",
    "name": "map-location",
    "iconName": "map-location",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "map-location",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder-closed",
    "name": "folder-closed",
    "iconName": "folder-closed",
    "prefix": "fas",
    "filter": [
      "folder-closed",
      "solid",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-drum-steelpan",
    "name": "drum-steelpan",
    "iconName": "drum-steelpan",
    "prefix": "fas",
    "filter": [
      "solid",
      "drum-steelpan",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handshake",
    "name": "handshake",
    "iconName": "handshake",
    "prefix": "fas",
    "filter": [
      "handshake",
      "solid",
      "hands",
      "charity",
      "political",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-thumbs-down",
    "name": "thumbs-down",
    "iconName": "thumbs-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "thumbs-down",
      "social",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-font",
    "name": "font",
    "iconName": "font",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "font"
    ]
  });
  icons.push({
    "id": "fa-solid fa-anchor",
    "name": "anchor",
    "iconName": "anchor",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "anchor",
      "logistics",
      "maritime",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-skating",
    "name": "person-skating",
    "iconName": "person-skating",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "person-skating",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-question",
    "name": "circle-question",
    "iconName": "circle-question",
    "prefix": "fas",
    "filter": [
      "solid",
      "accessibility",
      "symbols",
      "circle-question"
    ]
  });
  icons.push({
    "id": "fa-solid fa-feather",
    "name": "feather",
    "iconName": "feather",
    "prefix": "fas",
    "filter": [
      "feather",
      "solid",
      "nature",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clipboard-check",
    "name": "clipboard-check",
    "iconName": "clipboard-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "clipboard-check",
      "science",
      "logistics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-slash",
    "name": "user-slash",
    "iconName": "user-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-slash",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-industry",
    "name": "industry",
    "iconName": "industry",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "buildings",
      "industry",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-delete-left",
    "name": "delete-left",
    "iconName": "delete-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "delete-left",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shekel-sign",
    "name": "shekel-sign",
    "iconName": "shekel-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "shekel-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gem",
    "name": "gem",
    "iconName": "gem",
    "prefix": "fas",
    "filter": [
      "solid",
      "gem",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gamepad",
    "name": "gamepad",
    "iconName": "gamepad",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "childhood",
      "maps",
      "devices",
      "gamepad"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-praying",
    "name": "hands-praying",
    "iconName": "hands-praying",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hands-praying",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-window-maximize",
    "name": "window-maximize",
    "iconName": "window-maximize",
    "prefix": "fas",
    "filter": [
      "window-maximize",
      "coding",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-computer-mouse",
    "name": "computer-mouse",
    "iconName": "computer-mouse",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "computer-mouse"
    ]
  });
  icons.push({
    "id": "fa-solid fa-head-side-cough-slash",
    "name": "head-side-cough-slash",
    "iconName": "head-side-cough-slash",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "head-side-cough-slash",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-registered",
    "name": "registered",
    "iconName": "registered",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "registered"
    ]
  });
  icons.push({
    "id": "fa-solid fa-flag-usa",
    "name": "flag-usa",
    "iconName": "flag-usa",
    "prefix": "fas",
    "filter": [
      "flag-usa",
      "solid",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wine-bottle",
    "name": "wine-bottle",
    "iconName": "wine-bottle",
    "prefix": "fas",
    "filter": [
      "solid",
      "wine-bottle",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ghost",
    "name": "ghost",
    "iconName": "ghost",
    "prefix": "fas",
    "filter": [
      "gaming",
      "halloween",
      "solid",
      "ghost"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-circle-xmark",
    "name": "house-circle-xmark",
    "iconName": "house-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-circle-xmark",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-list-check",
    "name": "list-check",
    "iconName": "list-check",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "business",
      "list-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-otter",
    "name": "otter",
    "iconName": "otter",
    "prefix": "fas",
    "filter": [
      "solid",
      "maritime",
      "animals",
      "otter"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-chevron-left",
    "name": "circle-chevron-left",
    "iconName": "circle-chevron-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-chevron-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-bolt",
    "name": "plug-circle-bolt",
    "iconName": "plug-circle-bolt",
    "prefix": "fas",
    "filter": [
      "plug-circle-bolt",
      "solid",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-radio",
    "name": "radio",
    "iconName": "radio",
    "prefix": "fas",
    "filter": [
      "solid",
      "audio",
      "radio",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-inbox",
    "name": "inbox",
    "iconName": "inbox",
    "prefix": "fas",
    "filter": [
      "solid",
      "communication",
      "inbox"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-video",
    "name": "file-video",
    "iconName": "file-video",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-video",
      "files",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-backward-fast",
    "name": "backward-fast",
    "iconName": "backward-fast",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "backward-fast"
    ]
  });
  icons.push({
    "id": "fa-solid fa-smoking",
    "name": "smoking",
    "iconName": "smoking",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "smoking",
      "health",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-code",
    "name": "file-code",
    "iconName": "file-code",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "files",
      "file-code"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck",
    "name": "truck",
    "iconName": "truck",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "truck",
      "logistics",
      "automotive",
      "humanitarian",
      "shopping",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-sad-tear",
    "name": "face-sad-tear",
    "iconName": "face-sad-tear",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-sad-tear"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bills",
    "name": "money-bills",
    "iconName": "money-bills",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "money-bills",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-helicopter-symbol",
    "name": "helicopter-symbol",
    "iconName": "helicopter-symbol",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "helicopter-symbol",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-minus",
    "name": "user-minus",
    "iconName": "user-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-minus",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dove",
    "name": "dove",
    "iconName": "dove",
    "prefix": "fas",
    "filter": [
      "solid",
      "dove",
      "charity",
      "political",
      "animals",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angles-right",
    "name": "angles-right",
    "iconName": "angles-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "angles-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-brain",
    "name": "brain",
    "iconName": "brain",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "health",
      "brain"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-dress-burst",
    "name": "person-dress-burst",
    "iconName": "person-dress-burst",
    "prefix": "fas",
    "filter": [
      "person-dress-burst",
      "security",
      "solid",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-group-arrows-rotate",
    "name": "group-arrows-rotate",
    "iconName": "group-arrows-rotate",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "group-arrows-rotate",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-copyright",
    "name": "copyright",
    "iconName": "copyright",
    "prefix": "fas",
    "filter": [
      "copyright",
      "solid",
      "business"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mortar-pestle",
    "name": "mortar-pestle",
    "iconName": "mortar-pestle",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "mortar-pestle",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bowling-ball",
    "name": "bowling-ball",
    "iconName": "bowling-ball",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "bowling-ball"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calculator",
    "name": "calculator",
    "iconName": "calculator",
    "prefix": "fas",
    "filter": [
      "calculator",
      "solid",
      "business",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-candy-cane",
    "name": "candy-cane",
    "iconName": "candy-cane",
    "prefix": "fas",
    "filter": [
      "solid",
      "holidays",
      "candy-cane",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-poll-vertical",
    "name": "square-poll-vertical",
    "iconName": "square-poll-vertical",
    "prefix": "fas",
    "filter": [
      "marketing",
      "charts",
      "solid",
      "business",
      "social",
      "diagrams",
      "square-poll-vertical"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wine-glass",
    "name": "wine-glass",
    "iconName": "wine-glass",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "wine-glass",
      "travel",
      "moving",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cookie-bite",
    "name": "cookie-bite",
    "iconName": "cookie-bite",
    "prefix": "fas",
    "filter": [
      "cookie-bite",
      "solid",
      "childhood",
      "holidays"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tag",
    "name": "tag",
    "iconName": "tag",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "tag",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hryvnia-sign",
    "name": "hryvnia-sign",
    "iconName": "hryvnia-sign",
    "prefix": "fas",
    "filter": [
      "hryvnia-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-ramp-box",
    "name": "truck-ramp-box",
    "iconName": "truck-ramp-box",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-ramp-box",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-wide-short",
    "name": "arrow-down-wide-short",
    "iconName": "arrow-down-wide-short",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-down-wide-short",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-day",
    "name": "calendar-day",
    "iconName": "calendar-day",
    "prefix": "fas",
    "filter": [
      "solid",
      "calendar-day",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-helmet-un",
    "name": "helmet-un",
    "iconName": "helmet-un",
    "prefix": "fas",
    "filter": [
      "helmet-un",
      "solid",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-stroke",
    "name": "mars-stroke",
    "iconName": "mars-stroke",
    "prefix": "fas",
    "filter": [
      "mars-stroke",
      "solid",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-less-than-equal",
    "name": "less-than-equal",
    "iconName": "less-than-equal",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "less-than-equal"
    ]
  });
  icons.push({
    "id": "fa-solid fa-anchor-circle-check",
    "name": "anchor-circle-check",
    "iconName": "anchor-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "maritime",
      "humanitarian",
      "anchor-circle-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-check",
    "name": "money-check",
    "iconName": "money-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "money-check",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-download",
    "name": "download",
    "iconName": "download",
    "prefix": "fas",
    "filter": [
      "download",
      "solid",
      "devices",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-virus-slash",
    "name": "virus-slash",
    "iconName": "virus-slash",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "virus-slash",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-info",
    "name": "info",
    "iconName": "info",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "info"
    ]
  });
  icons.push({
    "id": "fa-solid fa-headset",
    "name": "headset",
    "iconName": "headset",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "headset",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-location-dot",
    "name": "location-dot",
    "iconName": "location-dot",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "location-dot",
      "social"
    ]
  });
  icons.push({
    "id": "fa-solid fa-burger",
    "name": "burger",
    "iconName": "burger",
    "prefix": "fas",
    "filter": [
      "solid",
      "burger",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder-plus",
    "name": "folder-plus",
    "iconName": "folder-plus",
    "prefix": "fas",
    "filter": [
      "folder-plus",
      "solid",
      "business"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-tie",
    "name": "user-tie",
    "iconName": "user-tie",
    "prefix": "fas",
    "filter": [
      "user-tie",
      "solid",
      "clothing",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bell-concierge",
    "name": "bell-concierge",
    "iconName": "bell-concierge",
    "prefix": "fas",
    "filter": [
      "solid",
      "bell-concierge",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-shield",
    "name": "file-shield",
    "iconName": "file-shield",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "files",
      "humanitarian",
      "file-shield"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hat-cowboy",
    "name": "hat-cowboy",
    "iconName": "hat-cowboy",
    "prefix": "fas",
    "filter": [
      "solid",
      "clothing",
      "hat-cowboy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-not-equal",
    "name": "not-equal",
    "iconName": "not-equal",
    "prefix": "fas",
    "filter": [
      "not-equal",
      "solid",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-open",
    "name": "book-open",
    "iconName": "book-open",
    "prefix": "fas",
    "filter": [
      "solid",
      "book-open",
      "education"
    ]
  });
  icons.push({
    "id": "fa-solid fa-splotch",
    "name": "splotch",
    "iconName": "splotch",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "splotch"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comments-dollar",
    "name": "comments-dollar",
    "iconName": "comments-dollar",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "money",
      "comments-dollar"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cent-sign",
    "name": "cent-sign",
    "iconName": "cent-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "cent-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comments",
    "name": "comments",
    "iconName": "comments",
    "prefix": "fas",
    "filter": [
      "solid",
      "comments",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-explosion",
    "name": "explosion",
    "iconName": "explosion",
    "prefix": "fas",
    "filter": [
      "explosion",
      "security",
      "solid",
      "science fiction",
      "humanitarian",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fingerprint",
    "name": "fingerprint",
    "iconName": "fingerprint",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "accessibility",
      "fingerprint"
    ]
  });
  icons.push({
    "id": "fa-solid fa-peace",
    "name": "peace",
    "iconName": "peace",
    "prefix": "fas",
    "filter": [
      "solid",
      "peace",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-check",
    "name": "calendar-check",
    "iconName": "calendar-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "time",
      "calendar-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shuffle",
    "name": "shuffle",
    "iconName": "shuffle",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "arrows",
      "shuffle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tablets",
    "name": "tablets",
    "iconName": "tablets",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "health",
      "tablets"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen-ruler",
    "name": "pen-ruler",
    "iconName": "pen-ruler",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "construction",
      "pen-ruler",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-football",
    "name": "football",
    "iconName": "football",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "football"
    ]
  });
  icons.push({
    "id": "fa-solid fa-masks-theater",
    "name": "masks-theater",
    "iconName": "masks-theater",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "masks-theater"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen-clip",
    "name": "pen-clip",
    "iconName": "pen-clip",
    "prefix": "fas",
    "filter": [
      "pen-clip",
      "solid",
      "business",
      "design",
      "writing",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tooth",
    "name": "tooth",
    "iconName": "tooth",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "tooth"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eye-dropper",
    "name": "eye-dropper",
    "iconName": "eye-dropper",
    "prefix": "fas",
    "filter": [
      "images",
      "medical",
      "solid",
      "design",
      "science",
      "health",
      "eye-dropper",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-holding",
    "name": "hands-holding",
    "iconName": "hands-holding",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hands-holding"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mountain",
    "name": "mountain",
    "iconName": "mountain",
    "prefix": "fas",
    "filter": [
      "mountain",
      "camping",
      "solid",
      "nature"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hat-wizard",
    "name": "hat-wizard",
    "iconName": "hat-wizard",
    "prefix": "fas",
    "filter": [
      "gaming",
      "halloween",
      "solid",
      "clothing",
      "hat-wizard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-envelope",
    "name": "envelope",
    "iconName": "envelope",
    "prefix": "fas",
    "filter": [
      "envelope",
      "solid",
      "business",
      "social",
      "writing",
      "communication",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-helmet-safety",
    "name": "helmet-safety",
    "iconName": "helmet-safety",
    "prefix": "fas",
    "filter": [
      "solid",
      "helmet-safety",
      "construction",
      "logistics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-skiing-nordic",
    "name": "person-skiing-nordic",
    "iconName": "person-skiing-nordic",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "person-skiing-nordic",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-asterisk",
    "name": "asterisk",
    "iconName": "asterisk",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "asterisk",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sailboat",
    "name": "sailboat",
    "iconName": "sailboat",
    "prefix": "fas",
    "filter": [
      "sailboat",
      "solid",
      "logistics",
      "maritime",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-up-down",
    "name": "up-down",
    "iconName": "up-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "up-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-lizard",
    "name": "hand-lizard",
    "iconName": "hand-lizard",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-lizard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-breastfeeding",
    "name": "person-breastfeeding",
    "iconName": "person-breastfeeding",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "person-breastfeeding",
      "childhood",
      "health",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-arrow-up",
    "name": "cloud-arrow-up",
    "iconName": "cloud-arrow-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "cloud-arrow-up",
      "connectivity",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hard-drive",
    "name": "hard-drive",
    "iconName": "hard-drive",
    "prefix": "fas",
    "filter": [
      "hard-drive",
      "solid",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spinner",
    "name": "spinner",
    "iconName": "spinner",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "spinner"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crosshairs",
    "name": "crosshairs",
    "iconName": "crosshairs",
    "prefix": "fas",
    "filter": [
      "crosshairs",
      "solid",
      "maps",
      "spinners",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-glass-water-droplet",
    "name": "glass-water-droplet",
    "iconName": "glass-water-droplet",
    "prefix": "fas",
    "filter": [
      "solid",
      "glass-water-droplet",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-between-lines",
    "name": "users-between-lines",
    "iconName": "users-between-lines",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-between-lines",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-braille",
    "name": "braille",
    "iconName": "braille",
    "prefix": "fas",
    "filter": [
      "solid",
      "accessibility",
      "braille"
    ]
  });
  icons.push({
    "id": "fa-solid fa-head-side-virus",
    "name": "head-side-virus",
    "iconName": "head-side-virus",
    "prefix": "fas",
    "filter": [
      "head-side-virus",
      "medical",
      "solid",
      "health",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-smile-beam",
    "name": "face-smile-beam",
    "iconName": "face-smile-beam",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-smile-beam"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cake-candles",
    "name": "cake-candles",
    "iconName": "cake-candles",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "childhood",
      "maps",
      "social",
      "cake-candles",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cart-arrow-down",
    "name": "cart-arrow-down",
    "iconName": "cart-arrow-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "cart-arrow-down",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code",
    "name": "code",
    "iconName": "code",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-journal-whills",
    "name": "book-journal-whills",
    "iconName": "book-journal-whills",
    "prefix": "fas",
    "filter": [
      "solid",
      "science fiction",
      "book-journal-whills",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-plus",
    "name": "circle-plus",
    "iconName": "circle-plus",
    "prefix": "fas",
    "filter": [
      "circle-plus",
      "solid",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-poo-storm",
    "name": "poo-storm",
    "iconName": "poo-storm",
    "prefix": "fas",
    "filter": [
      "solid",
      "poo-storm",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sort-down",
    "name": "sort-down",
    "iconName": "sort-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "sort-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-landmark",
    "name": "landmark",
    "iconName": "landmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "money",
      "buildings",
      "landmark",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-open-reader",
    "name": "book-open-reader",
    "iconName": "book-open-reader",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "book-open-reader"
    ]
  });
  icons.push({
    "id": "fa-solid fa-indent",
    "name": "indent",
    "iconName": "indent",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "indent"
    ]
  });
  icons.push({
    "id": "fa-solid fa-certificate",
    "name": "certificate",
    "iconName": "certificate",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "spinners",
      "shapes",
      "certificate",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-caret-left",
    "name": "square-caret-left",
    "iconName": "square-caret-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-caret-left",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-flushed",
    "name": "face-flushed",
    "iconName": "face-flushed",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-flushed"
    ]
  });
  icons.push({
    "id": "fa-solid fa-droplet-slash",
    "name": "droplet-slash",
    "iconName": "droplet-slash",
    "prefix": "fas",
    "filter": [
      "droplet-slash",
      "solid",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-snowplow",
    "name": "snowplow",
    "iconName": "snowplow",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "snowplow",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-print",
    "name": "print",
    "iconName": "print",
    "prefix": "fas",
    "filter": [
      "print",
      "solid",
      "business",
      "maps",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen",
    "name": "pen",
    "iconName": "pen",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "writing",
      "pen",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fill-drip",
    "name": "fill-drip",
    "iconName": "fill-drip",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "fill-drip"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rupee-sign",
    "name": "rupee-sign",
    "iconName": "rupee-sign",
    "prefix": "fas",
    "filter": [
      "rupee-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-language",
    "name": "language",
    "iconName": "language",
    "prefix": "fas",
    "filter": [
      "solid",
      "language",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-envelope-open-text",
    "name": "envelope-open-text",
    "iconName": "envelope-open-text",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "envelope-open-text"
    ]
  });
  icons.push({
    "id": "fa-solid fa-reply",
    "name": "reply",
    "iconName": "reply",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "reply"
    ]
  });
  icons.push({
    "id": "fa-solid fa-memory",
    "name": "memory",
    "iconName": "memory",
    "prefix": "fas",
    "filter": [
      "solid",
      "memory",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-xmark",
    "name": "xmark",
    "iconName": "xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "xmark",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-stars",
    "name": "face-grin-stars",
    "iconName": "face-grin-stars",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-stars"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-front",
    "name": "truck-front",
    "iconName": "truck-front",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-front",
      "logistics",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-high",
    "name": "temperature-high",
    "iconName": "temperature-high",
    "prefix": "fas",
    "filter": [
      "solid",
      "science",
      "temperature-high",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tent-arrow-left-right",
    "name": "tent-arrow-left-right",
    "iconName": "tent-arrow-left-right",
    "prefix": "fas",
    "filter": [
      "tent-arrow-left-right",
      "camping",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-up-lock",
    "name": "arrow-down-up-lock",
    "iconName": "arrow-down-up-lock",
    "prefix": "fas",
    "filter": [
      "arrow-down-up-lock",
      "solid",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tornado",
    "name": "tornado",
    "iconName": "tornado",
    "prefix": "fas",
    "filter": [
      "solid",
      "tornado",
      "weather",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-chimney-user",
    "name": "house-chimney-user",
    "iconName": "house-chimney-user",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-chimney-user",
      "household",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-area",
    "name": "chart-area",
    "iconName": "chart-area",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "chart-area",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star-half",
    "name": "star-half",
    "iconName": "star-half",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle",
      "star-half"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-arrow-right",
    "name": "truck-arrow-right",
    "iconName": "truck-arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "truck-arrow-right",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-taxi",
    "name": "taxi",
    "iconName": "taxi",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "taxi",
      "automotive",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-grip-lines",
    "name": "grip-lines",
    "iconName": "grip-lines",
    "prefix": "fas",
    "filter": [
      "solid",
      "grip-lines",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-excel",
    "name": "file-excel",
    "iconName": "file-excel",
    "prefix": "fas",
    "filter": [
      "solid",
      "files",
      "file-excel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-falling",
    "name": "person-falling",
    "iconName": "person-falling",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-falling",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-caret-up",
    "name": "square-caret-up",
    "iconName": "square-caret-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "square-caret-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-left-right-to-line",
    "name": "arrows-left-right-to-line",
    "iconName": "arrows-left-right-to-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrows-left-right-to-line",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ice-cream",
    "name": "ice-cream",
    "iconName": "ice-cream",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "ice-cream",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-up-across-line",
    "name": "arrow-down-up-across-line",
    "iconName": "arrow-down-up-across-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-down-up-across-line",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-tongue",
    "name": "face-grin-tongue",
    "iconName": "face-grin-tongue",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-tongue"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-circle-check",
    "name": "plane-circle-check",
    "iconName": "plane-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "plane-circle-check",
      "logistics",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trademark",
    "name": "trademark",
    "iconName": "trademark",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "trademark"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-back-fist",
    "name": "hand-back-fist",
    "iconName": "hand-back-fist",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-back-fist"
    ]
  });
  icons.push({
    "id": "fa-solid fa-venus",
    "name": "venus",
    "iconName": "venus",
    "prefix": "fas",
    "filter": [
      "solid",
      "venus",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hot-tub-person",
    "name": "hot-tub-person",
    "iconName": "hot-tub-person",
    "prefix": "fas",
    "filter": [
      "solid",
      "hot-tub-person",
      "travel",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-parachute-box",
    "name": "parachute-box",
    "iconName": "parachute-box",
    "prefix": "fas",
    "filter": [
      "parachute-box",
      "solid",
      "charity",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-map-pin",
    "name": "map-pin",
    "iconName": "map-pin",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "map-pin"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-arrow-up",
    "name": "file-arrow-up",
    "iconName": "file-arrow-up",
    "prefix": "fas",
    "filter": [
      "file-arrow-up",
      "solid",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-repeat",
    "name": "repeat",
    "iconName": "repeat",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "repeat",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-snowman",
    "name": "snowman",
    "iconName": "snowman",
    "prefix": "fas",
    "filter": [
      "snowman",
      "solid",
      "childhood",
      "holidays"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mound",
    "name": "mound",
    "iconName": "mound",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "nature",
      "construction",
      "humanitarian",
      "mound",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-forward",
    "name": "forward",
    "iconName": "forward",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "forward"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-up",
    "name": "circle-up",
    "iconName": "circle-up",
    "prefix": "fas",
    "filter": [
      "circle-up",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school-circle-check",
    "name": "school-circle-check",
    "iconName": "school-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "buildings",
      "school-circle-check",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-satellite",
    "name": "satellite",
    "iconName": "satellite",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "satellite",
      "astronomy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-yin-yang",
    "name": "yin-yang",
    "iconName": "yin-yang",
    "prefix": "fas",
    "filter": [
      "solid",
      "yin-yang",
      "spinners",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cable-car",
    "name": "cable-car",
    "iconName": "cable-car",
    "prefix": "fas",
    "filter": [
      "solid",
      "cable-car",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-biking",
    "name": "person-biking",
    "iconName": "person-biking",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "sports",
      "users",
      "person-biking"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microchip",
    "name": "microchip",
    "iconName": "microchip",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "devices",
      "microchip"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-frown",
    "name": "face-frown",
    "iconName": "face-frown",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "communication",
      "face-frown",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-z-a",
    "name": "arrow-up-z-a",
    "iconName": "arrow-up-z-a",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-up-z-a",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school-circle-exclamation",
    "name": "school-circle-exclamation",
    "iconName": "school-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "buildings",
      "school-circle-exclamation",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-medical-circle-check",
    "name": "house-medical-circle-check",
    "iconName": "house-medical-circle-check",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "buildings",
      "health",
      "humanitarian",
      "house-medical-circle-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trash",
    "name": "trash",
    "iconName": "trash",
    "prefix": "fas",
    "filter": [
      "solid",
      "trash",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-arrow-up",
    "name": "temperature-arrow-up",
    "iconName": "temperature-arrow-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "household",
      "temperature-arrow-up",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sack-xmark",
    "name": "sack-xmark",
    "iconName": "sack-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "sack-xmark",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-plus",
    "name": "calendar-plus",
    "iconName": "calendar-plus",
    "prefix": "fas",
    "filter": [
      "solid",
      "calendar-plus",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-skull-crossbones",
    "name": "skull-crossbones",
    "iconName": "skull-crossbones",
    "prefix": "fas",
    "filter": [
      "gaming",
      "halloween",
      "medical",
      "security",
      "solid",
      "skull-crossbones",
      "alert",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-on",
    "name": "car-on",
    "iconName": "car-on",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "car-on",
      "humanitarian",
      "automotive"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-arrow-right",
    "name": "circle-arrow-right",
    "iconName": "circle-arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-arrow-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-question",
    "name": "person-circle-question",
    "iconName": "person-circle-question",
    "prefix": "fas",
    "filter": [
      "solid",
      "users",
      "person-circle-question",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-phone",
    "name": "square-phone",
    "iconName": "square-phone",
    "prefix": "fas",
    "filter": [
      "square-phone",
      "solid",
      "business",
      "maps",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-branch",
    "name": "code-branch",
    "iconName": "code-branch",
    "prefix": "fas",
    "filter": [
      "code-branch",
      "coding",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-question",
    "name": "question",
    "iconName": "question",
    "prefix": "fas",
    "filter": [
      "solid",
      "question",
      "accessibility",
      "alert",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vest",
    "name": "vest",
    "iconName": "vest",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "vest",
      "clothing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star-and-crescent",
    "name": "star-and-crescent",
    "iconName": "star-and-crescent",
    "prefix": "fas",
    "filter": [
      "solid",
      "star-and-crescent",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-superscript",
    "name": "superscript",
    "iconName": "superscript",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "mathematics",
      "superscript"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scale-unbalanced-flip",
    "name": "scale-unbalanced-flip",
    "iconName": "scale-unbalanced-flip",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "money",
      "political",
      "scale-unbalanced-flip"
    ]
  });
  icons.push({
    "id": "fa-solid fa-thermometer",
    "name": "thermometer",
    "iconName": "thermometer",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "thermometer"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dungeon",
    "name": "dungeon",
    "iconName": "dungeon",
    "prefix": "fas",
    "filter": [
      "gaming",
      "security",
      "solid",
      "buildings",
      "household",
      "dungeon"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lungs",
    "name": "lungs",
    "iconName": "lungs",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "lungs",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-recycle",
    "name": "recycle",
    "iconName": "recycle",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "recycle",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-check-dollar",
    "name": "money-check-dollar",
    "iconName": "money-check-dollar",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "money-check-dollar",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ear-listen",
    "name": "ear-listen",
    "iconName": "ear-listen",
    "prefix": "fas",
    "filter": [
      "ear-listen",
      "solid",
      "accessibility",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-flood-water",
    "name": "house-flood-water",
    "iconName": "house-flood-water",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-flood-water",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tractor",
    "name": "tractor",
    "iconName": "tractor",
    "prefix": "fas",
    "filter": [
      "solid",
      "tractor",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-door-open",
    "name": "door-open",
    "iconName": "door-open",
    "prefix": "fas",
    "filter": [
      "door-open",
      "security",
      "solid",
      "household",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield-virus",
    "name": "shield-virus",
    "iconName": "shield-virus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "shield-virus",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-plus",
    "name": "user-plus",
    "iconName": "user-plus",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "user-plus",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tents",
    "name": "tents",
    "iconName": "tents",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "buildings",
      "tents",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill",
    "name": "money-bill",
    "iconName": "money-bill",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "money",
      "money-bill"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tarp",
    "name": "tarp",
    "iconName": "tarp",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "construction",
      "tarp",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-csv",
    "name": "file-csv",
    "iconName": "file-csv",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-csv",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handshake-slash",
    "name": "handshake-slash",
    "iconName": "handshake-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "handshake-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-umbrella-beach",
    "name": "umbrella-beach",
    "iconName": "umbrella-beach",
    "prefix": "fas",
    "filter": [
      "solid",
      "umbrella-beach",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-battery",
    "name": "car-battery",
    "iconName": "car-battery",
    "prefix": "fas",
    "filter": [
      "car-battery",
      "solid",
      "devices",
      "automotive",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-laugh",
    "name": "face-laugh",
    "iconName": "face-laugh",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-laugh"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-pause",
    "name": "circle-pause",
    "iconName": "circle-pause",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "circle-pause"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vihara",
    "name": "vihara",
    "iconName": "vihara",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "vihara",
      "humanitarian",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-pointer",
    "name": "hand-pointer",
    "iconName": "hand-pointer",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-pointer"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-pulling",
    "name": "people-pulling",
    "iconName": "people-pulling",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "people-pulling",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paw",
    "name": "paw",
    "iconName": "paw",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "paw",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-lock",
    "name": "user-lock",
    "iconName": "user-lock",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "user-lock",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gas-pump",
    "name": "gas-pump",
    "iconName": "gas-pump",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "humanitarian",
      "automotive",
      "gas-pump",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-holding-circle",
    "name": "hands-holding-circle",
    "iconName": "hands-holding-circle",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "hands",
      "hands-holding-circle",
      "charity",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-earth-americas",
    "name": "earth-americas",
    "iconName": "earth-americas",
    "prefix": "fas",
    "filter": [
      "earth-americas",
      "solid",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-a-z",
    "name": "arrow-down-a-z",
    "iconName": "arrow-down-a-z",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-down-a-z",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-line",
    "name": "chart-line",
    "iconName": "chart-line",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "business",
      "money",
      "chart-line",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rotate-right",
    "name": "rotate-right",
    "iconName": "rotate-right",
    "prefix": "fas",
    "filter": [
      "rotate-right",
      "media playback",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fill",
    "name": "fill",
    "iconName": "fill",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "fill"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-word",
    "name": "file-word",
    "iconName": "file-word",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-word",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-film",
    "name": "film",
    "iconName": "film",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "film",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lines-leaning",
    "name": "lines-leaning",
    "iconName": "lines-leaning",
    "prefix": "fas",
    "filter": [
      "solid",
      "shapes",
      "lines-leaning",
      "design",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angles-left",
    "name": "angles-left",
    "iconName": "angles-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "angles-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tachograph-digital",
    "name": "tachograph-digital",
    "iconName": "tachograph-digital",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "tachograph-digital"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-rifle",
    "name": "person-rifle",
    "iconName": "person-rifle",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-rifle",
      "users",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-podcast",
    "name": "podcast",
    "iconName": "podcast",
    "prefix": "fas",
    "filter": [
      "solid",
      "podcast",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-receipt",
    "name": "receipt",
    "iconName": "receipt",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "money",
      "health",
      "receipt",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table-cells",
    "name": "table-cells",
    "iconName": "table-cells",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "table-cells"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-tag",
    "name": "user-tag",
    "iconName": "user-tag",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-tag",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield",
    "name": "shield",
    "iconName": "shield",
    "prefix": "fas",
    "filter": [
      "shield",
      "coding",
      "security",
      "solid",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-question",
    "name": "file-circle-question",
    "iconName": "file-circle-question",
    "prefix": "fas",
    "filter": [
      "solid",
      "files",
      "humanitarian",
      "file-circle-question"
    ]
  });
  icons.push({
    "id": "fa-solid fa-coins",
    "name": "coins",
    "iconName": "coins",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "coins"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file",
    "name": "file",
    "iconName": "file",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "file",
      "business",
      "shapes",
      "writing",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge",
    "name": "bridge",
    "iconName": "bridge",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "logistics",
      "bridge",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment-sms",
    "name": "comment-sms",
    "iconName": "comment-sms",
    "prefix": "fas",
    "filter": [
      "solid",
      "comment-sms",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug",
    "name": "plug",
    "iconName": "plug",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "devices",
      "household",
      "plug",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-point-down",
    "name": "hand-point-down",
    "iconName": "hand-point-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-point-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-server",
    "name": "server",
    "iconName": "server",
    "prefix": "fas",
    "filter": [
      "server",
      "solid",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lock-open",
    "name": "lock-open",
    "iconName": "lock-open",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "lock-open"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scale-unbalanced",
    "name": "scale-unbalanced",
    "iconName": "scale-unbalanced",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "money",
      "political",
      "scale-unbalanced"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-rotate",
    "name": "arrows-rotate",
    "iconName": "arrows-rotate",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "arrows-rotate",
      "arrows",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-warehouse",
    "name": "warehouse",
    "iconName": "warehouse",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "logistics",
      "warehouse"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-arrow-left",
    "name": "circle-arrow-left",
    "iconName": "circle-arrow-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-arrow-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sort",
    "name": "sort",
    "iconName": "sort",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "sort"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-chimney-window",
    "name": "house-chimney-window",
    "iconName": "house-chimney-window",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-chimney-window",
      "buildings",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plus",
    "name": "plus",
    "iconName": "plus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "maps",
      "mathematics",
      "health",
      "plus",
      "symbols",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-exclamation",
    "name": "heart-circle-exclamation",
    "iconName": "heart-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "heart-circle-exclamation",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-anchor-lock",
    "name": "anchor-lock",
    "iconName": "anchor-lock",
    "prefix": "fas",
    "filter": [
      "solid",
      "anchor-lock",
      "logistics",
      "maritime",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-asl-interpreting",
    "name": "hands-asl-interpreting",
    "iconName": "hands-asl-interpreting",
    "prefix": "fas",
    "filter": [
      "solid",
      "accessibility",
      "hands-asl-interpreting",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-horse",
    "name": "horse",
    "iconName": "horse",
    "prefix": "fas",
    "filter": [
      "horse",
      "solid",
      "animals",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hamsa",
    "name": "hamsa",
    "iconName": "hamsa",
    "prefix": "fas",
    "filter": [
      "solid",
      "hamsa",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-meteor",
    "name": "meteor",
    "iconName": "meteor",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "meteor",
      "astronomy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-virus",
    "name": "virus",
    "iconName": "virus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "virus",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-d6",
    "name": "dice-d6",
    "iconName": "dice-d6",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-d6"
    ]
  });
  icons.push({
    "id": "fa-solid fa-strikethrough",
    "name": "strikethrough",
    "iconName": "strikethrough",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "strikethrough"
    ]
  });
  icons.push({
    "id": "fa-solid fa-smog",
    "name": "smog",
    "iconName": "smog",
    "prefix": "fas",
    "filter": [
      "solid",
      "smog",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-xmark",
    "name": "calendar-xmark",
    "iconName": "calendar-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "calendar-xmark",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eraser",
    "name": "eraser",
    "iconName": "eraser",
    "prefix": "fas",
    "filter": [
      "eraser",
      "solid",
      "business",
      "design",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-booth",
    "name": "person-booth",
    "iconName": "person-booth",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-booth",
      "political",
      "users",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star-of-life",
    "name": "star-of-life",
    "iconName": "star-of-life",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "star-of-life",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-manat-sign",
    "name": "manat-sign",
    "iconName": "manat-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "manat-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-republican",
    "name": "republican",
    "iconName": "republican",
    "prefix": "fas",
    "filter": [
      "solid",
      "republican",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clock",
    "name": "clock",
    "iconName": "clock",
    "prefix": "fas",
    "filter": [
      "solid",
      "clock",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hill-rockslide",
    "name": "hill-rockslide",
    "iconName": "hill-rockslide",
    "prefix": "fas",
    "filter": [
      "hill-rockslide",
      "solid",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-signal",
    "name": "house-signal",
    "iconName": "house-signal",
    "prefix": "fas",
    "filter": [
      "house-signal",
      "solid",
      "connectivity",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-droplet",
    "name": "truck-droplet",
    "iconName": "truck-droplet",
    "prefix": "fas",
    "filter": [
      "truck-droplet",
      "solid",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lightbulb",
    "name": "lightbulb",
    "iconName": "lightbulb",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "maps",
      "household",
      "lightbulb",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-flask-vial",
    "name": "flask-vial",
    "iconName": "flask-vial",
    "prefix": "fas",
    "filter": [
      "flask-vial",
      "medical",
      "solid",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-bookmark",
    "name": "book-bookmark",
    "iconName": "book-bookmark",
    "prefix": "fas",
    "filter": [
      "book-bookmark",
      "solid",
      "writing",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-right-from-bracket",
    "name": "right-from-bracket",
    "iconName": "right-from-bracket",
    "prefix": "fas",
    "filter": [
      "solid",
      "right-from-bracket",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-turn-right",
    "name": "arrows-turn-right",
    "iconName": "arrows-turn-right",
    "prefix": "fas",
    "filter": [
      "arrows-turn-right",
      "solid",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diagram-successor",
    "name": "diagram-successor",
    "iconName": "diagram-successor",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "diagram-successor",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bars",
    "name": "bars",
    "iconName": "bars",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "bars",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-phone",
    "name": "phone",
    "iconName": "phone",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "phone",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-rays",
    "name": "users-rays",
    "iconName": "users-rays",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-rays",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clipboard-question",
    "name": "clipboard-question",
    "iconName": "clipboard-question",
    "prefix": "fas",
    "filter": [
      "solid",
      "clipboard-question",
      "business",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-lock",
    "name": "building-lock",
    "iconName": "building-lock",
    "prefix": "fas",
    "filter": [
      "building-lock",
      "security",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-merge",
    "name": "code-merge",
    "iconName": "code-merge",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code-merge"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-arrow-up-from-line",
    "name": "person-arrow-up-from-line",
    "iconName": "person-arrow-up-from-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "users",
      "person-arrow-up-from-line",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eject",
    "name": "eject",
    "iconName": "eject",
    "prefix": "fas",
    "filter": [
      "eject",
      "media playback",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vr-cardboard",
    "name": "vr-cardboard",
    "iconName": "vr-cardboard",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "vr-cardboard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sd-card",
    "name": "sd-card",
    "iconName": "sd-card",
    "prefix": "fas",
    "filter": [
      "solid",
      "sd-card",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-nodes",
    "name": "circle-nodes",
    "iconName": "circle-nodes",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "connectivity",
      "design",
      "circle-nodes",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-box",
    "name": "box",
    "iconName": "box",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "box"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toilet-paper",
    "name": "toilet-paper",
    "iconName": "toilet-paper",
    "prefix": "fas",
    "filter": [
      "camping",
      "halloween",
      "solid",
      "household",
      "travel",
      "toilet-paper"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-circle-exclamation",
    "name": "plane-circle-exclamation",
    "iconName": "plane-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "plane-circle-exclamation",
      "logistics",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-draw-polygon",
    "name": "draw-polygon",
    "iconName": "draw-polygon",
    "prefix": "fas",
    "filter": [
      "solid",
      "draw-polygon",
      "maps",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-filter-circle-xmark",
    "name": "filter-circle-xmark",
    "iconName": "filter-circle-xmark",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "filter-circle-xmark",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right-arrow-left",
    "name": "arrow-right-arrow-left",
    "iconName": "arrow-right-arrow-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-right-arrow-left",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-spikes",
    "name": "road-spikes",
    "iconName": "road-spikes",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "road-spikes",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-nfi",
    "name": "square-nfi",
    "iconName": "square-nfi",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-nfi",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars",
    "name": "mars",
    "iconName": "mars",
    "prefix": "fas",
    "filter": [
      "solid",
      "mars",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-harassing",
    "name": "person-harassing",
    "iconName": "person-harassing",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-harassing",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eye-low-vision",
    "name": "eye-low-vision",
    "iconName": "eye-low-vision",
    "prefix": "fas",
    "filter": [
      "eye-low-vision",
      "solid",
      "maps",
      "accessibility"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-xmark",
    "name": "user-xmark",
    "iconName": "user-xmark",
    "prefix": "fas",
    "filter": [
      "user-xmark",
      "solid",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volcano",
    "name": "volcano",
    "iconName": "volcano",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "weather",
      "volcano",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angles-up",
    "name": "angles-up",
    "iconName": "angles-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "angles-up",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-invoice",
    "name": "file-invoice",
    "iconName": "file-invoice",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "file-invoice"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scale-balanced",
    "name": "scale-balanced",
    "iconName": "scale-balanced",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "money",
      "scale-balanced",
      "political",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-kiss",
    "name": "face-kiss",
    "iconName": "face-kiss",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-kiss"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-digging",
    "name": "person-digging",
    "iconName": "person-digging",
    "prefix": "fas",
    "filter": [
      "solid",
      "construction",
      "person-digging",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-train-subway",
    "name": "train-subway",
    "iconName": "train-subway",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "train-subway",
      "logistics",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-graduate",
    "name": "user-graduate",
    "iconName": "user-graduate",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "user-graduate",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-skiing",
    "name": "person-skiing",
    "iconName": "person-skiing",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "users",
      "person-skiing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-up-down-left-right",
    "name": "up-down-left-right",
    "iconName": "up-down-left-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "up-down-left-right",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bath",
    "name": "bath",
    "iconName": "bath",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "childhood",
      "maps",
      "household",
      "travel",
      "bath"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-secret",
    "name": "user-secret",
    "iconName": "user-secret",
    "prefix": "fas",
    "filter": [
      "coding",
      "security",
      "solid",
      "users",
      "user-secret"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-lock",
    "name": "road-lock",
    "iconName": "road-lock",
    "prefix": "fas",
    "filter": [
      "road-lock",
      "solid",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-camera",
    "name": "camera",
    "iconName": "camera",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "devices",
      "social",
      "camera",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-beer-mug-empty",
    "name": "beer-mug-empty",
    "iconName": "beer-mug-empty",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "beer-mug-empty",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mask",
    "name": "mask",
    "iconName": "mask",
    "prefix": "fas",
    "filter": [
      "halloween",
      "security",
      "solid",
      "mask"
    ]
  });
  icons.push({
    "id": "fa-solid fa-faucet-drip",
    "name": "faucet-drip",
    "iconName": "faucet-drip",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "faucet-drip",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chalkboard-user",
    "name": "chalkboard-user",
    "iconName": "chalkboard-user",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "chalkboard-user",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-sad-cry",
    "name": "face-sad-cry",
    "iconName": "face-sad-cry",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-sad-cry"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jedi",
    "name": "jedi",
    "iconName": "jedi",
    "prefix": "fas",
    "filter": [
      "jedi",
      "solid",
      "science fiction",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spray-can-sparkles",
    "name": "spray-can-sparkles",
    "iconName": "spray-can-sparkles",
    "prefix": "fas",
    "filter": [
      "solid",
      "spray-can-sparkles",
      "automotive"
    ]
  });
  icons.push({
    "id": "fa-solid fa-qrcode",
    "name": "qrcode",
    "iconName": "qrcode",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "qrcode"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-carry-box",
    "name": "people-carry-box",
    "iconName": "people-carry-box",
    "prefix": "fas",
    "filter": [
      "people-carry-box",
      "solid",
      "moving",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volume-off",
    "name": "volume-off",
    "iconName": "volume-off",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "audio",
      "volume-off"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-ninja",
    "name": "user-ninja",
    "iconName": "user-ninja",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-ninja",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-viruses",
    "name": "viruses",
    "iconName": "viruses",
    "prefix": "fas",
    "filter": [
      "viruses",
      "medical",
      "solid",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-left-right",
    "name": "arrows-left-right",
    "iconName": "arrows-left-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrows-left-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handshake-simple-slash",
    "name": "handshake-simple-slash",
    "iconName": "handshake-simple-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "handshake-simple-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fish-fins",
    "name": "fish-fins",
    "iconName": "fish-fins",
    "prefix": "fas",
    "filter": [
      "solid",
      "fish-fins",
      "maritime",
      "animals",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-worm",
    "name": "worm",
    "iconName": "worm",
    "prefix": "fas",
    "filter": [
      "solid",
      "worm",
      "nature",
      "animals",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-down",
    "name": "circle-down",
    "iconName": "circle-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fire-extinguisher",
    "name": "fire-extinguisher",
    "iconName": "fire-extinguisher",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "fire-extinguisher",
      "maps"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-medical-circle-exclamation",
    "name": "house-medical-circle-exclamation",
    "iconName": "house-medical-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "house-medical-circle-exclamation",
      "medical",
      "solid",
      "buildings",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hat-cowboy-side",
    "name": "hat-cowboy-side",
    "iconName": "hat-cowboy-side",
    "prefix": "fas",
    "filter": [
      "solid",
      "clothing",
      "hat-cowboy-side"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gifts",
    "name": "gifts",
    "iconName": "gifts",
    "prefix": "fas",
    "filter": [
      "solid",
      "holidays",
      "gifts",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-from-bracket",
    "name": "arrow-up-from-bracket",
    "iconName": "arrow-up-from-bracket",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-up-from-bracket"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-xmark",
    "name": "file-circle-xmark",
    "iconName": "file-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "files",
      "file-circle-xmark",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-group",
    "name": "people-group",
    "iconName": "people-group",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "people-group",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking-with-cane",
    "name": "person-walking-with-cane",
    "iconName": "person-walking-with-cane",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "accessibility",
      "person-walking-with-cane",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-marker",
    "name": "marker",
    "iconName": "marker",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "marker",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sack-dollar",
    "name": "sack-dollar",
    "iconName": "sack-dollar",
    "prefix": "fas",
    "filter": [
      "solid",
      "sack-dollar",
      "money",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-kip-sign",
    "name": "kip-sign",
    "iconName": "kip-sign",
    "prefix": "fas",
    "filter": [
      "kip-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen-nib",
    "name": "pen-nib",
    "iconName": "pen-nib",
    "prefix": "fas",
    "filter": [
      "solid",
      "pen-nib",
      "business",
      "design",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-xmark",
    "name": "circle-xmark",
    "iconName": "circle-xmark",
    "prefix": "fas",
    "filter": [
      "circle-xmark",
      "solid",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road",
    "name": "road",
    "iconName": "road",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "road",
      "logistics",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-info",
    "name": "circle-info",
    "iconName": "circle-info",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "accessibility",
      "circle-info"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass",
    "name": "magnifying-glass",
    "iconName": "magnifying-glass",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "magnifying-glass"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pencil",
    "name": "pencil",
    "iconName": "pencil",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "writing",
      "construction",
      "pencil",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-oil-can",
    "name": "oil-can",
    "iconName": "oil-can",
    "prefix": "fas",
    "filter": [
      "solid",
      "automotive",
      "oil-can"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wifi",
    "name": "wifi",
    "iconName": "wifi",
    "prefix": "fas",
    "filter": [
      "wifi",
      "solid",
      "connectivity",
      "maps",
      "toggle",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-pulse",
    "name": "heart-pulse",
    "iconName": "heart-pulse",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "maps",
      "sports",
      "heart-pulse",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment-medical",
    "name": "comment-medical",
    "iconName": "comment-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "comment-medical",
      "health",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-italic",
    "name": "italic",
    "iconName": "italic",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "italic"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handshake-simple",
    "name": "handshake-simple",
    "iconName": "handshake-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "handshake-simple",
      "charity",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stroopwafel",
    "name": "stroopwafel",
    "iconName": "stroopwafel",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "stroopwafel",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-id-badge",
    "name": "id-badge",
    "iconName": "id-badge",
    "prefix": "fas",
    "filter": [
      "images",
      "security",
      "solid",
      "id-badge",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-greater-than-equal",
    "name": "greater-than-equal",
    "iconName": "greater-than-equal",
    "prefix": "fas",
    "filter": [
      "solid",
      "greater-than-equal",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-terminal",
    "name": "terminal",
    "iconName": "terminal",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "terminal"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-rolling-eyes",
    "name": "face-rolling-eyes",
    "iconName": "face-rolling-eyes",
    "prefix": "fas",
    "filter": [
      "face-rolling-eyes",
      "solid",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pause",
    "name": "pause",
    "iconName": "pause",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "pause"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-medical",
    "name": "truck-medical",
    "iconName": "truck-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "maps",
      "health",
      "truck-medical",
      "automotive",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-closed-captioning",
    "name": "closed-captioning",
    "iconName": "closed-captioning",
    "prefix": "fas",
    "filter": [
      "solid",
      "accessibility",
      "video",
      "closed-captioning"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plant-wilt",
    "name": "plant-wilt",
    "iconName": "plant-wilt",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "plant-wilt",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-circle-check",
    "name": "house-circle-check",
    "iconName": "house-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-circle-check",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-trend-up",
    "name": "arrow-trend-up",
    "iconName": "arrow-trend-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-trend-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fire",
    "name": "fire",
    "iconName": "fire",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "nature",
      "science",
      "fire",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star-of-david",
    "name": "star-of-david",
    "iconName": "star-of-david",
    "prefix": "fas",
    "filter": [
      "solid",
      "star-of-david",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-map",
    "name": "map",
    "iconName": "map",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "travel",
      "map"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-contract",
    "name": "file-contract",
    "iconName": "file-contract",
    "prefix": "fas",
    "filter": [
      "file-contract",
      "security",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-equals",
    "name": "equals",
    "iconName": "equals",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "equals",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gavel",
    "name": "gavel",
    "iconName": "gavel",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "gavel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star",
    "name": "star",
    "iconName": "star",
    "prefix": "fas",
    "filter": [
      "solid",
      "star",
      "social",
      "shapes",
      "toggle",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right-to-bracket",
    "name": "arrow-right-to-bracket",
    "iconName": "arrow-right-to-bracket",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-right-to-bracket",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-peso-sign",
    "name": "peso-sign",
    "iconName": "peso-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "peso-sign",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sleigh",
    "name": "sleigh",
    "iconName": "sleigh",
    "prefix": "fas",
    "filter": [
      "sleigh",
      "solid",
      "holidays",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-up-down",
    "name": "arrows-up-down",
    "iconName": "arrows-up-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrows-up-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-upload",
    "name": "upload",
    "iconName": "upload",
    "prefix": "fas",
    "filter": [
      "solid",
      "upload",
      "devices",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spray-can",
    "name": "spray-can",
    "iconName": "spray-can",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "spray-can"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield-dog",
    "name": "shield-dog",
    "iconName": "shield-dog",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "shield-dog",
      "animals",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pepper-hot",
    "name": "pepper-hot",
    "iconName": "pepper-hot",
    "prefix": "fas",
    "filter": [
      "solid",
      "pepper-hot",
      "vegetables",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-arrow-up-right",
    "name": "square-arrow-up-right",
    "iconName": "square-arrow-up-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-arrow-up-right",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge-circle-xmark",
    "name": "bridge-circle-xmark",
    "iconName": "bridge-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "bridge-circle-xmark",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-knight",
    "name": "chess-knight",
    "iconName": "chess-knight",
    "prefix": "fas",
    "filter": [
      "chess-knight",
      "gaming",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-medical",
    "name": "book-medical",
    "iconName": "book-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "book-medical",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clone",
    "name": "clone",
    "iconName": "clone",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "design",
      "clone",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fish",
    "name": "fish",
    "iconName": "fish",
    "prefix": "fas",
    "filter": [
      "solid",
      "fish",
      "maritime",
      "animals",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dog",
    "name": "dog",
    "iconName": "dog",
    "prefix": "fas",
    "filter": [
      "solid",
      "animals",
      "dog"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-holding-child",
    "name": "hands-holding-child",
    "iconName": "hands-holding-child",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "hands",
      "childhood",
      "charity",
      "hands-holding-child",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ethernet",
    "name": "ethernet",
    "iconName": "ethernet",
    "prefix": "fas",
    "filter": [
      "ethernet",
      "solid",
      "connectivity",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-three",
    "name": "dice-three",
    "iconName": "dice-three",
    "prefix": "fas",
    "filter": [
      "dice-three",
      "gaming",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ellipsis-vertical",
    "name": "ellipsis-vertical",
    "iconName": "ellipsis-vertical",
    "prefix": "fas",
    "filter": [
      "solid",
      "ellipsis-vertical",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-shield",
    "name": "user-shield",
    "iconName": "user-shield",
    "prefix": "fas",
    "filter": [
      "user-shield",
      "security",
      "solid",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-id-card",
    "name": "id-card",
    "iconName": "id-card",
    "prefix": "fas",
    "filter": [
      "images",
      "security",
      "solid",
      "id-card",
      "humanitarian",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-shield",
    "name": "building-shield",
    "iconName": "building-shield",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "building-shield",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-suitcase-medical",
    "name": "suitcase-medical",
    "iconName": "suitcase-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "suitcase-medical",
      "maps",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge-circle-check",
    "name": "bridge-circle-check",
    "iconName": "bridge-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "bridge-circle-check",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tent-arrow-turn-left",
    "name": "tent-arrow-turn-left",
    "iconName": "tent-arrow-turn-left",
    "prefix": "fas",
    "filter": [
      "tent-arrow-turn-left",
      "camping",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-commit",
    "name": "code-commit",
    "iconName": "code-commit",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code-commit"
    ]
  });
  icons.push({
    "id": "fa-solid fa-thumbs-up",
    "name": "thumbs-up",
    "iconName": "thumbs-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "thumbs-up",
      "social",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shirt",
    "name": "shirt",
    "iconName": "shirt",
    "prefix": "fas",
    "filter": [
      "solid",
      "shirt",
      "clothing",
      "humanitarian",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-lock",
    "name": "house-lock",
    "iconName": "house-lock",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "house-lock",
      "buildings",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen-fancy",
    "name": "pen-fancy",
    "iconName": "pen-fancy",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "pen-fancy",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-doctor",
    "name": "user-doctor",
    "iconName": "user-doctor",
    "prefix": "fas",
    "filter": [
      "user-doctor",
      "medical",
      "solid",
      "health",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-envelope-circle-check",
    "name": "envelope-circle-check",
    "iconName": "envelope-circle-check",
    "prefix": "fas",
    "filter": [
      "envelope-circle-check",
      "solid",
      "business",
      "humanitarian",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spa",
    "name": "spa",
    "iconName": "spa",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "spa",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users",
    "name": "users",
    "iconName": "users",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tenge-sign",
    "name": "tenge-sign",
    "iconName": "tenge-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "tenge-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-shelter",
    "name": "person-shelter",
    "iconName": "person-shelter",
    "prefix": "fas",
    "filter": [
      "person-shelter",
      "camping",
      "security",
      "solid",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-check",
    "name": "check",
    "iconName": "check",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "check",
      "symbols",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-point-up",
    "name": "hand-point-up",
    "iconName": "hand-point-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-point-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-lines",
    "name": "file-lines",
    "iconName": "file-lines",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "writing",
      "file-lines",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-venus-double",
    "name": "venus-double",
    "iconName": "venus-double",
    "prefix": "fas",
    "filter": [
      "solid",
      "venus-double",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-broom-ball",
    "name": "broom-ball",
    "iconName": "broom-ball",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "broom-ball"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sink",
    "name": "sink",
    "iconName": "sink",
    "prefix": "fas",
    "filter": [
      "solid",
      "sink",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-bubbles",
    "name": "hands-bubbles",
    "iconName": "hands-bubbles",
    "prefix": "fas",
    "filter": [
      "hands-bubbles",
      "solid",
      "hands",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-swatchbook",
    "name": "swatchbook",
    "iconName": "swatchbook",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "swatchbook"
    ]
  });
  icons.push({
    "id": "fa-solid fa-photo-film",
    "name": "photo-film",
    "iconName": "photo-film",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "social",
      "photo-film",
      "files",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mask-face",
    "name": "mask-face",
    "iconName": "mask-face",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "humanitarian",
      "mask-face"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-caret-right",
    "name": "square-caret-right",
    "iconName": "square-caret-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-caret-right",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sitemap",
    "name": "sitemap",
    "iconName": "sitemap",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "sitemap"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-fork",
    "name": "code-fork",
    "iconName": "code-fork",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code-fork"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-minus",
    "name": "plug-circle-minus",
    "iconName": "plug-circle-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "plug-circle-minus",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vial-virus",
    "name": "vial-virus",
    "iconName": "vial-virus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "vial-virus",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-down-to-people",
    "name": "arrows-down-to-people",
    "iconName": "arrows-down-to-people",
    "prefix": "fas",
    "filter": [
      "arrows-down-to-people",
      "solid",
      "humanitarian",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment-dollar",
    "name": "comment-dollar",
    "iconName": "comment-dollar",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "money",
      "comment-dollar"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pills",
    "name": "pills",
    "iconName": "pills",
    "prefix": "fas",
    "filter": [
      "pills",
      "medical",
      "solid",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sign-hanging",
    "name": "sign-hanging",
    "iconName": "sign-hanging",
    "prefix": "fas",
    "filter": [
      "sign-hanging",
      "solid",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hanukiah",
    "name": "hanukiah",
    "iconName": "hanukiah",
    "prefix": "fas",
    "filter": [
      "solid",
      "hanukiah",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-trend-down",
    "name": "arrow-trend-down",
    "iconName": "arrow-trend-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-trend-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mug-saucer",
    "name": "mug-saucer",
    "iconName": "mug-saucer",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "maps",
      "household",
      "travel",
      "food",
      "mug-saucer"
    ]
  });
  icons.push({
    "id": "fa-solid fa-place-of-worship",
    "name": "place-of-worship",
    "iconName": "place-of-worship",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "place-of-worship",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-child",
    "name": "child",
    "iconName": "child",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "users",
      "child"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trowel",
    "name": "trowel",
    "iconName": "trowel",
    "prefix": "fas",
    "filter": [
      "trowel",
      "solid",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-chevron-up",
    "name": "circle-chevron-up",
    "iconName": "circle-chevron-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-chevron-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-baseball-bat-ball",
    "name": "baseball-bat-ball",
    "iconName": "baseball-bat-ball",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "sports",
      "baseball-bat-ball"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-wheat",
    "name": "building-wheat",
    "iconName": "building-wheat",
    "prefix": "fas",
    "filter": [
      "building-wheat",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-prescription",
    "name": "prescription",
    "iconName": "prescription",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "prescription",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bicycle",
    "name": "bicycle",
    "iconName": "bicycle",
    "prefix": "fas",
    "filter": [
      "solid",
      "bicycle",
      "maps",
      "sports",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angle-right",
    "name": "angle-right",
    "iconName": "angle-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "angle-right",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-wide",
    "name": "face-grin-wide",
    "iconName": "face-grin-wide",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-wide"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stethoscope",
    "name": "stethoscope",
    "iconName": "stethoscope",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "stethoscope",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tarp-droplet",
    "name": "tarp-droplet",
    "iconName": "tarp-droplet",
    "prefix": "fas",
    "filter": [
      "tarp-droplet",
      "camping",
      "solid",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-group",
    "name": "user-group",
    "iconName": "user-group",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "user-group",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-up-long",
    "name": "up-long",
    "iconName": "up-long",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "up-long"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bottle-droplet",
    "name": "bottle-droplet",
    "iconName": "bottle-droplet",
    "prefix": "fas",
    "filter": [
      "solid",
      "bottle-droplet",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-infinity",
    "name": "infinity",
    "iconName": "infinity",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "infinity",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-i-cursor",
    "name": "i-cursor",
    "iconName": "i-cursor",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "i-cursor"
    ]
  });
  icons.push({
    "id": "fa-solid fa-record-vinyl",
    "name": "record-vinyl",
    "iconName": "record-vinyl",
    "prefix": "fas",
    "filter": [
      "solid",
      "record-vinyl",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hospital-user",
    "name": "hospital-user",
    "iconName": "hospital-user",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "buildings",
      "health",
      "users",
      "hospital-user"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chevron-left",
    "name": "chevron-left",
    "iconName": "chevron-left",
    "prefix": "fas",
    "filter": [
      "chevron-left",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-check",
    "name": "circle-check",
    "iconName": "circle-check",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "circle-check",
      "toggle",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bell-slash",
    "name": "bell-slash",
    "iconName": "bell-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "maps",
      "alert",
      "time",
      "bell-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-maximize",
    "name": "maximize",
    "iconName": "maximize",
    "prefix": "fas",
    "filter": [
      "maximize",
      "media playback",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-menorah",
    "name": "menorah",
    "iconName": "menorah",
    "prefix": "fas",
    "filter": [
      "menorah",
      "solid",
      "holidays",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-snowflake",
    "name": "snowflake",
    "iconName": "snowflake",
    "prefix": "fas",
    "filter": [
      "snowflake",
      "solid",
      "spinners",
      "weather",
      "household",
      "travel",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handshake-angle",
    "name": "handshake-angle",
    "iconName": "handshake-angle",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "accessibility",
      "charity",
      "handshake-angle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-champagne-glasses",
    "name": "champagne-glasses",
    "iconName": "champagne-glasses",
    "prefix": "fas",
    "filter": [
      "champagne-glasses",
      "solid",
      "holidays",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-waveform",
    "name": "file-waveform",
    "iconName": "file-waveform",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "file-waveform"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-h",
    "name": "circle-h",
    "iconName": "circle-h",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "circle-h",
      "alphabet",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building",
    "name": "building",
    "iconName": "building",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "buildings",
      "building",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-radiation",
    "name": "radiation",
    "iconName": "radiation",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "radiation",
      "alert",
      "science",
      "health",
      "disaster",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-check",
    "name": "user-check",
    "iconName": "user-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "users",
      "user-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bookmark",
    "name": "bookmark",
    "iconName": "bookmark",
    "prefix": "fas",
    "filter": [
      "bookmark",
      "solid",
      "maps",
      "shapes",
      "writing",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-astronaut",
    "name": "user-astronaut",
    "iconName": "user-astronaut",
    "prefix": "fas",
    "filter": [
      "solid",
      "science fiction",
      "astronomy",
      "users",
      "user-astronaut"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hill-avalanche",
    "name": "hill-avalanche",
    "iconName": "hill-avalanche",
    "prefix": "fas",
    "filter": [
      "solid",
      "humanitarian",
      "hill-avalanche",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-faucet",
    "name": "faucet",
    "iconName": "faucet",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "faucet",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-short-wide",
    "name": "arrow-down-short-wide",
    "iconName": "arrow-down-short-wide",
    "prefix": "fas",
    "filter": [
      "arrow-down-short-wide",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding-heart",
    "name": "hand-holding-heart",
    "iconName": "hand-holding-heart",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "charity",
      "hand-holding-heart"
    ]
  });
  icons.push({
    "id": "fa-solid fa-landmark-dome",
    "name": "landmark-dome",
    "iconName": "landmark-dome",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "political",
      "humanitarian",
      "landmark-dome"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-9-1",
    "name": "arrow-down-9-1",
    "iconName": "arrow-down-9-1",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-down-9-1"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-departure",
    "name": "plane-departure",
    "iconName": "plane-departure",
    "prefix": "fas",
    "filter": [
      "solid",
      "travel",
      "plane-departure"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-exclamation",
    "name": "plug-circle-exclamation",
    "iconName": "plug-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "plug-circle-exclamation",
      "solid",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-camera-retro",
    "name": "camera-retro",
    "iconName": "camera-retro",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "camera-retro",
      "devices",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cart-flatbed-suitcase",
    "name": "cart-flatbed-suitcase",
    "iconName": "cart-flatbed-suitcase",
    "prefix": "fas",
    "filter": [
      "solid",
      "cart-flatbed-suitcase",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-life-ring",
    "name": "life-ring",
    "iconName": "life-ring",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "spinners",
      "humanitarian",
      "life-ring"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mosquito-net",
    "name": "mosquito-net",
    "iconName": "mosquito-net",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "mosquito-net",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-grip-lines-vertical",
    "name": "grip-lines-vertical",
    "iconName": "grip-lines-vertical",
    "prefix": "fas",
    "filter": [
      "solid",
      "grip-lines-vertical",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toolbox",
    "name": "toolbox",
    "iconName": "toolbox",
    "prefix": "fas",
    "filter": [
      "solid",
      "toolbox",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hourglass-end",
    "name": "hourglass-end",
    "iconName": "hourglass-end",
    "prefix": "fas",
    "filter": [
      "hourglass-end",
      "solid",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-audio-description",
    "name": "audio-description",
    "iconName": "audio-description",
    "prefix": "fas",
    "filter": [
      "audio-description",
      "solid",
      "accessibility",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wind",
    "name": "wind",
    "iconName": "wind",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "weather",
      "wind",
      "disaster",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stop",
    "name": "stop",
    "iconName": "stop",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "stop"
    ]
  });
  icons.push({
    "id": "fa-solid fa-location-pin",
    "name": "location-pin",
    "iconName": "location-pin",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "social",
      "shapes",
      "location-pin"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-pie",
    "name": "chart-pie",
    "iconName": "chart-pie",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "chart-pie",
      "business",
      "money",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gopuram",
    "name": "gopuram",
    "iconName": "gopuram",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "gopuram",
      "humanitarian",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microphone-lines",
    "name": "microphone-lines",
    "iconName": "microphone-lines",
    "prefix": "fas",
    "filter": [
      "solid",
      "audio",
      "video",
      "communication",
      "microphone-lines"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clipboard",
    "name": "clipboard",
    "iconName": "clipboard",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "clipboard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lari-sign",
    "name": "lari-sign",
    "iconName": "lari-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "lari-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-wave",
    "name": "money-bill-wave",
    "iconName": "money-bill-wave",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "money-bill-wave"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-pen",
    "name": "user-pen",
    "iconName": "user-pen",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-pen",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down",
    "name": "arrow-down",
    "iconName": "arrow-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-link",
    "name": "link",
    "iconName": "link",
    "prefix": "fas",
    "filter": [
      "solid",
      "link",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toilet-paper-slash",
    "name": "toilet-paper-slash",
    "iconName": "toilet-paper-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "toilet-paper-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-phone-volume",
    "name": "phone-volume",
    "iconName": "phone-volume",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "phone-volume",
      "business",
      "maps",
      "accessibility",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-moon-rain",
    "name": "cloud-moon-rain",
    "iconName": "cloud-moon-rain",
    "prefix": "fas",
    "filter": [
      "cloud-moon-rain",
      "solid",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-music",
    "name": "music",
    "iconName": "music",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "music",
      "education",
      "maps",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-three-quarters",
    "name": "temperature-three-quarters",
    "iconName": "temperature-three-quarters",
    "prefix": "fas",
    "filter": [
      "solid",
      "temperature-three-quarters",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angle-left",
    "name": "angle-left",
    "iconName": "angle-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "angle-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-drowning",
    "name": "person-drowning",
    "iconName": "person-drowning",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-drowning",
      "users",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-indian-rupee-sign",
    "name": "indian-rupee-sign",
    "iconName": "indian-rupee-sign",
    "prefix": "fas",
    "filter": [
      "indian-rupee-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wheelchair-move",
    "name": "wheelchair-move",
    "iconName": "wheelchair-move",
    "prefix": "fas",
    "filter": [
      "wheelchair-move",
      "medical",
      "solid",
      "maps",
      "accessibility",
      "health",
      "travel",
      "users",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clover",
    "name": "clover",
    "iconName": "clover",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "shapes",
      "clover"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pen-to-square",
    "name": "pen-to-square",
    "iconName": "pen-to-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "writing",
      "pen-to-square",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-futbol",
    "name": "futbol",
    "iconName": "futbol",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "futbol"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-laugh-beam",
    "name": "face-laugh-beam",
    "iconName": "face-laugh-beam",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-laugh-beam"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-minus",
    "name": "square-minus",
    "iconName": "square-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-minus",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-turn-up",
    "name": "arrow-turn-up",
    "iconName": "arrow-turn-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-turn-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-poop",
    "name": "poop",
    "iconName": "poop",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "poop",
      "health",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-rectangle",
    "name": "users-rectangle",
    "iconName": "users-rectangle",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-rectangle",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-quarter",
    "name": "temperature-quarter",
    "iconName": "temperature-quarter",
    "prefix": "fas",
    "filter": [
      "temperature-quarter",
      "solid",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-van-shuttle",
    "name": "van-shuttle",
    "iconName": "van-shuttle",
    "prefix": "fas",
    "filter": [
      "solid",
      "van-shuttle",
      "automotive",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-un",
    "name": "building-un",
    "iconName": "building-un",
    "prefix": "fas",
    "filter": [
      "solid",
      "building-un",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-two",
    "name": "dice-two",
    "iconName": "dice-two",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-two"
    ]
  });
  icons.push({
    "id": "fa-solid fa-images",
    "name": "images",
    "iconName": "images",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "maps",
      "social"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bus-simple",
    "name": "bus-simple",
    "iconName": "bus-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "bus-simple",
      "logistics",
      "automotive",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-user",
    "name": "circle-user",
    "iconName": "circle-user",
    "prefix": "fas",
    "filter": [
      "circle-user",
      "solid",
      "social",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-turn-up",
    "name": "turn-up",
    "iconName": "turn-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "turn-up",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-video",
    "name": "video",
    "iconName": "video",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "video",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-quote-right",
    "name": "quote-right",
    "iconName": "quote-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "quote-right",
      "writing",
      "communication",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-rays",
    "name": "person-rays",
    "iconName": "person-rays",
    "prefix": "fas",
    "filter": [
      "person-rays",
      "marketing",
      "solid",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge-lock",
    "name": "bridge-lock",
    "iconName": "bridge-lock",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "humanitarian",
      "bridge-lock"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wheelchair",
    "name": "wheelchair",
    "iconName": "wheelchair",
    "prefix": "fas",
    "filter": [
      "wheelchair",
      "medical",
      "solid",
      "maps",
      "accessibility",
      "health",
      "travel",
      "users",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ellipsis",
    "name": "ellipsis",
    "iconName": "ellipsis",
    "prefix": "fas",
    "filter": [
      "solid",
      "ellipsis",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-rotate-right",
    "name": "arrow-rotate-right",
    "iconName": "arrow-rotate-right",
    "prefix": "fas",
    "filter": [
      "arrow-rotate-right",
      "media playback",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-text-width",
    "name": "text-width",
    "iconName": "text-width",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "text-width"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-to-eye",
    "name": "arrows-to-eye",
    "iconName": "arrows-to-eye",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "arrows-to-eye",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volleyball",
    "name": "volleyball",
    "iconName": "volleyball",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "volleyball"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder-open",
    "name": "folder-open",
    "iconName": "folder-open",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "writing",
      "files",
      "folder-open"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-laugh-squint",
    "name": "face-laugh-squint",
    "iconName": "face-laugh-squint",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-laugh-squint"
    ]
  });
  icons.push({
    "id": "fa-solid fa-virus-covid-slash",
    "name": "virus-covid-slash",
    "iconName": "virus-covid-slash",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "virus-covid-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stopwatch-20",
    "name": "stopwatch-20",
    "iconName": "stopwatch-20",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "stopwatch-20",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-blender-phone",
    "name": "blender-phone",
    "iconName": "blender-phone",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "communication",
      "blender-phone"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-bound",
    "name": "hands-bound",
    "iconName": "hands-bound",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "hands",
      "humanitarian",
      "hands-bound"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-medical",
    "name": "house-medical",
    "iconName": "house-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "buildings",
      "house-medical",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-rss",
    "name": "square-rss",
    "iconName": "square-rss",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-rss",
      "video",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-and-venus",
    "name": "mars-and-venus",
    "iconName": "mars-and-venus",
    "prefix": "fas",
    "filter": [
      "solid",
      "mars-and-venus",
      "genders",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cheese",
    "name": "cheese",
    "iconName": "cheese",
    "prefix": "fas",
    "filter": [
      "solid",
      "food",
      "cheese"
    ]
  });
  icons.push({
    "id": "fa-solid fa-newspaper",
    "name": "newspaper",
    "iconName": "newspaper",
    "prefix": "fas",
    "filter": [
      "newspaper",
      "solid",
      "maps",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-street-view",
    "name": "street-view",
    "iconName": "street-view",
    "prefix": "fas",
    "filter": [
      "solid",
      "street-view",
      "maps",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-laptop",
    "name": "laptop",
    "iconName": "laptop",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "laptop",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-key",
    "name": "key",
    "iconName": "key",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "maps",
      "travel",
      "key",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hospital",
    "name": "hospital",
    "iconName": "hospital",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "maps",
      "buildings",
      "health",
      "hospital",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-anchor-circle-xmark",
    "name": "anchor-circle-xmark",
    "iconName": "anchor-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "anchor-circle-xmark",
      "logistics",
      "maritime",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud",
    "name": "cloud",
    "iconName": "cloud",
    "prefix": "fas",
    "filter": [
      "cloud",
      "solid",
      "connectivity",
      "shapes",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-rook",
    "name": "chess-rook",
    "iconName": "chess-rook",
    "prefix": "fas",
    "filter": [
      "chess-rook",
      "gaming",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-staff-snake",
    "name": "staff-snake",
    "iconName": "staff-snake",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "staff-snake",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-whiskey-glass",
    "name": "whiskey-glass",
    "iconName": "whiskey-glass",
    "prefix": "fas",
    "filter": [
      "solid",
      "whiskey-glass",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-drumstick-bite",
    "name": "drumstick-bite",
    "iconName": "drumstick-bite",
    "prefix": "fas",
    "filter": [
      "solid",
      "drumstick-bite",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-plus",
    "name": "person-circle-plus",
    "iconName": "person-circle-plus",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-circle-plus",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shop-lock",
    "name": "shop-lock",
    "iconName": "shop-lock",
    "prefix": "fas",
    "filter": [
      "solid",
      "shop-lock",
      "buildings",
      "humanitarian",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-prescription-bottle-medical",
    "name": "prescription-bottle-medical",
    "iconName": "prescription-bottle-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "prescription-bottle-medical"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-tongue-wink",
    "name": "face-grin-tongue-wink",
    "iconName": "face-grin-tongue-wink",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-tongue-wink"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-crack",
    "name": "house-crack",
    "iconName": "house-crack",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "house-crack",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sun",
    "name": "sun",
    "iconName": "sun",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "weather",
      "sun",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bullhorn",
    "name": "bullhorn",
    "iconName": "bullhorn",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "political",
      "bullhorn",
      "communication",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-colon-sign",
    "name": "colon-sign",
    "iconName": "colon-sign",
    "prefix": "fas",
    "filter": [
      "colon-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vector-square",
    "name": "vector-square",
    "iconName": "vector-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "vector-square"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-spock",
    "name": "hand-spock",
    "iconName": "hand-spock",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "science fiction",
      "hand-spock"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fire-burner",
    "name": "fire-burner",
    "iconName": "fire-burner",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "household",
      "fire-burner",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-leaf",
    "name": "leaf",
    "iconName": "leaf",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "charity",
      "nature",
      "leaf",
      "vegetables",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle",
    "name": "circle",
    "iconName": "circle",
    "prefix": "fas",
    "filter": [
      "solid",
      "shapes",
      "toggle",
      "video",
      "circle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-praying",
    "name": "person-praying",
    "iconName": "person-praying",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-praying",
      "users",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-field",
    "name": "truck-field",
    "iconName": "truck-field",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "automotive",
      "truck-field",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rupiah-sign",
    "name": "rupiah-sign",
    "iconName": "rupiah-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "rupiah-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-headphones-simple",
    "name": "headphones-simple",
    "iconName": "headphones-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "headphones-simple",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-weight-scale",
    "name": "weight-scale",
    "iconName": "weight-scale",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "weight-scale",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-up-right-from-square",
    "name": "up-right-from-square",
    "iconName": "up-right-from-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "up-right-from-square",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking",
    "name": "person-walking",
    "iconName": "person-walking",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "person-walking",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-caret-up",
    "name": "caret-up",
    "iconName": "caret-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "caret-up",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-compare",
    "name": "code-compare",
    "iconName": "code-compare",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code-compare"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-fist",
    "name": "hand-fist",
    "iconName": "hand-fist",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "hands",
      "hand-fist",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-radiation",
    "name": "circle-radiation",
    "iconName": "circle-radiation",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "circle-radiation",
      "alert",
      "science",
      "health",
      "disaster",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-snowboarding",
    "name": "person-snowboarding",
    "iconName": "person-snowboarding",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "users",
      "person-snowboarding"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stapler",
    "name": "stapler",
    "iconName": "stapler",
    "prefix": "fas",
    "filter": [
      "solid",
      "stapler",
      "business",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shoe-prints",
    "name": "shoe-prints",
    "iconName": "shoe-prints",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "sports",
      "clothing",
      "shoe-prints"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sun-plant-wilt",
    "name": "sun-plant-wilt",
    "iconName": "sun-plant-wilt",
    "prefix": "fas",
    "filter": [
      "sun-plant-wilt",
      "solid",
      "weather",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-powerpoint",
    "name": "file-powerpoint",
    "iconName": "file-powerpoint",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-powerpoint",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-flag",
    "name": "flag",
    "iconName": "flag",
    "prefix": "fas",
    "filter": [
      "solid",
      "flag",
      "maps",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-water",
    "name": "water",
    "iconName": "water",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "weather",
      "maritime",
      "water",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crutch",
    "name": "crutch",
    "iconName": "crutch",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "crutch"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chalkboard",
    "name": "chalkboard",
    "iconName": "chalkboard",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "education",
      "chalkboard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-neuter",
    "name": "neuter",
    "iconName": "neuter",
    "prefix": "fas",
    "filter": [
      "solid",
      "neuter",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-tunnel",
    "name": "car-tunnel",
    "iconName": "car-tunnel",
    "prefix": "fas",
    "filter": [
      "solid",
      "car-tunnel",
      "logistics",
      "humanitarian",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-a-z",
    "name": "arrow-up-a-z",
    "iconName": "arrow-up-a-z",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-up-a-z",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-copy",
    "name": "copy",
    "iconName": "copy",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "files",
      "copy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-compass-drafting",
    "name": "compass-drafting",
    "iconName": "compass-drafting",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "compass-drafting",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-person-confined",
    "name": "square-person-confined",
    "iconName": "square-person-confined",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "square-person-confined",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-box-open",
    "name": "box-open",
    "iconName": "box-open",
    "prefix": "fas",
    "filter": [
      "solid",
      "box-open",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ruble-sign",
    "name": "ruble-sign",
    "iconName": "ruble-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "ruble-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-four",
    "name": "dice-four",
    "iconName": "dice-four",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-four"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dumbbell",
    "name": "dumbbell",
    "iconName": "dumbbell",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "dumbbell",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spell-check",
    "name": "spell-check",
    "iconName": "spell-check",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "spell-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chevron-up",
    "name": "chevron-up",
    "iconName": "chevron-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "chevron-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-network-wired",
    "name": "network-wired",
    "iconName": "network-wired",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "network-wired"
    ]
  });
  icons.push({
    "id": "fa-solid fa-subscript",
    "name": "subscript",
    "iconName": "subscript",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "subscript",
      "mathematics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-monster",
    "name": "truck-monster",
    "iconName": "truck-monster",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-monster",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-at",
    "name": "at",
    "iconName": "at",
    "prefix": "fas",
    "filter": [
      "solid",
      "at",
      "communication",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands",
    "name": "hands",
    "iconName": "hands",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "accessibility"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-user",
    "name": "house-user",
    "iconName": "house-user",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "house-user",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-rear",
    "name": "car-rear",
    "iconName": "car-rear",
    "prefix": "fas",
    "filter": [
      "solid",
      "car-rear",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-frog",
    "name": "frog",
    "iconName": "frog",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "frog",
      "nature",
      "science",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cubes-stacked",
    "name": "cubes-stacked",
    "iconName": "cubes-stacked",
    "prefix": "fas",
    "filter": [
      "cubes-stacked",
      "solid",
      "childhood",
      "shapes",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bore-hole",
    "name": "bore-hole",
    "iconName": "bore-hole",
    "prefix": "fas",
    "filter": [
      "bore-hole",
      "solid",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-chevron-right",
    "name": "circle-chevron-right",
    "iconName": "circle-chevron-right",
    "prefix": "fas",
    "filter": [
      "circle-chevron-right",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-blog",
    "name": "blog",
    "iconName": "blog",
    "prefix": "fas",
    "filter": [
      "solid",
      "writing",
      "blog"
    ]
  });
  icons.push({
    "id": "fa-solid fa-code-pull-request",
    "name": "code-pull-request",
    "iconName": "code-pull-request",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "code-pull-request"
    ]
  });
  icons.push({
    "id": "fa-solid fa-weight-hanging",
    "name": "weight-hanging",
    "iconName": "weight-hanging",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "weight-hanging"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-showers-heavy",
    "name": "cloud-showers-heavy",
    "iconName": "cloud-showers-heavy",
    "prefix": "fas",
    "filter": [
      "solid",
      "cloud-showers-heavy",
      "weather",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bus",
    "name": "bus",
    "iconName": "bus",
    "prefix": "fas",
    "filter": [
      "bus",
      "solid",
      "logistics",
      "humanitarian",
      "automotive",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bug",
    "name": "bug",
    "iconName": "bug",
    "prefix": "fas",
    "filter": [
      "coding",
      "security",
      "solid",
      "bug",
      "nature"
    ]
  });
  icons.push({
    "id": "fa-solid fa-drum",
    "name": "drum",
    "iconName": "drum",
    "prefix": "fas",
    "filter": [
      "solid",
      "drum",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-message",
    "name": "message",
    "iconName": "message",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "message",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-passport",
    "name": "passport",
    "iconName": "passport",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "passport",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking-arrow-loop-left",
    "name": "person-walking-arrow-loop-left",
    "iconName": "person-walking-arrow-loop-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-walking-arrow-loop-left",
      "users",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-zipper",
    "name": "file-zipper",
    "iconName": "file-zipper",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-zipper",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paragraph",
    "name": "paragraph",
    "iconName": "paragraph",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "paragraph",
      "solid",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-minus",
    "name": "person-circle-minus",
    "iconName": "person-circle-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-circle-minus",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bezier-curve",
    "name": "bezier-curve",
    "iconName": "bezier-curve",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "bezier-curve"
    ]
  });
  icons.push({
    "id": "fa-solid fa-socks",
    "name": "socks",
    "iconName": "socks",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "socks",
      "clothing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-check",
    "name": "file-circle-check",
    "iconName": "file-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-circle-check",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-roof",
    "name": "people-roof",
    "iconName": "people-roof",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "people-roof",
      "household",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-puzzle-piece",
    "name": "puzzle-piece",
    "iconName": "puzzle-piece",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "childhood",
      "puzzle-piece"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mill-sign",
    "name": "mill-sign",
    "iconName": "mill-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "mill-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-phone-slash",
    "name": "phone-slash",
    "iconName": "phone-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "phone-slash",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-share-from-square",
    "name": "share-from-square",
    "iconName": "share-from-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "arrows",
      "share-from-square"
    ]
  });
  icons.push({
    "id": "fa-solid fa-database",
    "name": "database",
    "iconName": "database",
    "prefix": "fas",
    "filter": [
      "database",
      "solid",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bed-pulse",
    "name": "bed-pulse",
    "iconName": "bed-pulse",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "bed-pulse"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-low",
    "name": "temperature-low",
    "iconName": "temperature-low",
    "prefix": "fas",
    "filter": [
      "temperature-low",
      "solid",
      "science",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-xmarks-lines",
    "name": "xmarks-lines",
    "iconName": "xmarks-lines",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "xmarks-lines",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chair",
    "name": "chair",
    "iconName": "chair",
    "prefix": "fas",
    "filter": [
      "solid",
      "chair",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-align-center",
    "name": "align-center",
    "iconName": "align-center",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "align-center",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-circle-xmark",
    "name": "plane-circle-xmark",
    "iconName": "plane-circle-xmark",
    "prefix": "fas",
    "filter": [
      "plane-circle-xmark",
      "solid",
      "logistics",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-battery-full",
    "name": "battery-full",
    "iconName": "battery-full",
    "prefix": "fas",
    "filter": [
      "solid",
      "battery-full",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trash-can-arrow-up",
    "name": "trash-can-arrow-up",
    "iconName": "trash-can-arrow-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "trash-can-arrow-up",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-right-from-square",
    "name": "arrow-up-right-from-square",
    "iconName": "arrow-up-right-from-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-up-right-from-square",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-dizzy",
    "name": "face-dizzy",
    "iconName": "face-dizzy",
    "prefix": "fas",
    "filter": [
      "solid",
      "face-dizzy",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-forward-fast",
    "name": "forward-fast",
    "iconName": "forward-fast",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "forward-fast"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-chevron-down",
    "name": "circle-chevron-down",
    "iconName": "circle-chevron-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "circle-chevron-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-military-rifle",
    "name": "person-military-rifle",
    "iconName": "person-military-rifle",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-military-rifle",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cart-shopping",
    "name": "cart-shopping",
    "iconName": "cart-shopping",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "cart-shopping",
      "shopping",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-monument",
    "name": "monument",
    "iconName": "monument",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "buildings",
      "monument",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-queen",
    "name": "chess-queen",
    "iconName": "chess-queen",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "chess-queen"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-location",
    "name": "magnifying-glass-location",
    "iconName": "magnifying-glass-location",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "magnifying-glass-location"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-right-dots",
    "name": "arrow-up-right-dots",
    "iconName": "arrow-up-right-dots",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "humanitarian",
      "arrow-up-right-dots"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right-long",
    "name": "arrow-right-long",
    "iconName": "arrow-right-long",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-right-long"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eye-slash",
    "name": "eye-slash",
    "iconName": "eye-slash",
    "prefix": "fas",
    "filter": [
      "images",
      "security",
      "solid",
      "maps",
      "eye-slash",
      "design",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house",
    "name": "house",
    "iconName": "house",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "buildings",
      "house"
    ]
  });
  icons.push({
    "id": "fa-solid fa-panorama",
    "name": "panorama",
    "iconName": "panorama",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "panorama"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wrench",
    "name": "wrench",
    "iconName": "wrench",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "construction",
      "wrench"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-clock",
    "name": "user-clock",
    "iconName": "user-clock",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-clock",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-baht-sign",
    "name": "baht-sign",
    "iconName": "baht-sign",
    "prefix": "fas",
    "filter": [
      "baht-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-capsules",
    "name": "capsules",
    "iconName": "capsules",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "health",
      "capsules"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scroll-torah",
    "name": "scroll-torah",
    "iconName": "scroll-torah",
    "prefix": "fas",
    "filter": [
      "scroll-torah",
      "solid",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-grip-vertical",
    "name": "grip-vertical",
    "iconName": "grip-vertical",
    "prefix": "fas",
    "filter": [
      "solid",
      "grip-vertical",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-triangle-exclamation",
    "name": "triangle-exclamation",
    "iconName": "triangle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "alert",
      "triangle-exclamation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-prescription-bottle",
    "name": "prescription-bottle",
    "iconName": "prescription-bottle",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "health",
      "prescription-bottle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clipboard-user",
    "name": "clipboard-user",
    "iconName": "clipboard-user",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "humanitarian",
      "clipboard-user",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-motorcycle",
    "name": "motorcycle",
    "iconName": "motorcycle",
    "prefix": "fas",
    "filter": [
      "motorcycle",
      "solid",
      "maps",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-phone-flip",
    "name": "square-phone-flip",
    "iconName": "square-phone-flip",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "square-phone-flip",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-surprise",
    "name": "face-surprise",
    "iconName": "face-surprise",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-surprise"
    ]
  });
  icons.push({
    "id": "fa-solid fa-golf-ball-tee",
    "name": "golf-ball-tee",
    "iconName": "golf-ball-tee",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "golf-ball-tee"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mitten",
    "name": "mitten",
    "iconName": "mitten",
    "prefix": "fas",
    "filter": [
      "solid",
      "childhood",
      "clothing",
      "mitten"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pump-medical",
    "name": "pump-medical",
    "iconName": "pump-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "pump-medical",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-share",
    "name": "share",
    "iconName": "share",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "arrows",
      "share"
    ]
  });
  icons.push({
    "id": "fa-solid fa-floppy-disk",
    "name": "floppy-disk",
    "iconName": "floppy-disk",
    "prefix": "fas",
    "filter": [
      "floppy-disk",
      "solid",
      "business",
      "devices",
      "design",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dolly",
    "name": "dolly",
    "iconName": "dolly",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "dolly",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-glasses",
    "name": "glasses",
    "iconName": "glasses",
    "prefix": "fas",
    "filter": [
      "glasses",
      "solid",
      "business",
      "clothing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-circle-arrow-right",
    "name": "building-circle-arrow-right",
    "iconName": "building-circle-arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "humanitarian",
      "building-circle-arrow-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-suitcase",
    "name": "suitcase",
    "iconName": "suitcase",
    "prefix": "fas",
    "filter": [
      "solid",
      "suitcase",
      "maps",
      "travel",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-retweet",
    "name": "retweet",
    "iconName": "retweet",
    "prefix": "fas",
    "filter": [
      "solid",
      "retweet",
      "social",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shapes",
    "name": "shapes",
    "iconName": "shapes",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "childhood",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bitcoin-sign",
    "name": "bitcoin-sign",
    "iconName": "bitcoin-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "bitcoin-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-gantt",
    "name": "chart-gantt",
    "iconName": "chart-gantt",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "chart-gantt",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-teeth-open",
    "name": "teeth-open",
    "iconName": "teeth-open",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "teeth-open",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-universal-access",
    "name": "universal-access",
    "iconName": "universal-access",
    "prefix": "fas",
    "filter": [
      "universal-access",
      "solid",
      "accessibility"
    ]
  });
  icons.push({
    "id": "fa-solid fa-map-location-dot",
    "name": "map-location-dot",
    "iconName": "map-location-dot",
    "prefix": "fas",
    "filter": [
      "map-location-dot",
      "camping",
      "solid",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-plus",
    "name": "plug-circle-plus",
    "iconName": "plug-circle-plus",
    "prefix": "fas",
    "filter": [
      "plug-circle-plus",
      "solid",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pager",
    "name": "pager",
    "iconName": "pager",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "pager",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-spin",
    "name": "arrows-spin",
    "iconName": "arrows-spin",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "spinners",
      "arrows",
      "humanitarian",
      "arrows-spin"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-side",
    "name": "car-side",
    "iconName": "car-side",
    "prefix": "fas",
    "filter": [
      "solid",
      "car-side",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-list-ol",
    "name": "list-ol",
    "iconName": "list-ol",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "list-ol"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bandage",
    "name": "bandage",
    "iconName": "bandage",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "bandage",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-transfer",
    "name": "money-bill-transfer",
    "iconName": "money-bill-transfer",
    "prefix": "fas",
    "filter": [
      "money-bill-transfer",
      "solid",
      "money",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ban",
    "name": "ban",
    "iconName": "ban",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "humanitarian",
      "ban"
    ]
  });
  icons.push({
    "id": "fa-solid fa-train-tram",
    "name": "train-tram",
    "iconName": "train-tram",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "train-tram",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-hearts",
    "name": "face-grin-hearts",
    "iconName": "face-grin-hearts",
    "prefix": "fas",
    "filter": [
      "face-grin-hearts",
      "solid",
      "emoji",
      "holidays"
    ]
  });
  icons.push({
    "id": "fa-solid fa-guitar",
    "name": "guitar",
    "iconName": "guitar",
    "prefix": "fas",
    "filter": [
      "solid",
      "audio",
      "guitar"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mattress-pillow",
    "name": "mattress-pillow",
    "iconName": "mattress-pillow",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "household",
      "humanitarian",
      "mattress-pillow"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-poll-horizontal",
    "name": "square-poll-horizontal",
    "iconName": "square-poll-horizontal",
    "prefix": "fas",
    "filter": [
      "marketing",
      "charts",
      "solid",
      "square-poll-horizontal",
      "business",
      "social",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scissors",
    "name": "scissors",
    "iconName": "scissors",
    "prefix": "fas",
    "filter": [
      "scissors",
      "solid",
      "business",
      "design",
      "files",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-arrows",
    "name": "people-arrows",
    "iconName": "people-arrows",
    "prefix": "fas",
    "filter": [
      "solid",
      "people-arrows",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-compact-disc",
    "name": "compact-disc",
    "iconName": "compact-disc",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "spinners",
      "compact-disc",
      "audio",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tv",
    "name": "tv",
    "iconName": "tv",
    "prefix": "fas",
    "filter": [
      "tv",
      "solid",
      "devices",
      "household",
      "video",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-medal",
    "name": "medal",
    "iconName": "medal",
    "prefix": "fas",
    "filter": [
      "solid",
      "sports",
      "medal"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right-from-bracket",
    "name": "arrow-right-from-bracket",
    "iconName": "arrow-right-from-bracket",
    "prefix": "fas",
    "filter": [
      "arrow-right-from-bracket",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heading",
    "name": "heading",
    "iconName": "heading",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "heading"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding-medical",
    "name": "hand-holding-medical",
    "iconName": "hand-holding-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "hands",
      "hand-holding-medical",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gift",
    "name": "gift",
    "iconName": "gift",
    "prefix": "fas",
    "filter": [
      "gift",
      "solid",
      "holidays",
      "maps",
      "charity",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-laptop-code",
    "name": "laptop-code",
    "iconName": "laptop-code",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "laptop-code",
      "education"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-1",
    "name": "money-bill-1",
    "iconName": "money-bill-1",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "money",
      "money-bill-1"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-minus",
    "name": "calendar-minus",
    "iconName": "calendar-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "calendar-minus",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rectangle-ad",
    "name": "rectangle-ad",
    "iconName": "rectangle-ad",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "rectangle-ad"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hourglass",
    "name": "hourglass",
    "iconName": "hourglass",
    "prefix": "fas",
    "filter": [
      "solid",
      "hourglass",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tablet",
    "name": "tablet",
    "iconName": "tablet",
    "prefix": "fas",
    "filter": [
      "tablet",
      "solid",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wand-sparkles",
    "name": "wand-sparkles",
    "iconName": "wand-sparkles",
    "prefix": "fas",
    "filter": [
      "gaming",
      "halloween",
      "solid",
      "wand-sparkles"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-viewfinder",
    "name": "users-viewfinder",
    "iconName": "users-viewfinder",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-viewfinder",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-field-un",
    "name": "truck-field-un",
    "iconName": "truck-field-un",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-field-un",
      "logistics",
      "automotive",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-flag",
    "name": "house-flag",
    "iconName": "house-flag",
    "prefix": "fas",
    "filter": [
      "house-flag",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-down-to-line",
    "name": "arrows-down-to-line",
    "iconName": "arrows-down-to-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows-down-to-line",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-carrot",
    "name": "carrot",
    "iconName": "carrot",
    "prefix": "fas",
    "filter": [
      "solid",
      "holidays",
      "vegetables",
      "food",
      "carrot"
    ]
  });
  icons.push({
    "id": "fa-solid fa-apple-whole",
    "name": "apple-whole",
    "iconName": "apple-whole",
    "prefix": "fas",
    "filter": [
      "apple-whole",
      "solid",
      "education",
      "childhood",
      "vegetables",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-envelope-open",
    "name": "envelope-open",
    "iconName": "envelope-open",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "writing",
      "envelope-open",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-brush",
    "name": "brush",
    "iconName": "brush",
    "prefix": "fas",
    "filter": [
      "brush",
      "solid",
      "design",
      "construction",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gauge-simple-high",
    "name": "gauge-simple-high",
    "iconName": "gauge-simple-high",
    "prefix": "fas",
    "filter": [
      "solid",
      "automotive",
      "gauge-simple-high"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-dress",
    "name": "person-dress",
    "iconName": "person-dress",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-dress",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-burst",
    "name": "person-burst",
    "iconName": "person-burst",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-burst",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gauge-simple",
    "name": "gauge-simple",
    "iconName": "gauge-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "gauge-simple",
      "automotive"
    ]
  });
  icons.push({
    "id": "fa-solid fa-object-ungroup",
    "name": "object-ungroup",
    "iconName": "object-ungroup",
    "prefix": "fas",
    "filter": [
      "object-ungroup",
      "solid",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-arrow-down",
    "name": "circle-arrow-down",
    "iconName": "circle-arrow-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "circle-arrow-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toilets-portable",
    "name": "toilets-portable",
    "iconName": "toilets-portable",
    "prefix": "fas",
    "filter": [
      "solid",
      "toilets-portable",
      "buildings",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-wheat",
    "name": "money-bill-wheat",
    "iconName": "money-bill-wheat",
    "prefix": "fas",
    "filter": [
      "solid",
      "money-bill-wheat",
      "money",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-turkish-lira-sign",
    "name": "turkish-lira-sign",
    "iconName": "turkish-lira-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "turkish-lira-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-beam-sweat",
    "name": "face-grin-beam-sweat",
    "iconName": "face-grin-beam-sweat",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-beam-sweat"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diagram-project",
    "name": "diagram-project",
    "iconName": "diagram-project",
    "prefix": "fas",
    "filter": [
      "coding",
      "charts",
      "solid",
      "diagrams",
      "diagram-project"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-showers-water",
    "name": "cloud-showers-water",
    "iconName": "cloud-showers-water",
    "prefix": "fas",
    "filter": [
      "solid",
      "cloud-showers-water",
      "weather",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cannabis",
    "name": "cannabis",
    "iconName": "cannabis",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "nature",
      "cannabis",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pallet",
    "name": "pallet",
    "iconName": "pallet",
    "prefix": "fas",
    "filter": [
      "solid",
      "pallet",
      "logistics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volume-high",
    "name": "volume-high",
    "iconName": "volume-high",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "volume-high",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jet-fighter",
    "name": "jet-fighter",
    "iconName": "jet-fighter",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "jet-fighter",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-campground",
    "name": "campground",
    "iconName": "campground",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "campground",
      "buildings"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stopwatch",
    "name": "stopwatch",
    "iconName": "stopwatch",
    "prefix": "fas",
    "filter": [
      "solid",
      "time",
      "stopwatch"
    ]
  });
  icons.push({
    "id": "fa-solid fa-handcuffs",
    "name": "handcuffs",
    "iconName": "handcuffs",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "handcuffs",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield-heart",
    "name": "shield-heart",
    "iconName": "shield-heart",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "shield-heart",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bug-slash",
    "name": "bug-slash",
    "iconName": "bug-slash",
    "prefix": "fas",
    "filter": [
      "coding",
      "security",
      "solid",
      "bug-slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-droplet",
    "name": "droplet",
    "iconName": "droplet",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "droplet",
      "maps",
      "design",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-virus-covid",
    "name": "virus-covid",
    "iconName": "virus-covid",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "virus-covid",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-smile",
    "name": "face-smile",
    "iconName": "face-smile",
    "prefix": "fas",
    "filter": [
      "solid",
      "face-smile",
      "emoji",
      "communication",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crop-simple",
    "name": "crop-simple",
    "iconName": "crop-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "crop-simple",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-board",
    "name": "chess-board",
    "iconName": "chess-board",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "chess-board"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-kiss-beam",
    "name": "face-kiss-beam",
    "iconName": "face-kiss-beam",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-kiss-beam"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand",
    "name": "hand",
    "iconName": "hand",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "hands",
      "hand"
    ]
  });
  icons.push({
    "id": "fa-solid fa-battery-empty",
    "name": "battery-empty",
    "iconName": "battery-empty",
    "prefix": "fas",
    "filter": [
      "solid",
      "battery-empty",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dumpster-fire",
    "name": "dumpster-fire",
    "iconName": "dumpster-fire",
    "prefix": "fas",
    "filter": [
      "solid",
      "construction",
      "political",
      "dumpster-fire"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-to-dot",
    "name": "arrows-to-dot",
    "iconName": "arrows-to-dot",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "arrows",
      "humanitarian",
      "arrows-to-dot"
    ]
  });
  icons.push({
    "id": "fa-solid fa-store-slash",
    "name": "store-slash",
    "iconName": "store-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "store-slash",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-squint-tears",
    "name": "face-grin-squint-tears",
    "iconName": "face-grin-squint-tears",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-squint-tears"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fire-flame-curved",
    "name": "fire-flame-curved",
    "iconName": "fire-flame-curved",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "sports",
      "fire-flame-curved",
      "science",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-church",
    "name": "church",
    "iconName": "church",
    "prefix": "fas",
    "filter": [
      "solid",
      "church",
      "buildings",
      "humanitarian",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-helicopter",
    "name": "helicopter",
    "iconName": "helicopter",
    "prefix": "fas",
    "filter": [
      "solid",
      "helicopter",
      "maps",
      "logistics",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-kiss-wink-heart",
    "name": "face-kiss-wink-heart",
    "iconName": "face-kiss-wink-heart",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "holidays",
      "face-kiss-wink-heart"
    ]
  });
  icons.push({
    "id": "fa-solid fa-laptop-file",
    "name": "laptop-file",
    "iconName": "laptop-file",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "business",
      "devices",
      "laptop-file",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess",
    "name": "chess",
    "iconName": "chess",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "chess"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trash-arrow-up",
    "name": "trash-arrow-up",
    "iconName": "trash-arrow-up",
    "prefix": "fas",
    "filter": [
      "trash-arrow-up",
      "solid",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trophy",
    "name": "trophy",
    "iconName": "trophy",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "trophy",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-chimney",
    "name": "house-chimney",
    "iconName": "house-chimney",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "house-chimney",
      "humanitarian",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-compress",
    "name": "compress",
    "iconName": "compress",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "compress"
    ]
  });
  icons.push({
    "id": "fa-solid fa-notdef",
    "name": "notdef",
    "iconName": "notdef",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "notdef",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bread-slice",
    "name": "bread-slice",
    "iconName": "bread-slice",
    "prefix": "fas",
    "filter": [
      "solid",
      "bread-slice",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shower",
    "name": "shower",
    "iconName": "shower",
    "prefix": "fas",
    "filter": [
      "shower",
      "solid",
      "maps",
      "household",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-minimize",
    "name": "minimize",
    "iconName": "minimize",
    "prefix": "fas",
    "filter": [
      "minimize",
      "images",
      "media playback",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toilet",
    "name": "toilet",
    "iconName": "toilet",
    "prefix": "fas",
    "filter": [
      "toilet",
      "solid",
      "household",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-half",
    "name": "temperature-half",
    "iconName": "temperature-half",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "temperature-half"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bugs",
    "name": "bugs",
    "iconName": "bugs",
    "prefix": "fas",
    "filter": [
      "bugs",
      "solid",
      "nature",
      "animals",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diamond",
    "name": "diamond",
    "iconName": "diamond",
    "prefix": "fas",
    "filter": [
      "diamond",
      "gaming",
      "solid",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sterling-sign",
    "name": "sterling-sign",
    "iconName": "sterling-sign",
    "prefix": "fas",
    "filter": [
      "sterling-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-through-window",
    "name": "person-through-window",
    "iconName": "person-through-window",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-through-window",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-days",
    "name": "calendar-days",
    "iconName": "calendar-days",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "time",
      "calendar-days"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-meh-blank",
    "name": "face-meh-blank",
    "iconName": "face-meh-blank",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-meh-blank"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bowl-rice",
    "name": "bowl-rice",
    "iconName": "bowl-rice",
    "prefix": "fas",
    "filter": [
      "bowl-rice",
      "solid",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school-circle-xmark",
    "name": "school-circle-xmark",
    "iconName": "school-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "buildings",
      "school-circle-xmark",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-half-dress",
    "name": "person-half-dress",
    "iconName": "person-half-dress",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "person-half-dress",
      "health",
      "genders",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-lock",
    "name": "plane-lock",
    "iconName": "plane-lock",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "plane-lock",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-list",
    "name": "list",
    "iconName": "list",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "list"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-parking",
    "name": "square-parking",
    "iconName": "square-parking",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "square-parking"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-caret-down",
    "name": "square-caret-down",
    "iconName": "square-caret-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "square-caret-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paperclip",
    "name": "paperclip",
    "iconName": "paperclip",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "paperclip",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-circle-exclamation",
    "name": "road-circle-exclamation",
    "iconName": "road-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "road-circle-exclamation",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-play",
    "name": "circle-play",
    "iconName": "circle-play",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "circle-play"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-user",
    "name": "building-user",
    "iconName": "building-user",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "humanitarian",
      "building-user"
    ]
  });
  icons.push({
    "id": "fa-solid fa-battery-quarter",
    "name": "battery-quarter",
    "iconName": "battery-quarter",
    "prefix": "fas",
    "filter": [
      "solid",
      "battery-quarter",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-chimney-medical",
    "name": "house-chimney-medical",
    "iconName": "house-chimney-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "house-chimney-medical",
      "buildings",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mercury",
    "name": "mercury",
    "iconName": "mercury",
    "prefix": "fas",
    "filter": [
      "solid",
      "mercury",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment-dots",
    "name": "comment-dots",
    "iconName": "comment-dots",
    "prefix": "fas",
    "filter": [
      "solid",
      "comment-dots",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mosque",
    "name": "mosque",
    "iconName": "mosque",
    "prefix": "fas",
    "filter": [
      "mosque",
      "solid",
      "buildings",
      "humanitarian",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-notes-medical",
    "name": "notes-medical",
    "iconName": "notes-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "notes-medical",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-charging-station",
    "name": "charging-station",
    "iconName": "charging-station",
    "prefix": "fas",
    "filter": [
      "solid",
      "charging-station",
      "automotive",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-archway",
    "name": "archway",
    "iconName": "archway",
    "prefix": "fas",
    "filter": [
      "archway",
      "solid",
      "buildings",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grimace",
    "name": "face-grimace",
    "iconName": "face-grimace",
    "prefix": "fas",
    "filter": [
      "solid",
      "face-grimace",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-om",
    "name": "om",
    "iconName": "om",
    "prefix": "fas",
    "filter": [
      "solid",
      "om",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-flask",
    "name": "flask",
    "iconName": "flask",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "maps",
      "science",
      "health",
      "flask",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school",
    "name": "school",
    "iconName": "school",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "childhood",
      "school",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-head-side-mask",
    "name": "head-side-mask",
    "iconName": "head-side-mask",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "head-side-mask",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rotate-left",
    "name": "rotate-left",
    "iconName": "rotate-left",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "rotate-left",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-prescription",
    "name": "file-prescription",
    "iconName": "file-prescription",
    "prefix": "fas",
    "filter": [
      "file-prescription",
      "medical",
      "solid",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-skull",
    "name": "book-skull",
    "iconName": "book-skull",
    "prefix": "fas",
    "filter": [
      "gaming",
      "halloween",
      "solid",
      "book-skull"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ranking-star",
    "name": "ranking-star",
    "iconName": "ranking-star",
    "prefix": "fas",
    "filter": [
      "ranking-star",
      "marketing",
      "solid",
      "sports",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-full",
    "name": "square-full",
    "iconName": "square-full",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "square-full"
    ]
  });
  icons.push({
    "id": "fa-solid fa-couch",
    "name": "couch",
    "iconName": "couch",
    "prefix": "fas",
    "filter": [
      "solid",
      "couch",
      "household",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-keyboard",
    "name": "keyboard",
    "iconName": "keyboard",
    "prefix": "fas",
    "filter": [
      "keyboard",
      "coding",
      "solid",
      "devices",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-litecoin-sign",
    "name": "litecoin-sign",
    "iconName": "litecoin-sign",
    "prefix": "fas",
    "filter": [
      "litecoin-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-medical-flag",
    "name": "house-medical-flag",
    "iconName": "house-medical-flag",
    "prefix": "fas",
    "filter": [
      "house-medical-flag",
      "medical",
      "solid",
      "buildings",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-expand",
    "name": "expand",
    "iconName": "expand",
    "prefix": "fas",
    "filter": [
      "expand",
      "media playback",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paste",
    "name": "paste",
    "iconName": "paste",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "design",
      "files",
      "paste"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table-tennis-paddle-ball",
    "name": "table-tennis-paddle-ball",
    "iconName": "table-tennis-paddle-ball",
    "prefix": "fas",
    "filter": [
      "solid",
      "table-tennis-paddle-ball",
      "sports"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tablet-button",
    "name": "tablet-button",
    "iconName": "tablet-button",
    "prefix": "fas",
    "filter": [
      "tablet-button",
      "solid",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-basketball",
    "name": "basketball",
    "iconName": "basketball",
    "prefix": "fas",
    "filter": [
      "basketball",
      "solid",
      "sports"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wallet",
    "name": "wallet",
    "iconName": "wallet",
    "prefix": "fas",
    "filter": [
      "solid",
      "wallet",
      "business",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-running",
    "name": "person-running",
    "iconName": "person-running",
    "prefix": "fas",
    "filter": [
      "person-running",
      "solid",
      "sports",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-window-minimize",
    "name": "window-minimize",
    "iconName": "window-minimize",
    "prefix": "fas",
    "filter": [
      "window-minimize",
      "coding",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-atlas",
    "name": "book-atlas",
    "iconName": "book-atlas",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "book-atlas",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-stairs",
    "name": "stairs",
    "iconName": "stairs",
    "prefix": "fas",
    "filter": [
      "solid",
      "stairs",
      "household",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-unlock",
    "name": "unlock",
    "iconName": "unlock",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "unlock"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-1-9",
    "name": "arrow-down-1-9",
    "iconName": "arrow-down-1-9",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-down-1-9"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tent-arrows-down",
    "name": "tent-arrows-down",
    "iconName": "tent-arrows-down",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "buildings",
      "tent-arrows-down",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin",
    "name": "face-grin",
    "iconName": "face-grin",
    "prefix": "fas",
    "filter": [
      "solid",
      "face-grin",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-long",
    "name": "arrow-down-long",
    "iconName": "arrow-down-long",
    "prefix": "fas",
    "filter": [
      "arrow-down-long",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart",
    "name": "heart",
    "iconName": "heart",
    "prefix": "fas",
    "filter": [
      "gaming",
      "medical",
      "solid",
      "holidays",
      "maps",
      "sports",
      "charity",
      "social",
      "shapes",
      "health",
      "heart",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dollar-sign",
    "name": "dollar-sign",
    "iconName": "dollar-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "money",
      "charity",
      "dollar-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-khanda",
    "name": "khanda",
    "iconName": "khanda",
    "prefix": "fas",
    "filter": [
      "solid",
      "khanda",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-five",
    "name": "dice-five",
    "iconName": "dice-five",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-five",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diamond-turn-right",
    "name": "diamond-turn-right",
    "iconName": "diamond-turn-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "diamond-turn-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hammer",
    "name": "hammer",
    "iconName": "hammer",
    "prefix": "fas",
    "filter": [
      "solid",
      "hammer",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-check-to-slot",
    "name": "check-to-slot",
    "iconName": "check-to-slot",
    "prefix": "fas",
    "filter": [
      "solid",
      "political",
      "check-to-slot"
    ]
  });
  icons.push({
    "id": "fa-solid fa-locust",
    "name": "locust",
    "iconName": "locust",
    "prefix": "fas",
    "filter": [
      "locust",
      "solid",
      "nature",
      "animals",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-umbrella",
    "name": "umbrella",
    "iconName": "umbrella",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "umbrella",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-genderless",
    "name": "genderless",
    "iconName": "genderless",
    "prefix": "fas",
    "filter": [
      "solid",
      "genderless",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-kit-medical",
    "name": "kit-medical",
    "iconName": "kit-medical",
    "prefix": "fas",
    "filter": [
      "camping",
      "medical",
      "solid",
      "health",
      "kit-medical"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-smile-wink",
    "name": "face-smile-wink",
    "iconName": "face-smile-wink",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-smile-wink"
    ]
  });
  icons.push({
    "id": "fa-solid fa-unlock-keyhole",
    "name": "unlock-keyhole",
    "iconName": "unlock-keyhole",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "unlock-keyhole"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-9-1",
    "name": "arrow-up-9-1",
    "iconName": "arrow-up-9-1",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-up-9-1"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-pen",
    "name": "square-pen",
    "iconName": "square-pen",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "writing",
      "square-pen",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-envelope",
    "name": "square-envelope",
    "iconName": "square-envelope",
    "prefix": "fas",
    "filter": [
      "solid",
      "square-envelope",
      "business",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-florin-sign",
    "name": "florin-sign",
    "iconName": "florin-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "florin-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-long",
    "name": "arrow-up-long",
    "iconName": "arrow-up-long",
    "prefix": "fas",
    "filter": [
      "arrow-up-long",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-robbery",
    "name": "people-robbery",
    "iconName": "people-robbery",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "people-robbery",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-location-crosshairs",
    "name": "location-crosshairs",
    "iconName": "location-crosshairs",
    "prefix": "fas",
    "filter": [
      "location-crosshairs",
      "solid",
      "maps",
      "toggle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-walkie-talkie",
    "name": "walkie-talkie",
    "iconName": "walkie-talkie",
    "prefix": "fas",
    "filter": [
      "walkie-talkie",
      "solid",
      "devices",
      "communication",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dna",
    "name": "dna",
    "iconName": "dna",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "dna",
      "science",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-image",
    "name": "file-image",
    "iconName": "file-image",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "file-image",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diagram-predecessor",
    "name": "diagram-predecessor",
    "iconName": "diagram-predecessor",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "diagram-predecessor",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-dollar-to-slot",
    "name": "circle-dollar-to-slot",
    "iconName": "circle-dollar-to-slot",
    "prefix": "fas",
    "filter": [
      "solid",
      "circle-dollar-to-slot",
      "money",
      "charity",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-martini-glass",
    "name": "martini-glass",
    "iconName": "martini-glass",
    "prefix": "fas",
    "filter": [
      "solid",
      "martini-glass",
      "travel",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-pregnant",
    "name": "person-pregnant",
    "iconName": "person-pregnant",
    "prefix": "fas",
    "filter": [
      "person-pregnant",
      "solid",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vial",
    "name": "vial",
    "iconName": "vial",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "vial",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-people-line",
    "name": "people-line",
    "iconName": "people-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "people-line",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-euro-sign",
    "name": "euro-sign",
    "iconName": "euro-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "euro-sign",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right-to-city",
    "name": "arrow-right-to-city",
    "iconName": "arrow-right-to-city",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "arrow-right-to-city",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-share-nodes",
    "name": "square-share-nodes",
    "iconName": "square-share-nodes",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "square-share-nodes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-envelopes-bulk",
    "name": "envelopes-bulk",
    "iconName": "envelopes-bulk",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "envelopes-bulk"
    ]
  });
  icons.push({
    "id": "fa-solid fa-window-restore",
    "name": "window-restore",
    "iconName": "window-restore",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "window-restore"
    ]
  });
  icons.push({
    "id": "fa-solid fa-share-nodes",
    "name": "share-nodes",
    "iconName": "share-nodes",
    "prefix": "fas",
    "filter": [
      "solid",
      "share-nodes",
      "social"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mobile-screen-button",
    "name": "mobile-screen-button",
    "iconName": "mobile-screen-button",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "mobile-screen-button",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ring",
    "name": "ring",
    "iconName": "ring",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "ring",
      "spinners"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plus-minus",
    "name": "plus-minus",
    "iconName": "plus-minus",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "mathematics",
      "plus-minus"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toggle-on",
    "name": "toggle-on",
    "iconName": "toggle-on",
    "prefix": "fas",
    "filter": [
      "toggle-on",
      "solid",
      "toggle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-guarani-sign",
    "name": "guarani-sign",
    "iconName": "guarani-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "guarani-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-circle-xmark",
    "name": "building-circle-xmark",
    "iconName": "building-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "building-circle-xmark",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-up",
    "name": "plane-up",
    "iconName": "plane-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle",
      "plane-up",
      "travel",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-dot",
    "name": "circle-dot",
    "iconName": "circle-dot",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle",
      "circle-dot"
    ]
  });
  icons.push({
    "id": "fa-solid fa-filter-circle-dollar",
    "name": "filter-circle-dollar",
    "iconName": "filter-circle-dollar",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "filter-circle-dollar"
    ]
  });
  icons.push({
    "id": "fa-solid fa-address-card",
    "name": "address-card",
    "iconName": "address-card",
    "prefix": "fas",
    "filter": [
      "address-card",
      "solid",
      "business",
      "accessibility",
      "alphabet",
      "communication",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hotdog",
    "name": "hotdog",
    "iconName": "hotdog",
    "prefix": "fas",
    "filter": [
      "solid",
      "hotdog",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hourglass-start",
    "name": "hourglass-start",
    "iconName": "hourglass-start",
    "prefix": "fas",
    "filter": [
      "solid",
      "time",
      "hourglass-start"
    ]
  });
  icons.push({
    "id": "fa-solid fa-pizza-slice",
    "name": "pizza-slice",
    "iconName": "pizza-slice",
    "prefix": "fas",
    "filter": [
      "solid",
      "pizza-slice",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tablet-screen-button",
    "name": "tablet-screen-button",
    "iconName": "tablet-screen-button",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "tablet-screen-button"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-tired",
    "name": "face-tired",
    "iconName": "face-tired",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-tired"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cross",
    "name": "cross",
    "iconName": "cross",
    "prefix": "fas",
    "filter": [
      "solid",
      "cross",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-laptop",
    "name": "house-laptop",
    "iconName": "house-laptop",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "devices",
      "household",
      "house-laptop"
    ]
  });
  icons.push({
    "id": "fa-solid fa-location-pin-lock",
    "name": "location-pin-lock",
    "iconName": "location-pin-lock",
    "prefix": "fas",
    "filter": [
      "location-pin-lock",
      "solid",
      "maps",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-injured",
    "name": "user-injured",
    "iconName": "user-injured",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-injured",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-plane",
    "name": "truck-plane",
    "iconName": "truck-plane",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "truck-plane",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-circle-check",
    "name": "building-circle-check",
    "iconName": "building-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "humanitarian",
      "building-circle-check"
    ]
  });
  icons.push({
    "id": "fa-solid fa-graduation-cap",
    "name": "graduation-cap",
    "iconName": "graduation-cap",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "maps",
      "clothing",
      "graduation-cap"
    ]
  });
  icons.push({
    "id": "fa-solid fa-atom",
    "name": "atom",
    "iconName": "atom",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "science fiction",
      "spinners",
      "science",
      "atom",
      "energy",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-biohazard",
    "name": "biohazard",
    "iconName": "biohazard",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "biohazard",
      "science",
      "health",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spoon",
    "name": "spoon",
    "iconName": "spoon",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "spoon",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-broom",
    "name": "broom",
    "iconName": "broom",
    "prefix": "fas",
    "filter": [
      "halloween",
      "solid",
      "broom"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vial-circle-check",
    "name": "vial-circle-check",
    "iconName": "vial-circle-check",
    "prefix": "fas",
    "filter": [
      "vial-circle-check",
      "medical",
      "solid",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hurricane",
    "name": "hurricane",
    "iconName": "hurricane",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "weather",
      "hurricane",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-export",
    "name": "file-export",
    "iconName": "file-export",
    "prefix": "fas",
    "filter": [
      "file-export",
      "solid",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-gear",
    "name": "user-gear",
    "iconName": "user-gear",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-gear",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paper-plane",
    "name": "paper-plane",
    "iconName": "paper-plane",
    "prefix": "fas",
    "filter": [
      "solid",
      "writing",
      "communication",
      "paper-plane",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tower-broadcast",
    "name": "tower-broadcast",
    "iconName": "tower-broadcast",
    "prefix": "fas",
    "filter": [
      "solid",
      "connectivity",
      "tower-broadcast",
      "video",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-trend-up",
    "name": "money-bill-trend-up",
    "iconName": "money-bill-trend-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "money-bill-trend-up",
      "money",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mobile-screen",
    "name": "mobile-screen",
    "iconName": "mobile-screen",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "mobile-screen",
      "humanitarian",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-basket-shopping",
    "name": "basket-shopping",
    "iconName": "basket-shopping",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "basket-shopping",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-right",
    "name": "arrow-right",
    "iconName": "arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-right",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking-arrow-right",
    "name": "person-walking-arrow-right",
    "iconName": "person-walking-arrow-right",
    "prefix": "fas",
    "filter": [
      "person-walking-arrow-right",
      "solid",
      "users",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-flag-checkered",
    "name": "flag-checkered",
    "iconName": "flag-checkered",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "flag-checkered"
    ]
  });
  icons.push({
    "id": "fa-solid fa-border-none",
    "name": "border-none",
    "iconName": "border-none",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "border-none"
    ]
  });
  icons.push({
    "id": "fa-solid fa-city",
    "name": "city",
    "iconName": "city",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "city",
      "buildings"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-king",
    "name": "chess-king",
    "iconName": "chess-king",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "chess-king"
    ]
  });
  icons.push({
    "id": "fa-solid fa-backward",
    "name": "backward",
    "iconName": "backward",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "backward"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-column",
    "name": "chart-column",
    "iconName": "chart-column",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "diagrams",
      "chart-column"
    ]
  });
  icons.push({
    "id": "fa-solid fa-caret-left",
    "name": "caret-left",
    "iconName": "caret-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "caret-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-divide",
    "name": "divide",
    "iconName": "divide",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "divide"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-fire",
    "name": "house-fire",
    "iconName": "house-fire",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "buildings",
      "house-fire",
      "household",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-stroke-right",
    "name": "mars-stroke-right",
    "iconName": "mars-stroke-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "mars-stroke-right",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ruler-combined",
    "name": "ruler-combined",
    "iconName": "ruler-combined",
    "prefix": "fas",
    "filter": [
      "ruler-combined",
      "solid",
      "design",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-bolt",
    "name": "heart-circle-bolt",
    "iconName": "heart-circle-bolt",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "heart-circle-bolt",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-greater-than",
    "name": "greater-than",
    "iconName": "greater-than",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "greater-than",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cookie",
    "name": "cookie",
    "iconName": "cookie",
    "prefix": "fas",
    "filter": [
      "solid",
      "cookie",
      "childhood",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-beam",
    "name": "face-grin-beam",
    "iconName": "face-grin-beam",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-beam"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fire-flame-simple",
    "name": "fire-flame-simple",
    "iconName": "fire-flame-simple",
    "prefix": "fas",
    "filter": [
      "fire-flame-simple",
      "medical",
      "solid",
      "sports",
      "science",
      "health",
      "humanitarian",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-swimming",
    "name": "person-swimming",
    "iconName": "person-swimming",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-swimming",
      "sports",
      "maritime",
      "travel",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-bar",
    "name": "chart-bar",
    "iconName": "chart-bar",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "chart-bar",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-satellite-dish",
    "name": "satellite-dish",
    "iconName": "satellite-dish",
    "prefix": "fas",
    "filter": [
      "solid",
      "connectivity",
      "devices",
      "satellite-dish",
      "astronomy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-head-side-cough",
    "name": "head-side-cough",
    "iconName": "head-side-cough",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "head-side-cough",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spaghetti-monster-flying",
    "name": "spaghetti-monster-flying",
    "iconName": "spaghetti-monster-flying",
    "prefix": "fas",
    "filter": [
      "solid",
      "spaghetti-monster-flying",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jar-wheat",
    "name": "jar-wheat",
    "iconName": "jar-wheat",
    "prefix": "fas",
    "filter": [
      "solid",
      "jar-wheat",
      "household",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-skull",
    "name": "skull",
    "iconName": "skull",
    "prefix": "fas",
    "filter": [
      "halloween",
      "medical",
      "solid",
      "health",
      "skull",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-down-long",
    "name": "down-long",
    "iconName": "down-long",
    "prefix": "fas",
    "filter": [
      "down-long",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-utensils",
    "name": "utensils",
    "iconName": "utensils",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "household",
      "utensils",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-child-reaching",
    "name": "child-reaching",
    "iconName": "child-reaching",
    "prefix": "fas",
    "filter": [
      "solid",
      "child-reaching",
      "childhood",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-comment",
    "name": "comment",
    "iconName": "comment",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "shapes",
      "comment",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gear",
    "name": "gear",
    "iconName": "gear",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "spinners",
      "gear",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bacteria",
    "name": "bacteria",
    "iconName": "bacteria",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "bacteria",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mountain-city",
    "name": "mountain-city",
    "iconName": "mountain-city",
    "prefix": "fas",
    "filter": [
      "solid",
      "nature",
      "buildings",
      "travel",
      "mountain-city",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-crack",
    "name": "heart-crack",
    "iconName": "heart-crack",
    "prefix": "fas",
    "filter": [
      "solid",
      "shapes",
      "heart-crack"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-pickup",
    "name": "truck-pickup",
    "iconName": "truck-pickup",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-pickup",
      "construction",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-id-card-clip",
    "name": "id-card-clip",
    "iconName": "id-card-clip",
    "prefix": "fas",
    "filter": [
      "medical",
      "security",
      "solid",
      "id-card-clip",
      "health",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-medical",
    "name": "file-medical",
    "iconName": "file-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "file-medical",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-spider",
    "name": "spider",
    "iconName": "spider",
    "prefix": "fas",
    "filter": [
      "halloween",
      "solid",
      "nature",
      "animals",
      "spider"
    ]
  });
  icons.push({
    "id": "fa-solid fa-right-to-bracket",
    "name": "right-to-bracket",
    "iconName": "right-to-bracket",
    "prefix": "fas",
    "filter": [
      "right-to-bracket",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-signs-post",
    "name": "signs-post",
    "iconName": "signs-post",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "nature",
      "signs-post"
    ]
  });
  icons.push({
    "id": "fa-solid fa-naira-sign",
    "name": "naira-sign",
    "iconName": "naira-sign",
    "prefix": "fas",
    "filter": [
      "naira-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school-flag",
    "name": "school-flag",
    "iconName": "school-flag",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "buildings",
      "school-flag",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-briefcase",
    "name": "briefcase",
    "iconName": "briefcase",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "briefcase",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-half-stroke",
    "name": "circle-half-stroke",
    "iconName": "circle-half-stroke",
    "prefix": "fas",
    "filter": [
      "charts",
      "images",
      "solid",
      "circle-half-stroke",
      "shapes",
      "design",
      "diagrams",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-minus",
    "name": "heart-circle-minus",
    "iconName": "heart-circle-minus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "humanitarian",
      "heart-circle-minus"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bed",
    "name": "bed",
    "iconName": "bed",
    "prefix": "fas",
    "filter": [
      "bed",
      "solid",
      "maps",
      "household",
      "humanitarian",
      "travel",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-restroom",
    "name": "restroom",
    "iconName": "restroom",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "users",
      "restroom"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wheat-awn-circle-exclamation",
    "name": "wheat-awn-circle-exclamation",
    "iconName": "wheat-awn-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "wheat-awn-circle-exclamation",
      "food",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-palette",
    "name": "palette",
    "iconName": "palette",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "design",
      "palette"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-large",
    "name": "user-large",
    "iconName": "user-large",
    "prefix": "fas",
    "filter": [
      "solid",
      "user-large",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-right",
    "name": "circle-right",
    "iconName": "circle-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "circle-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ribbon",
    "name": "ribbon",
    "iconName": "ribbon",
    "prefix": "fas",
    "filter": [
      "solid",
      "charity",
      "ribbon"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hockey-puck",
    "name": "hockey-puck",
    "iconName": "hockey-puck",
    "prefix": "fas",
    "filter": [
      "solid",
      "hockey-puck",
      "sports"
    ]
  });
  icons.push({
    "id": "fa-solid fa-blender",
    "name": "blender",
    "iconName": "blender",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "blender",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up",
    "name": "arrow-up",
    "iconName": "arrow-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-up"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-check",
    "name": "heart-circle-check",
    "iconName": "heart-circle-check",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "heart-circle-check",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user-nurse",
    "name": "user-nurse",
    "iconName": "user-nurse",
    "prefix": "fas",
    "filter": [
      "user-nurse",
      "medical",
      "solid",
      "health",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-to-circle",
    "name": "arrows-to-circle",
    "iconName": "arrows-to-circle",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "humanitarian",
      "arrows-to-circle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-compass",
    "name": "compass",
    "iconName": "compass",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "business",
      "compass",
      "spinners"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gauge",
    "name": "gauge",
    "iconName": "gauge",
    "prefix": "fas",
    "filter": [
      "gauge",
      "solid",
      "automotive"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-meh",
    "name": "face-meh",
    "iconName": "face-meh",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-meh",
      "communication",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cart-plus",
    "name": "cart-plus",
    "iconName": "cart-plus",
    "prefix": "fas",
    "filter": [
      "solid",
      "cart-plus",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-barcode",
    "name": "barcode",
    "iconName": "barcode",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "barcode",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bone",
    "name": "bone",
    "iconName": "bone",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "bone",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rotate",
    "name": "rotate",
    "iconName": "rotate",
    "prefix": "fas",
    "filter": [
      "rotate",
      "media playback",
      "solid",
      "spinners",
      "arrows",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-reply-all",
    "name": "reply-all",
    "iconName": "reply-all",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "reply-all"
    ]
  });
  icons.push({
    "id": "fa-solid fa-link-slash",
    "name": "link-slash",
    "iconName": "link-slash",
    "prefix": "fas",
    "filter": [
      "link-slash",
      "solid",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-dollar",
    "name": "magnifying-glass-dollar",
    "iconName": "magnifying-glass-dollar",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "magnifying-glass-dollar"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jug-detergent",
    "name": "jug-detergent",
    "iconName": "jug-detergent",
    "prefix": "fas",
    "filter": [
      "jug-detergent",
      "solid",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-gear",
    "name": "users-gear",
    "iconName": "users-gear",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-gear",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-business-time",
    "name": "business-time",
    "iconName": "business-time",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "business-time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bag-shopping",
    "name": "bag-shopping",
    "iconName": "bag-shopping",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "bag-shopping",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crown",
    "name": "crown",
    "iconName": "crown",
    "prefix": "fas",
    "filter": [
      "solid",
      "shapes",
      "crown"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bong",
    "name": "bong",
    "iconName": "bong",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "bong",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angles-down",
    "name": "angles-down",
    "iconName": "angles-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "angles-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-elevator",
    "name": "elevator",
    "iconName": "elevator",
    "prefix": "fas",
    "filter": [
      "solid",
      "elevator",
      "travel",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-six",
    "name": "dice-six",
    "iconName": "dice-six",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-six"
    ]
  });
  icons.push({
    "id": "fa-solid fa-kitchen-set",
    "name": "kitchen-set",
    "iconName": "kitchen-set",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "kitchen-set",
      "humanitarian",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-brazilian-real-sign",
    "name": "brazilian-real-sign",
    "iconName": "brazilian-real-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "brazilian-real-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-turn-down",
    "name": "turn-down",
    "iconName": "turn-down",
    "prefix": "fas",
    "filter": [
      "turn-down",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-rotate-left",
    "name": "arrow-rotate-left",
    "iconName": "arrow-rotate-left",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "arrow-rotate-left",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-caret-down",
    "name": "caret-down",
    "iconName": "caret-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "caret-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-feather-pointed",
    "name": "feather-pointed",
    "iconName": "feather-pointed",
    "prefix": "fas",
    "filter": [
      "solid",
      "feather-pointed",
      "nature",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mountain-sun",
    "name": "mountain-sun",
    "iconName": "mountain-sun",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "nature",
      "mountain-sun",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-notch",
    "name": "circle-notch",
    "iconName": "circle-notch",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "circle-notch"
    ]
  });
  icons.push({
    "id": "fa-solid fa-power-off",
    "name": "power-off",
    "iconName": "power-off",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "power-off",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-icicles",
    "name": "icicles",
    "iconName": "icicles",
    "prefix": "fas",
    "filter": [
      "icicles",
      "solid",
      "nature",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wave-square",
    "name": "wave-square",
    "iconName": "wave-square",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "wave-square"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-turn-to-dots",
    "name": "arrows-turn-to-dots",
    "iconName": "arrows-turn-to-dots",
    "prefix": "fas",
    "filter": [
      "arrows-turn-to-dots",
      "solid",
      "arrows",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-from-water-pump",
    "name": "arrow-up-from-water-pump",
    "iconName": "arrow-up-from-water-pump",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "arrow-up-from-water-pump",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-slash",
    "name": "slash",
    "iconName": "slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "slash"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-chart",
    "name": "magnifying-glass-chart",
    "iconName": "magnifying-glass-chart",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "magnifying-glass-chart",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rectangle-list",
    "name": "rectangle-list",
    "iconName": "rectangle-list",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "rectangle-list"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wine-glass-empty",
    "name": "wine-glass-empty",
    "iconName": "wine-glass-empty",
    "prefix": "fas",
    "filter": [
      "solid",
      "wine-glass-empty",
      "travel",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-less-than",
    "name": "less-than",
    "iconName": "less-than",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "less-than",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book",
    "name": "book",
    "iconName": "book",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "book",
      "writing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-minus",
    "name": "magnifying-glass-minus",
    "iconName": "magnifying-glass-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "magnifying-glass-minus"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-military-to-person",
    "name": "person-military-to-person",
    "iconName": "person-military-to-person",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-military-to-person",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-bolt",
    "name": "cloud-bolt",
    "iconName": "cloud-bolt",
    "prefix": "fas",
    "filter": [
      "solid",
      "cloud-bolt",
      "weather",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bacon",
    "name": "bacon",
    "iconName": "bacon",
    "prefix": "fas",
    "filter": [
      "bacon",
      "solid",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-wide-short",
    "name": "arrow-up-wide-short",
    "iconName": "arrow-up-wide-short",
    "prefix": "fas",
    "filter": [
      "arrow-up-wide-short",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-check",
    "name": "square-check",
    "iconName": "square-check",
    "prefix": "fas",
    "filter": [
      "square-check",
      "text formatting",
      "solid",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-pawn",
    "name": "chess-pawn",
    "iconName": "chess-pawn",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "chess-pawn"
    ]
  });
  icons.push({
    "id": "fa-solid fa-moon",
    "name": "moon",
    "iconName": "moon",
    "prefix": "fas",
    "filter": [
      "moon",
      "solid",
      "weather",
      "astronomy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jar",
    "name": "jar",
    "iconName": "jar",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "jar",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mobile-button",
    "name": "mobile-button",
    "iconName": "mobile-button",
    "prefix": "fas",
    "filter": [
      "mobile-button",
      "solid",
      "devices",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-invoice-dollar",
    "name": "file-invoice-dollar",
    "iconName": "file-invoice-dollar",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-invoice-dollar",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shop",
    "name": "shop",
    "iconName": "shop",
    "prefix": "fas",
    "filter": [
      "solid",
      "shop",
      "buildings",
      "humanitarian",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-tsunami",
    "name": "house-tsunami",
    "iconName": "house-tsunami",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-tsunami",
      "weather",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-chimney-crack",
    "name": "house-chimney-crack",
    "iconName": "house-chimney-crack",
    "prefix": "fas",
    "filter": [
      "house-chimney-crack",
      "solid",
      "buildings",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-phone-flip",
    "name": "phone-flip",
    "iconName": "phone-flip",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "communication",
      "phone-flip"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-pdf",
    "name": "file-pdf",
    "iconName": "file-pdf",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-pdf",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-traffic-light",
    "name": "traffic-light",
    "iconName": "traffic-light",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "traffic-light"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-dots",
    "name": "hand-dots",
    "iconName": "hand-dots",
    "prefix": "fas",
    "filter": [
      "hand-dots",
      "medical",
      "solid",
      "hands",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-teeth",
    "name": "teeth",
    "iconName": "teeth",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "teeth"
    ]
  });
  icons.push({
    "id": "fa-solid fa-image",
    "name": "image",
    "iconName": "image",
    "prefix": "fas",
    "filter": [
      "image",
      "images",
      "solid",
      "maps",
      "social"
    ]
  });
  icons.push({
    "id": "fa-solid fa-location-arrow",
    "name": "location-arrow",
    "iconName": "location-arrow",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "arrows",
      "location-arrow"
    ]
  });
  icons.push({
    "id": "fa-solid fa-outdent",
    "name": "outdent",
    "iconName": "outdent",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "outdent"
    ]
  });
  icons.push({
    "id": "fa-solid fa-holly-berry",
    "name": "holly-berry",
    "iconName": "holly-berry",
    "prefix": "fas",
    "filter": [
      "solid",
      "holly-berry",
      "holidays"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-arrow-down",
    "name": "temperature-arrow-down",
    "iconName": "temperature-arrow-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "temperature-arrow-down",
      "weather",
      "household",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angle-up",
    "name": "angle-up",
    "iconName": "angle-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "angle-up",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microscope",
    "name": "microscope",
    "iconName": "microscope",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "education",
      "science",
      "health",
      "humanitarian",
      "microscope"
    ]
  });
  icons.push({
    "id": "fa-solid fa-voicemail",
    "name": "voicemail",
    "iconName": "voicemail",
    "prefix": "fas",
    "filter": [
      "solid",
      "voicemail",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-signature",
    "name": "file-signature",
    "iconName": "file-signature",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "file-signature"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table-cells-large",
    "name": "table-cells-large",
    "iconName": "table-cells-large",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "table-cells-large"
    ]
  });
  icons.push({
    "id": "fa-solid fa-align-right",
    "name": "align-right",
    "iconName": "align-right",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "align-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-venus-mars",
    "name": "venus-mars",
    "iconName": "venus-mars",
    "prefix": "fas",
    "filter": [
      "solid",
      "genders",
      "venus-mars"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-full",
    "name": "temperature-full",
    "iconName": "temperature-full",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "temperature-full"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mobile",
    "name": "mobile",
    "iconName": "mobile",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "mobile",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-font-awesome",
    "name": "font-awesome",
    "iconName": "font-awesome",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "design",
      "font-awesome"
    ]
  });
  icons.push({
    "id": "fa-solid fa-play",
    "name": "play",
    "iconName": "play",
    "prefix": "fas",
    "filter": [
      "play",
      "media playback",
      "solid",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tty",
    "name": "tty",
    "iconName": "tty",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "accessibility",
      "tty",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-signal",
    "name": "signal",
    "iconName": "signal",
    "prefix": "fas",
    "filter": [
      "solid",
      "connectivity",
      "toggle",
      "signal"
    ]
  });
  icons.push({
    "id": "fa-solid fa-address-book",
    "name": "address-book",
    "iconName": "address-book",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "address-book",
      "communication",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bottle-water",
    "name": "bottle-water",
    "iconName": "bottle-water",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "humanitarian",
      "bottle-water",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-point-left",
    "name": "hand-point-left",
    "iconName": "hand-point-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-point-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-bible",
    "name": "book-bible",
    "iconName": "book-bible",
    "prefix": "fas",
    "filter": [
      "solid",
      "book-bible",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-cane",
    "name": "person-cane",
    "iconName": "person-cane",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "person-cane",
      "accessibility",
      "health",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ticket-simple",
    "name": "ticket-simple",
    "iconName": "ticket-simple",
    "prefix": "fas",
    "filter": [
      "solid",
      "ticket-simple",
      "maps",
      "shapes"
    ]
  });
  icons.push({
    "id": "fa-solid fa-transgender",
    "name": "transgender",
    "iconName": "transgender",
    "prefix": "fas",
    "filter": [
      "solid",
      "genders",
      "transgender"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder-minus",
    "name": "folder-minus",
    "iconName": "folder-minus",
    "prefix": "fas",
    "filter": [
      "folder-minus",
      "solid",
      "business"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-check",
    "name": "plug-circle-check",
    "iconName": "plug-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "plug-circle-check",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-fast",
    "name": "truck-fast",
    "iconName": "truck-fast",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-fast",
      "logistics",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-caravan",
    "name": "caravan",
    "iconName": "caravan",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "caravan",
      "automotive",
      "travel",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ship",
    "name": "ship",
    "iconName": "ship",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "ship",
      "maritime",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-boxes-stacked",
    "name": "boxes-stacked",
    "iconName": "boxes-stacked",
    "prefix": "fas",
    "filter": [
      "boxes-stacked",
      "solid",
      "logistics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-walking-dashed-line-arrow-right",
    "name": "person-walking-dashed-line-arrow-right",
    "iconName": "person-walking-dashed-line-arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-walking-dashed-line-arrow-right",
      "users",
      "disaster",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-tanakh",
    "name": "book-tanakh",
    "iconName": "book-tanakh",
    "prefix": "fas",
    "filter": [
      "book-tanakh",
      "solid",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-glass-water",
    "name": "glass-water",
    "iconName": "glass-water",
    "prefix": "fas",
    "filter": [
      "glass-water",
      "solid",
      "humanitarian",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ankh",
    "name": "ankh",
    "iconName": "ankh",
    "prefix": "fas",
    "filter": [
      "solid",
      "ankh",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-frown-open",
    "name": "face-frown-open",
    "iconName": "face-frown-open",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-frown-open"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-left",
    "name": "arrow-left",
    "iconName": "arrow-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-up-right-and-down-left-from-center",
    "name": "up-right-and-down-left-from-center",
    "iconName": "up-right-and-down-left-from-center",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "arrows",
      "up-right-and-down-left-from-center"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder",
    "name": "folder",
    "iconName": "folder",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "folder",
      "business",
      "shapes",
      "writing",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vault",
    "name": "vault",
    "iconName": "vault",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "business",
      "money",
      "vault"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-double",
    "name": "mars-double",
    "iconName": "mars-double",
    "prefix": "fas",
    "filter": [
      "solid",
      "genders",
      "mars-double"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-medical-circle-xmark",
    "name": "house-medical-circle-xmark",
    "iconName": "house-medical-circle-xmark",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "house-medical-circle-xmark",
      "buildings",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-arrow-up",
    "name": "circle-arrow-up",
    "iconName": "circle-arrow-up",
    "prefix": "fas",
    "filter": [
      "circle-arrow-up",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-scroll",
    "name": "scroll",
    "iconName": "scroll",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "scroll"
    ]
  });
  icons.push({
    "id": "fa-solid fa-synagogue",
    "name": "synagogue",
    "iconName": "synagogue",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "synagogue",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-joint",
    "name": "joint",
    "iconName": "joint",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "joint",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-forward-step",
    "name": "forward-step",
    "iconName": "forward-step",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "forward-step"
    ]
  });
  icons.push({
    "id": "fa-solid fa-truck-moving",
    "name": "truck-moving",
    "iconName": "truck-moving",
    "prefix": "fas",
    "filter": [
      "solid",
      "truck-moving",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-volume-xmark",
    "name": "volume-xmark",
    "iconName": "volume-xmark",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "volume-xmark",
      "audio"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gauge-high",
    "name": "gauge-high",
    "iconName": "gauge-high",
    "prefix": "fas",
    "filter": [
      "solid",
      "gauge-high",
      "automotive"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-pen",
    "name": "file-pen",
    "iconName": "file-pen",
    "prefix": "fas",
    "filter": [
      "solid",
      "files",
      "humanitarian",
      "file-pen"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trailer",
    "name": "trailer",
    "iconName": "trailer",
    "prefix": "fas",
    "filter": [
      "trailer",
      "camping",
      "solid",
      "automotive",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-peace",
    "name": "hand-peace",
    "iconName": "hand-peace",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-peace"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hands-clapping",
    "name": "hands-clapping",
    "iconName": "hands-clapping",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hands-clapping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gun",
    "name": "gun",
    "iconName": "gun",
    "prefix": "fas",
    "filter": [
      "gun",
      "security",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-martini-glass-empty",
    "name": "martini-glass-empty",
    "iconName": "martini-glass-empty",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "travel",
      "martini-glass-empty",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-heart-circle-plus",
    "name": "heart-circle-plus",
    "iconName": "heart-circle-plus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "heart-circle-plus",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-arrival",
    "name": "plane-arrival",
    "iconName": "plane-arrival",
    "prefix": "fas",
    "filter": [
      "solid",
      "plane-arrival",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-martini-glass-citrus",
    "name": "martini-glass-citrus",
    "iconName": "martini-glass-citrus",
    "prefix": "fas",
    "filter": [
      "solid",
      "martini-glass-citrus",
      "travel",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-vials",
    "name": "vials",
    "iconName": "vials",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "vials",
      "science",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-caret-right",
    "name": "caret-right",
    "iconName": "caret-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "caret-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rainbow",
    "name": "rainbow",
    "iconName": "rainbow",
    "prefix": "fas",
    "filter": [
      "rainbow",
      "solid",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-kiwi-bird",
    "name": "kiwi-bird",
    "iconName": "kiwi-bird",
    "prefix": "fas",
    "filter": [
      "solid",
      "kiwi-bird",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ferry",
    "name": "ferry",
    "iconName": "ferry",
    "prefix": "fas",
    "filter": [
      "solid",
      "ferry",
      "logistics",
      "maritime",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-robot",
    "name": "robot",
    "iconName": "robot",
    "prefix": "fas",
    "filter": [
      "robot",
      "solid",
      "childhood",
      "science fiction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-gears",
    "name": "gears",
    "iconName": "gears",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "logistics",
      "gears"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bold",
    "name": "bold",
    "iconName": "bold",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "bold"
    ]
  });
  icons.push({
    "id": "fa-solid fa-train",
    "name": "train",
    "iconName": "train",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "logistics",
      "train",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-border-all",
    "name": "border-all",
    "iconName": "border-all",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "border-all"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cow",
    "name": "cow",
    "iconName": "cow",
    "prefix": "fas",
    "filter": [
      "solid",
      "cow",
      "animals",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-dots-from-line",
    "name": "person-dots-from-line",
    "iconName": "person-dots-from-line",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "person-dots-from-line",
      "health",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-d20",
    "name": "dice-d20",
    "iconName": "dice-d20",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-d20"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paint-roller",
    "name": "paint-roller",
    "iconName": "paint-roller",
    "prefix": "fas",
    "filter": [
      "solid",
      "paint-roller",
      "design",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crop",
    "name": "crop",
    "iconName": "crop",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "crop",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-percent",
    "name": "percent",
    "iconName": "percent",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "money",
      "mathematics",
      "percent",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-book-quran",
    "name": "book-quran",
    "iconName": "book-quran",
    "prefix": "fas",
    "filter": [
      "solid",
      "book-quran",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bars-staggered",
    "name": "bars-staggered",
    "iconName": "bars-staggered",
    "prefix": "fas",
    "filter": [
      "bars-staggered",
      "coding",
      "solid",
      "business"
    ]
  });
  icons.push({
    "id": "fa-solid fa-crow",
    "name": "crow",
    "iconName": "crow",
    "prefix": "fas",
    "filter": [
      "halloween",
      "solid",
      "crow",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-up-right",
    "name": "square-up-right",
    "iconName": "square-up-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "square-up-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-minus",
    "name": "minus",
    "iconName": "minus",
    "prefix": "fas",
    "filter": [
      "minus",
      "solid",
      "mathematics",
      "symbols",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bolt",
    "name": "bolt",
    "iconName": "bolt",
    "prefix": "fas",
    "filter": [
      "images",
      "solid",
      "weather",
      "bolt",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shop-slash",
    "name": "shop-slash",
    "iconName": "shop-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "shop-slash",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mask-ventilator",
    "name": "mask-ventilator",
    "iconName": "mask-ventilator",
    "prefix": "fas",
    "filter": [
      "mask-ventilator",
      "medical",
      "solid",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-exclamation",
    "name": "exclamation",
    "iconName": "exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "alert",
      "exclamation",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-star-half-stroke",
    "name": "star-half-stroke",
    "iconName": "star-half-stroke",
    "prefix": "fas",
    "filter": [
      "solid",
      "star-half-stroke",
      "toggle"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bomb",
    "name": "bomb",
    "iconName": "bomb",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "bomb"
    ]
  });
  icons.push({
    "id": "fa-solid fa-folder-tree",
    "name": "folder-tree",
    "iconName": "folder-tree",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "folder-tree"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sim-card",
    "name": "sim-card",
    "iconName": "sim-card",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "sim-card"
    ]
  });
  icons.push({
    "id": "fa-solid fa-route",
    "name": "route",
    "iconName": "route",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "route",
      "maps",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-barrier",
    "name": "road-barrier",
    "iconName": "road-barrier",
    "prefix": "fas",
    "filter": [
      "solid",
      "road-barrier",
      "logistics",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wand-magic",
    "name": "wand-magic",
    "iconName": "wand-magic",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "wand-magic",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-syringe",
    "name": "syringe",
    "iconName": "syringe",
    "prefix": "fas",
    "filter": [
      "syringe",
      "medical",
      "solid",
      "science",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-burst",
    "name": "burst",
    "iconName": "burst",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "shapes",
      "burst",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tent-arrow-down-to-line",
    "name": "tent-arrow-down-to-line",
    "iconName": "tent-arrow-down-to-line",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "buildings",
      "tent-arrow-down-to-line",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding-dollar",
    "name": "hand-holding-dollar",
    "iconName": "hand-holding-dollar",
    "prefix": "fas",
    "filter": [
      "hand-holding-dollar",
      "solid",
      "hands",
      "money",
      "charity"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane",
    "name": "plane",
    "iconName": "plane",
    "prefix": "fas",
    "filter": [
      "plane",
      "solid",
      "maps",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-horse-head",
    "name": "horse-head",
    "iconName": "horse-head",
    "prefix": "fas",
    "filter": [
      "solid",
      "horse-head",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plane-slash",
    "name": "plane-slash",
    "iconName": "plane-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "plane-slash",
      "travel",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tower-observation",
    "name": "tower-observation",
    "iconName": "tower-observation",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "buildings",
      "tower-observation",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-down-z-a",
    "name": "arrow-down-z-a",
    "iconName": "arrow-down-z-a",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrow-down-z-a",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-oil-well",
    "name": "oil-well",
    "iconName": "oil-well",
    "prefix": "fas",
    "filter": [
      "solid",
      "oil-well",
      "buildings",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-left-right",
    "name": "left-right",
    "iconName": "left-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "left-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-falling-burst",
    "name": "person-falling-burst",
    "iconName": "person-falling-burst",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-falling-burst",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clapperboard",
    "name": "clapperboard",
    "iconName": "clapperboard",
    "prefix": "fas",
    "filter": [
      "solid",
      "video",
      "clapperboard"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar",
    "name": "calendar",
    "iconName": "calendar",
    "prefix": "fas",
    "filter": [
      "calendar",
      "solid",
      "business",
      "shapes",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-trash-can",
    "name": "trash-can",
    "iconName": "trash-can",
    "prefix": "fas",
    "filter": [
      "solid",
      "trash-can",
      "editing",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding-hand",
    "name": "hand-holding-hand",
    "iconName": "hand-holding-hand",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "charity",
      "hand-holding-hand",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-laptop-medical",
    "name": "laptop-medical",
    "iconName": "laptop-medical",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "health",
      "laptop-medical"
    ]
  });
  icons.push({
    "id": "fa-solid fa-a",
    "name": "a",
    "iconName": "a",
    "prefix": "fas",
    "filter": [
      "a",
      "solid",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clipboard-list",
    "name": "clipboard-list",
    "iconName": "clipboard-list",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "clipboard-list"
    ]
  });
  icons.push({
    "id": "fa-solid fa-b",
    "name": "b",
    "iconName": "b",
    "prefix": "fas",
    "filter": [
      "b",
      "solid",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-franc-sign",
    "name": "franc-sign",
    "iconName": "franc-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "franc-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-globe",
    "name": "globe",
    "iconName": "globe",
    "prefix": "fas",
    "filter": [
      "solid",
      "globe",
      "business",
      "connectivity",
      "maps",
      "charity",
      "astronomy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-toilet-portable",
    "name": "toilet-portable",
    "iconName": "toilet-portable",
    "prefix": "fas",
    "filter": [
      "solid",
      "toilet-portable",
      "buildings",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rectangle-xmark",
    "name": "rectangle-xmark",
    "iconName": "rectangle-xmark",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "rectangle-xmark"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bars-progress",
    "name": "bars-progress",
    "iconName": "bars-progress",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "bars-progress",
      "business",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-section",
    "name": "section",
    "iconName": "section",
    "prefix": "fas",
    "filter": [
      "solid",
      "section",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-arrow-down",
    "name": "file-arrow-down",
    "iconName": "file-arrow-down",
    "prefix": "fas",
    "filter": [
      "file-arrow-down",
      "solid",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-i",
    "name": "i",
    "iconName": "i",
    "prefix": "fas",
    "filter": [
      "solid",
      "i",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-filter",
    "name": "filter",
    "iconName": "filter",
    "prefix": "fas",
    "filter": [
      "filter",
      "coding",
      "solid",
      "science"
    ]
  });
  icons.push({
    "id": "fa-solid fa-j",
    "name": "j",
    "iconName": "j",
    "prefix": "fas",
    "filter": [
      "solid",
      "j",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-user",
    "name": "user",
    "iconName": "user",
    "prefix": "fas",
    "filter": [
      "solid",
      "social",
      "user",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-g",
    "name": "g",
    "iconName": "g",
    "prefix": "fas",
    "filter": [
      "solid",
      "g",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-h",
    "name": "h",
    "iconName": "h",
    "prefix": "fas",
    "filter": [
      "solid",
      "h",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-e",
    "name": "e",
    "iconName": "e",
    "prefix": "fas",
    "filter": [
      "solid",
      "e",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-thumbtack",
    "name": "thumbtack",
    "iconName": "thumbtack",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "social",
      "writing",
      "thumbtack"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cedi-sign",
    "name": "cedi-sign",
    "iconName": "cedi-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "cedi-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-f",
    "name": "f",
    "iconName": "f",
    "prefix": "fas",
    "filter": [
      "solid",
      "f",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-c",
    "name": "c",
    "iconName": "c",
    "prefix": "fas",
    "filter": [
      "solid",
      "c",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-d",
    "name": "d",
    "iconName": "d",
    "prefix": "fas",
    "filter": [
      "solid",
      "d",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-diagram-next",
    "name": "diagram-next",
    "iconName": "diagram-next",
    "prefix": "fas",
    "filter": [
      "charts",
      "solid",
      "diagram-next",
      "diagrams"
    ]
  });
  icons.push({
    "id": "fa-solid fa-1",
    "name": "1",
    "iconName": "1",
    "prefix": "fas",
    "filter": [
      "1",
      "solid",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-backward-step",
    "name": "backward-step",
    "iconName": "backward-step",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "backward-step"
    ]
  });
  icons.push({
    "id": "fa-solid fa-austral-sign",
    "name": "austral-sign",
    "iconName": "austral-sign",
    "prefix": "fas",
    "filter": [
      "austral-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-2",
    "name": "2",
    "iconName": "2",
    "prefix": "fas",
    "filter": [
      "2",
      "solid",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-paintbrush",
    "name": "paintbrush",
    "iconName": "paintbrush",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "paintbrush",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-0",
    "name": "0",
    "iconName": "0",
    "prefix": "fas",
    "filter": [
      "0",
      "solid",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-rain",
    "name": "cloud-rain",
    "iconName": "cloud-rain",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "cloud-rain"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tree-city",
    "name": "tree-city",
    "iconName": "tree-city",
    "prefix": "fas",
    "filter": [
      "solid",
      "tree-city",
      "buildings",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mars-stroke-up",
    "name": "mars-stroke-up",
    "iconName": "mars-stroke-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "mars-stroke-up",
      "genders"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-circle-exclamation",
    "name": "building-circle-exclamation",
    "iconName": "building-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "building-circle-exclamation",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-9",
    "name": "9",
    "iconName": "9",
    "prefix": "fas",
    "filter": [
      "solid",
      "numbers",
      "9"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-bridge",
    "name": "road-bridge",
    "iconName": "road-bridge",
    "prefix": "fas",
    "filter": [
      "solid",
      "road-bridge",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-7",
    "name": "7",
    "iconName": "7",
    "prefix": "fas",
    "filter": [
      "solid",
      "7",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-8",
    "name": "8",
    "iconName": "8",
    "prefix": "fas",
    "filter": [
      "solid",
      "8",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-5",
    "name": "5",
    "iconName": "5",
    "prefix": "fas",
    "filter": [
      "solid",
      "5",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-6",
    "name": "6",
    "iconName": "6",
    "prefix": "fas",
    "filter": [
      "solid",
      "6",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-3",
    "name": "3",
    "iconName": "3",
    "prefix": "fas",
    "filter": [
      "solid",
      "3",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-landmark-flag",
    "name": "landmark-flag",
    "iconName": "landmark-flag",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "landmark-flag",
      "buildings",
      "political",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-4",
    "name": "4",
    "iconName": "4",
    "prefix": "fas",
    "filter": [
      "solid",
      "4",
      "numbers"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cruzeiro-sign",
    "name": "cruzeiro-sign",
    "iconName": "cruzeiro-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "cruzeiro-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chess-bishop",
    "name": "chess-bishop",
    "iconName": "chess-bishop",
    "prefix": "fas",
    "filter": [
      "chess-bishop",
      "gaming",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-angry",
    "name": "face-angry",
    "iconName": "face-angry",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-angry"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shield-halved",
    "name": "shield-halved",
    "iconName": "shield-halved",
    "prefix": "fas",
    "filter": [
      "coding",
      "gaming",
      "security",
      "solid",
      "shield-halved"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bangladeshi-taka-sign",
    "name": "bangladeshi-taka-sign",
    "iconName": "bangladeshi-taka-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "bangladeshi-taka-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-temperature-empty",
    "name": "temperature-empty",
    "iconName": "temperature-empty",
    "prefix": "fas",
    "filter": [
      "temperature-empty",
      "solid",
      "weather"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-circle-exclamation",
    "name": "house-circle-exclamation",
    "iconName": "house-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "house-circle-exclamation",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-xmark",
    "name": "person-circle-xmark",
    "iconName": "person-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-circle-xmark",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-shrimp",
    "name": "shrimp",
    "iconName": "shrimp",
    "prefix": "fas",
    "filter": [
      "solid",
      "shrimp",
      "maritime",
      "animals",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fax",
    "name": "fax",
    "iconName": "fax",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "devices",
      "humanitarian",
      "fax",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-exclamation",
    "name": "person-circle-exclamation",
    "iconName": "person-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-circle-exclamation",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-store",
    "name": "store",
    "iconName": "store",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "store",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tent",
    "name": "tent",
    "iconName": "tent",
    "prefix": "fas",
    "filter": [
      "tent",
      "camping",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-squint",
    "name": "face-grin-squint",
    "iconName": "face-grin-squint",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-squint"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wheat-awn",
    "name": "wheat-awn",
    "iconName": "wheat-awn",
    "prefix": "fas",
    "filter": [
      "solid",
      "food",
      "wheat-awn",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-point-right",
    "name": "hand-point-right",
    "iconName": "hand-point-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-point-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnifying-glass-plus",
    "name": "magnifying-glass-plus",
    "iconName": "magnifying-glass-plus",
    "prefix": "fas",
    "filter": [
      "magnifying-glass-plus",
      "solid",
      "maps"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-up-1-9",
    "name": "arrow-up-1-9",
    "iconName": "arrow-up-1-9",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrow-up-1-9"
    ]
  });
  icons.push({
    "id": "fa-solid fa-fan",
    "name": "fan",
    "iconName": "fan",
    "prefix": "fas",
    "filter": [
      "fan",
      "solid",
      "spinners",
      "household",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-anchor-circle-exclamation",
    "name": "anchor-circle-exclamation",
    "iconName": "anchor-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "anchor-circle-exclamation",
      "logistics",
      "maritime",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cart-flatbed",
    "name": "cart-flatbed",
    "iconName": "cart-flatbed",
    "prefix": "fas",
    "filter": [
      "cart-flatbed",
      "solid",
      "logistics"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mosquito",
    "name": "mosquito",
    "iconName": "mosquito",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "nature",
      "animals",
      "humanitarian",
      "mosquito",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-battery-three-quarters",
    "name": "battery-three-quarters",
    "iconName": "battery-three-quarters",
    "prefix": "fas",
    "filter": [
      "solid",
      "battery-three-quarters",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microphone",
    "name": "microphone",
    "iconName": "microphone",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle",
      "audio",
      "video",
      "microphone",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-tears",
    "name": "face-grin-tears",
    "iconName": "face-grin-tears",
    "prefix": "fas",
    "filter": [
      "face-grin-tears",
      "solid",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-grin-tongue-squint",
    "name": "face-grin-tongue-squint",
    "iconName": "face-grin-tongue-squint",
    "prefix": "fas",
    "filter": [
      "solid",
      "emoji",
      "face-grin-tongue-squint"
    ]
  });
  icons.push({
    "id": "fa-solid fa-jet-fighter-up",
    "name": "jet-fighter-up",
    "iconName": "jet-fighter-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "logistics",
      "jet-fighter-up",
      "humanitarian",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table-list",
    "name": "table-list",
    "iconName": "table-list",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "table-list",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-military-pointing",
    "name": "person-military-pointing",
    "iconName": "person-military-pointing",
    "prefix": "fas",
    "filter": [
      "security",
      "solid",
      "person-military-pointing",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-exclamation",
    "name": "circle-exclamation",
    "iconName": "circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "alert",
      "circle-exclamation",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tags",
    "name": "tags",
    "iconName": "tags",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "maps",
      "tags",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-clock-rotate-left",
    "name": "clock-rotate-left",
    "iconName": "clock-rotate-left",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "clock-rotate-left",
      "arrows",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ticket",
    "name": "ticket",
    "iconName": "ticket",
    "prefix": "fas",
    "filter": [
      "solid",
      "ticket",
      "maps",
      "video"
    ]
  });
  icons.push({
    "id": "fa-solid fa-calendar-week",
    "name": "calendar-week",
    "iconName": "calendar-week",
    "prefix": "fas",
    "filter": [
      "solid",
      "calendar-week",
      "time"
    ]
  });
  icons.push({
    "id": "fa-solid fa-sort-up",
    "name": "sort-up",
    "iconName": "sort-up",
    "prefix": "fas",
    "filter": [
      "solid",
      "sort-up",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-holding",
    "name": "hand-holding",
    "iconName": "hand-holding",
    "prefix": "fas",
    "filter": [
      "hand-holding",
      "solid",
      "hands"
    ]
  });
  icons.push({
    "id": "fa-solid fa-box-tissue",
    "name": "box-tissue",
    "iconName": "box-tissue",
    "prefix": "fas",
    "filter": [
      "solid",
      "box-tissue",
      "household"
    ]
  });
  icons.push({
    "id": "fa-solid fa-magnet",
    "name": "magnet",
    "iconName": "magnet",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "science",
      "magnet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-earth-africa",
    "name": "earth-africa",
    "iconName": "earth-africa",
    "prefix": "fas",
    "filter": [
      "solid",
      "travel",
      "earth-africa"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dong-sign",
    "name": "dong-sign",
    "iconName": "dong-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "dong-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-up-to-line",
    "name": "arrows-up-to-line",
    "iconName": "arrows-up-to-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "humanitarian",
      "arrows-up-to-line"
    ]
  });
  icons.push({
    "id": "fa-solid fa-display",
    "name": "display",
    "iconName": "display",
    "prefix": "fas",
    "filter": [
      "solid",
      "devices",
      "display",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-microphone-slash",
    "name": "microphone-slash",
    "iconName": "microphone-slash",
    "prefix": "fas",
    "filter": [
      "solid",
      "toggle",
      "audio",
      "video",
      "microphone-slash",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-split-up-and-left",
    "name": "arrows-split-up-and-left",
    "iconName": "arrows-split-up-and-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "humanitarian",
      "arrows-split-up-and-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-virus",
    "name": "square-virus",
    "iconName": "square-virus",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "science",
      "square-virus",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chart-simple",
    "name": "chart-simple",
    "iconName": "chart-simple",
    "prefix": "fas",
    "filter": [
      "chart-simple",
      "marketing",
      "charts",
      "solid",
      "business",
      "logistics",
      "diagrams",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hourglass-half",
    "name": "hourglass-half",
    "iconName": "hourglass-half",
    "prefix": "fas",
    "filter": [
      "solid",
      "time",
      "hourglass-half"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-import",
    "name": "file-import",
    "iconName": "file-import",
    "prefix": "fas",
    "filter": [
      "solid",
      "file-import",
      "files"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ruler-vertical",
    "name": "ruler-vertical",
    "iconName": "ruler-vertical",
    "prefix": "fas",
    "filter": [
      "solid",
      "ruler-vertical",
      "design",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-x-ray",
    "name": "x-ray",
    "iconName": "x-ray",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "x-ray",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-table-columns",
    "name": "table-columns",
    "iconName": "table-columns",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "business",
      "table-columns"
    ]
  });
  icons.push({
    "id": "fa-solid fa-boxes-packing",
    "name": "boxes-packing",
    "iconName": "boxes-packing",
    "prefix": "fas",
    "filter": [
      "boxes-packing",
      "solid",
      "business",
      "logistics",
      "humanitarian",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-face-laugh-wink",
    "name": "face-laugh-wink",
    "iconName": "face-laugh-wink",
    "prefix": "fas",
    "filter": [
      "face-laugh-wink",
      "solid",
      "emoji"
    ]
  });
  icons.push({
    "id": "fa-solid fa-chevron-down",
    "name": "chevron-down",
    "iconName": "chevron-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "chevron-down"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-exclamation",
    "name": "file-circle-exclamation",
    "iconName": "file-circle-exclamation",
    "prefix": "fas",
    "filter": [
      "solid",
      "files",
      "file-circle-exclamation",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-disease",
    "name": "disease",
    "iconName": "disease",
    "prefix": "fas",
    "filter": [
      "medical",
      "solid",
      "disease",
      "science",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-quote-left",
    "name": "quote-left",
    "iconName": "quote-left",
    "prefix": "fas",
    "filter": [
      "solid",
      "quote-left",
      "writing",
      "communication",
      "symbols"
    ]
  });
  icons.push({
    "id": "fa-solid fa-wand-magic-sparkles",
    "name": "wand-magic-sparkles",
    "iconName": "wand-magic-sparkles",
    "prefix": "fas",
    "filter": [
      "solid",
      "wand-magic-sparkles",
      "design",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-users-line",
    "name": "users-line",
    "iconName": "users-line",
    "prefix": "fas",
    "filter": [
      "solid",
      "users-line",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cash-register",
    "name": "cash-register",
    "iconName": "cash-register",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "cash-register",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-child-dress",
    "name": "child-dress",
    "iconName": "child-dress",
    "prefix": "fas",
    "filter": [
      "child-dress",
      "solid",
      "childhood",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-grip",
    "name": "grip",
    "iconName": "grip",
    "prefix": "fas",
    "filter": [
      "grip",
      "solid",
      "editing"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bacterium",
    "name": "bacterium",
    "iconName": "bacterium",
    "prefix": "fas",
    "filter": [
      "bacterium",
      "medical",
      "solid",
      "health",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice-one",
    "name": "dice-one",
    "iconName": "dice-one",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice-one"
    ]
  });
  icons.push({
    "id": "fa-solid fa-school-lock",
    "name": "school-lock",
    "iconName": "school-lock",
    "prefix": "fas",
    "filter": [
      "solid",
      "education",
      "buildings",
      "school-lock",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bucket",
    "name": "bucket",
    "iconName": "bucket",
    "prefix": "fas",
    "filter": [
      "bucket",
      "camping",
      "solid",
      "childhood",
      "construction",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-money-bill-1-wave",
    "name": "money-bill-1-wave",
    "iconName": "money-bill-1-wave",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "money-bill-1-wave"
    ]
  });
  icons.push({
    "id": "fa-solid fa-object-group",
    "name": "object-group",
    "iconName": "object-group",
    "prefix": "fas",
    "filter": [
      "solid",
      "design",
      "object-group"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rss",
    "name": "rss",
    "iconName": "rss",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "rss",
      "connectivity"
    ]
  });
  icons.push({
    "id": "fa-solid fa-camera-rotate",
    "name": "camera-rotate",
    "iconName": "camera-rotate",
    "prefix": "fas",
    "filter": [
      "camera-rotate",
      "images",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-poo",
    "name": "poo",
    "iconName": "poo",
    "prefix": "fas",
    "filter": [
      "poo",
      "solid",
      "communication",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-plug-circle-xmark",
    "name": "plug-circle-xmark",
    "iconName": "plug-circle-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "plug-circle-xmark",
      "energy",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ear-deaf",
    "name": "ear-deaf",
    "iconName": "ear-deaf",
    "prefix": "fas",
    "filter": [
      "solid",
      "accessibility",
      "ear-deaf"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-left-long",
    "name": "arrow-left-long",
    "iconName": "arrow-left-long",
    "prefix": "fas",
    "filter": [
      "arrow-left-long",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-sun-rain",
    "name": "cloud-sun-rain",
    "iconName": "cloud-sun-rain",
    "prefix": "fas",
    "filter": [
      "solid",
      "weather",
      "cloud-sun-rain"
    ]
  });
  icons.push({
    "id": "fa-solid fa-ruler",
    "name": "ruler",
    "iconName": "ruler",
    "prefix": "fas",
    "filter": [
      "solid",
      "construction",
      "ruler"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrows-up-down-left-right",
    "name": "arrows-up-down-left-right",
    "iconName": "arrows-up-down-left-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "arrows-up-down-left-right"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-sparkles",
    "name": "hand-sparkles",
    "iconName": "hand-sparkles",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-sparkles"
    ]
  });
  icons.push({
    "id": "fa-solid fa-igloo",
    "name": "igloo",
    "iconName": "igloo",
    "prefix": "fas",
    "filter": [
      "solid",
      "igloo",
      "buildings"
    ]
  });
  icons.push({
    "id": "fa-solid fa-democrat",
    "name": "democrat",
    "iconName": "democrat",
    "prefix": "fas",
    "filter": [
      "solid",
      "democrat",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-layer-group",
    "name": "layer-group",
    "iconName": "layer-group",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "design",
      "layer-group"
    ]
  });
  icons.push({
    "id": "fa-solid fa-box-archive",
    "name": "box-archive",
    "iconName": "box-archive",
    "prefix": "fas",
    "filter": [
      "coding",
      "solid",
      "business",
      "box-archive",
      "writing",
      "files",
      "moving"
    ]
  });
  icons.push({
    "id": "fa-solid fa-battery-half",
    "name": "battery-half",
    "iconName": "battery-half",
    "prefix": "fas",
    "filter": [
      "solid",
      "battery-half",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-left",
    "name": "circle-left",
    "iconName": "circle-left",
    "prefix": "fas",
    "filter": [
      "circle-left",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-award",
    "name": "award",
    "iconName": "award",
    "prefix": "fas",
    "filter": [
      "solid",
      "award",
      "education",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dragon",
    "name": "dragon",
    "iconName": "dragon",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dragon",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-moon",
    "name": "cloud-moon",
    "iconName": "cloud-moon",
    "prefix": "fas",
    "filter": [
      "halloween",
      "solid",
      "weather",
      "cloud-moon"
    ]
  });
  icons.push({
    "id": "fa-solid fa-text-height",
    "name": "text-height",
    "iconName": "text-height",
    "prefix": "fas",
    "filter": [
      "text-height",
      "text formatting",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-child-combatant",
    "name": "child-combatant",
    "iconName": "child-combatant",
    "prefix": "fas",
    "filter": [
      "solid",
      "child-combatant",
      "humanitarian",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mug-hot",
    "name": "mug-hot",
    "iconName": "mug-hot",
    "prefix": "fas",
    "filter": [
      "mug-hot",
      "solid",
      "holidays",
      "food"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rocket",
    "name": "rocket",
    "iconName": "rocket",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "science fiction",
      "rocket",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-eye",
    "name": "eye",
    "iconName": "eye",
    "prefix": "fas",
    "filter": [
      "eye",
      "images",
      "medical",
      "security",
      "solid",
      "maps",
      "accessibility",
      "design",
      "health"
    ]
  });
  icons.push({
    "id": "fa-solid fa-right-long",
    "name": "right-long",
    "iconName": "right-long",
    "prefix": "fas",
    "filter": [
      "solid",
      "arrows",
      "right-long"
    ]
  });
  icons.push({
    "id": "fa-solid fa-down-left-and-up-right-to-center",
    "name": "down-left-and-up-right-to-center",
    "iconName": "down-left-and-up-right-to-center",
    "prefix": "fas",
    "filter": [
      "down-left-and-up-right-to-center",
      "media playback",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bullseye",
    "name": "bullseye",
    "iconName": "bullseye",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "toggle",
      "bullseye"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dumpster",
    "name": "dumpster",
    "iconName": "dumpster",
    "prefix": "fas",
    "filter": [
      "solid",
      "construction",
      "dumpster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-stop",
    "name": "circle-stop",
    "iconName": "circle-stop",
    "prefix": "fas",
    "filter": [
      "media playback",
      "solid",
      "circle-stop"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bahai",
    "name": "bahai",
    "iconName": "bahai",
    "prefix": "fas",
    "filter": [
      "solid",
      "bahai",
      "spinners",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-water-ladder",
    "name": "water-ladder",
    "iconName": "water-ladder",
    "prefix": "fas",
    "filter": [
      "solid",
      "water-ladder",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-file-circle-plus",
    "name": "file-circle-plus",
    "iconName": "file-circle-plus",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "file-circle-plus",
      "files",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-left-long",
    "name": "left-long",
    "iconName": "left-long",
    "prefix": "fas",
    "filter": [
      "left-long",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-binoculars",
    "name": "binoculars",
    "iconName": "binoculars",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "nature",
      "binoculars",
      "astronomy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-highlighter",
    "name": "highlighter",
    "iconName": "highlighter",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "highlighter",
      "business",
      "design"
    ]
  });
  icons.push({
    "id": "fa-solid fa-timeline",
    "name": "timeline",
    "iconName": "timeline",
    "prefix": "fas",
    "filter": [
      "marketing",
      "solid",
      "business",
      "timeline"
    ]
  });
  icons.push({
    "id": "fa-solid fa-cloud-arrow-down",
    "name": "cloud-arrow-down",
    "iconName": "cloud-arrow-down",
    "prefix": "fas",
    "filter": [
      "cloud-arrow-down",
      "solid",
      "connectivity",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-lira-sign",
    "name": "lira-sign",
    "iconName": "lira-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "lira-sign"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hotel",
    "name": "hotel",
    "iconName": "hotel",
    "prefix": "fas",
    "filter": [
      "solid",
      "buildings",
      "hotel",
      "travel",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-mobile-retro",
    "name": "mobile-retro",
    "iconName": "mobile-retro",
    "prefix": "fas",
    "filter": [
      "solid",
      "mobile-retro",
      "devices",
      "humanitarian",
      "communication"
    ]
  });
  icons.push({
    "id": "fa-solid fa-won-sign",
    "name": "won-sign",
    "iconName": "won-sign",
    "prefix": "fas",
    "filter": [
      "won-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-soap",
    "name": "soap",
    "iconName": "soap",
    "prefix": "fas",
    "filter": [
      "solid",
      "household",
      "soap",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-piggy-bank",
    "name": "piggy-bank",
    "iconName": "piggy-bank",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "charity",
      "piggy-bank",
      "political"
    ]
  });
  icons.push({
    "id": "fa-solid fa-bridge-water",
    "name": "bridge-water",
    "iconName": "bridge-water",
    "prefix": "fas",
    "filter": [
      "solid",
      "maps",
      "bridge-water",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-credit-card",
    "name": "credit-card",
    "iconName": "credit-card",
    "prefix": "fas",
    "filter": [
      "solid",
      "money",
      "credit-card",
      "shopping"
    ]
  });
  icons.push({
    "id": "fa-solid fa-yen-sign",
    "name": "yen-sign",
    "iconName": "yen-sign",
    "prefix": "fas",
    "filter": [
      "yen-sign",
      "solid",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-q",
    "name": "q",
    "iconName": "q",
    "prefix": "fas",
    "filter": [
      "q",
      "solid",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-r",
    "name": "r",
    "iconName": "r",
    "prefix": "fas",
    "filter": [
      "r",
      "solid",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-o",
    "name": "o",
    "iconName": "o",
    "prefix": "fas",
    "filter": [
      "solid",
      "alphabet",
      "o"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hand-middle-finger",
    "name": "hand-middle-finger",
    "iconName": "hand-middle-finger",
    "prefix": "fas",
    "filter": [
      "solid",
      "hands",
      "hand-middle-finger"
    ]
  });
  icons.push({
    "id": "fa-solid fa-p",
    "name": "p",
    "iconName": "p",
    "prefix": "fas",
    "filter": [
      "p",
      "solid",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-rug",
    "name": "rug",
    "iconName": "rug",
    "prefix": "fas",
    "filter": [
      "solid",
      "rug",
      "household",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-peseta-sign",
    "name": "peseta-sign",
    "iconName": "peseta-sign",
    "prefix": "fas",
    "filter": [
      "solid",
      "peseta-sign",
      "money"
    ]
  });
  icons.push({
    "id": "fa-solid fa-m",
    "name": "m",
    "iconName": "m",
    "prefix": "fas",
    "filter": [
      "solid",
      "alphabet",
      "m"
    ]
  });
  icons.push({
    "id": "fa-solid fa-n",
    "name": "n",
    "iconName": "n",
    "prefix": "fas",
    "filter": [
      "solid",
      "alphabet",
      "n"
    ]
  });
  icons.push({
    "id": "fa-solid fa-k",
    "name": "k",
    "iconName": "k",
    "prefix": "fas",
    "filter": [
      "solid",
      "k",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-l",
    "name": "l",
    "iconName": "l",
    "prefix": "fas",
    "filter": [
      "solid",
      "alphabet",
      "l"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tree",
    "name": "tree",
    "iconName": "tree",
    "prefix": "fas",
    "filter": [
      "camping",
      "solid",
      "maps",
      "nature",
      "tree"
    ]
  });
  icons.push({
    "id": "fa-solid fa-y",
    "name": "y",
    "iconName": "y",
    "prefix": "fas",
    "filter": [
      "solid",
      "y",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-road-circle-check",
    "name": "road-circle-check",
    "iconName": "road-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "road-circle-check",
      "logistics",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-z",
    "name": "z",
    "iconName": "z",
    "prefix": "fas",
    "filter": [
      "solid",
      "z",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-w",
    "name": "w",
    "iconName": "w",
    "prefix": "fas",
    "filter": [
      "solid",
      "w",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-house-flood-water-circle-arrow-right",
    "name": "house-flood-water-circle-arrow-right",
    "iconName": "house-flood-water-circle-arrow-right",
    "prefix": "fas",
    "filter": [
      "solid",
      "humanitarian",
      "house-flood-water-circle-arrow-right",
      "disaster"
    ]
  });
  icons.push({
    "id": "fa-solid fa-circle-minus",
    "name": "circle-minus",
    "iconName": "circle-minus",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "circle-minus"
    ]
  });
  icons.push({
    "id": "fa-solid fa-x",
    "name": "x",
    "iconName": "x",
    "prefix": "fas",
    "filter": [
      "solid",
      "x",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-xmark",
    "name": "square-xmark",
    "iconName": "square-xmark",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "square-xmark"
    ]
  });
  icons.push({
    "id": "fa-solid fa-u",
    "name": "u",
    "iconName": "u",
    "prefix": "fas",
    "filter": [
      "solid",
      "u",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-image-portrait",
    "name": "image-portrait",
    "iconName": "image-portrait",
    "prefix": "fas",
    "filter": [
      "image-portrait",
      "images",
      "solid",
      "users"
    ]
  });
  icons.push({
    "id": "fa-solid fa-v",
    "name": "v",
    "iconName": "v",
    "prefix": "fas",
    "filter": [
      "solid",
      "v",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-list-ul",
    "name": "list-ul",
    "iconName": "list-ul",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "list-ul",
      "solid"
    ]
  });
  icons.push({
    "id": "fa-solid fa-s",
    "name": "s",
    "iconName": "s",
    "prefix": "fas",
    "filter": [
      "solid",
      "s",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-t",
    "name": "t",
    "iconName": "t",
    "prefix": "fas",
    "filter": [
      "solid",
      "t",
      "alphabet"
    ]
  });
  icons.push({
    "id": "fa-solid fa-arrow-turn-down",
    "name": "arrow-turn-down",
    "iconName": "arrow-turn-down",
    "prefix": "fas",
    "filter": [
      "arrow-turn-down",
      "solid",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-angle-down",
    "name": "angle-down",
    "iconName": "angle-down",
    "prefix": "fas",
    "filter": [
      "solid",
      "angle-down",
      "arrows"
    ]
  });
  icons.push({
    "id": "fa-solid fa-building-ngo",
    "name": "building-ngo",
    "iconName": "building-ngo",
    "prefix": "fas",
    "filter": [
      "building-ngo",
      "solid",
      "buildings",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-screwdriver-wrench",
    "name": "screwdriver-wrench",
    "iconName": "screwdriver-wrench",
    "prefix": "fas",
    "filter": [
      "solid",
      "screwdriver-wrench",
      "construction"
    ]
  });
  icons.push({
    "id": "fa-solid fa-person-circle-check",
    "name": "person-circle-check",
    "iconName": "person-circle-check",
    "prefix": "fas",
    "filter": [
      "solid",
      "person-circle-check",
      "users",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-square-root-variable",
    "name": "square-root-variable",
    "iconName": "square-root-variable",
    "prefix": "fas",
    "filter": [
      "solid",
      "mathematics",
      "square-root-variable"
    ]
  });
  icons.push({
    "id": "fa-solid fa-underline",
    "name": "underline",
    "iconName": "underline",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "underline"
    ]
  });
  icons.push({
    "id": "fa-solid fa-tower-cell",
    "name": "tower-cell",
    "iconName": "tower-cell",
    "prefix": "fas",
    "filter": [
      "solid",
      "tower-cell",
      "connectivity",
      "video",
      "communication",
      "humanitarian"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dice",
    "name": "dice",
    "iconName": "dice",
    "prefix": "fas",
    "filter": [
      "gaming",
      "solid",
      "dice",
      "travel"
    ]
  });
  icons.push({
    "id": "fa-solid fa-dharmachakra",
    "name": "dharmachakra",
    "iconName": "dharmachakra",
    "prefix": "fas",
    "filter": [
      "solid",
      "spinners",
      "dharmachakra",
      "religion"
    ]
  });
  icons.push({
    "id": "fa-solid fa-note-sticky",
    "name": "note-sticky",
    "iconName": "note-sticky",
    "prefix": "fas",
    "filter": [
      "solid",
      "business",
      "writing",
      "files",
      "note-sticky"
    ]
  });
  icons.push({
    "id": "fa-solid fa-car-burst",
    "name": "car-burst",
    "iconName": "car-burst",
    "prefix": "fas",
    "filter": [
      "car-burst",
      "solid",
      "automotive",
      "transportation"
    ]
  });
  icons.push({
    "id": "fa-solid fa-solar-panel",
    "name": "solar-panel",
    "iconName": "solar-panel",
    "prefix": "fas",
    "filter": [
      "solid",
      "solar-panel",
      "energy"
    ]
  });
  icons.push({
    "id": "fa-solid fa-border-top-left",
    "name": "border-top-left",
    "iconName": "border-top-left",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "border-top-left"
    ]
  });
  icons.push({
    "id": "fa-solid fa-hippo",
    "name": "hippo",
    "iconName": "hippo",
    "prefix": "fas",
    "filter": [
      "solid",
      "hippo",
      "animals"
    ]
  });
  icons.push({
    "id": "fa-solid fa-desktop",
    "name": "desktop",
    "iconName": "desktop",
    "prefix": "fas",
    "filter": [
      "solid",
      "desktop",
      "devices"
    ]
  });
  icons.push({
    "id": "fa-solid fa-text-slash",
    "name": "text-slash",
    "iconName": "text-slash",
    "prefix": "fas",
    "filter": [
      "text formatting",
      "solid",
      "text-slash"
    ]
  });
  return icons;
}

const groupPaginator = (array, n) => {
  const pageSize = Math.ceil(array.length / n);
  return Array.from({ length: pageSize }, (_, index) => {
    const start = index * n;
    return array.slice(start, start + n);
  });
};

@Component({
  selector: 'ng-picker',
  templateUrl: './ng-picker.component.html',
  styleUrls: ['./ng-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgPickerComponent implements OnInit {
  @ViewChild('p1') p1?: any;

  @Input() icon: any;
  @Output() iconChange = new EventEmitter<any>();

  @Input() iconClass?: string;
  @Input() id?: string;
  @Input() disabled?: boolean = false;

  public inputSearch: string = '';
  public iconSelected?: IIcons;
  public icons?: IIcons[] = [];
  public dataPaginate?: IIcons[][][] = [];
  public data: IIcons[][] = [];
  public totalPaginate: number = 0;
  public page: number = 0;
  timeoutId?: any;

  search(): any {
    const filtar = () => {
      const filter = this.icons.filter((item) => {
        const term = this.inputSearch.toLowerCase();
        return item.name.toLowerCase().includes(term);
      });
      this.page = 0;
      this.dataPaginate = groupPaginator(groupPaginator(filter, 4), 4);
      this.data = this.dataPaginate[this.page];
      this.totalPaginate = this.dataPaginate.length;
    }
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(filtar, 1000);
  }

  toggle() {
    if (this.p1.isOpen()) {
      this.p1.close();
    } else {
      this.p1.open();
    }
  }

  public selected(icon: IIcons) {
    this.iconSelected = undefined;
    setTimeout(() => {
      this.iconSelected = icon;
      this.iconChange.emit(icon.id);
    }, 1);
    this.p1.close();
  }

  constructor() {
    this.icons = ICONS();
    this.dataPaginate = groupPaginator(groupPaginator(this.icons, 4), 4);
    this.data = this.dataPaginate[this.page];
    this.totalPaginate = this.dataPaginate.length;
  }

  next() {
    this.page++;
    this.data = this.dataPaginate[this.page];
  }

  previous() {
    this.page--;
    this.data = this.dataPaginate[this.page];
  }

  ngOnInit(): void {
    if (this.icon)
      this.iconSelected = this.icons.filter((item) => { 
        return item.id.toLowerCase() == this.icon.toLowerCase(); 
      })[0];
  }

}
