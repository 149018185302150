<div class="projects-section">

<div class="section-header">
  <h1 class="h2">{{ title }}</h1>
</div>

<div [hidden]="saved" style="width: 400px">
  <form [formGroup]="modelForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="mb3">
      <label for="description">Sistema</label>
      <ng-select appearance="outline" id="SistCod" labelForId="SistCod" formControlName="SistCod"
        [ngClass]="{ 'is-invalid': submitted && f.SistCod.errors }" class="form-control"
        placeholder="Selecione...">
        <ng-option *ngFor="let otp of optSistema" [value]="otp.Cod">
          {{ otp.Nome }}
        </ng-option>
      </ng-select>
      <div *ngIf="submitted && f.SistCod.errors" class="invalid-feedback">
        <div *ngIf="submitted && f.SistCod.errors.required">
          Sistema é obrigatório
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="label-control" for="Descricao">Descrição</label>
      <input
        type="text"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.Descricao.errors }"
        id="Descricao"
        formControlName="Descricao"
        name="Descricao"
      />
      <div *ngIf="submitted && f.Descricao.errors" class="invalid-feedback">
        <div *ngIf="submitted && f.Descricao.errors.required">
          Descrição é obrigatório
        </div>
      </div>
    </div>
    <div class="mb-3">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">

          <button type="submit" class="custom-btn custom-btn-azul">
            <i class="fa fa-save"></i>
            Gravar
          </button>

        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">

          <button
      class="custom-btn custom-btn-cinza"
      [routerLink]="['/operacao-rota-lista']"
      ngbTooltip="Operação de Rota"
    >
      Cancelar
    </button>

        </div>
      </div>
    </div>


    </div>
  </form>
</div>
<br />

<div [hidden]="!saved">
  <div class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Registro!</strong> salvo com sucesso.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    <hr />
    <p class="mb-0">
      <button class="btn btn-success" (click)="newSistema()">
        <i class="fa fa-plus"></i>
        Novo
      </button>
    </p>
  </div>
</div>

</div>
