import { Injectable } from '@angular/core';

export class CustomLogging {
  title: string;
  color: string;
  size: string;

  constructor(title: string, color: string, size: string) {
    this.title = title;
    this.color = color;
    this.size  = size;
  }

  log() {
    console.log(
      `%c${this.title}`, // everything after the %c is styled
      `color:  ${this.color};
      font-weight: bold;
      font-size: ${this.size};
      text-shadow: 0 0 5px rgba(0,0,0,0.2); `
     ); 
  }
 
  log2(text = "") {
    // the second line is now the body because the first references the content after the first %c for the title
    console.log(
      `%c${this.title} | %c${text}`,
      `color: ${this.color};
       font-weight: bold; font-size: ${  this.size };`,
      `color: ${this.color};
       font-weight: bold; font-size: ${ this.size};
       text-shadow: 0 0 5px rgba(0,0,0,0.2);`
    );
  }
}
