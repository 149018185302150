<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ngx-spinner size="medium" type="pacman"></ngx-spinner>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2">{{ title }}</h1>
  </div>

  <div class="row">
    <div class="col-sm-1 ">
      <b>Sistema:</b>
    </div>
    <div class="col-sm-11">
      <p>{{ item?.Sistema?.Nome }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-1 ">
      <b>Usuário:</b>
    </div>
    <div class="col-sm-11">
      <p>{{ item?.PessoaFisica?.NomeCompleto }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div id="affix-demo p-2">
        <tree-root #tree [nodes]="nodes" [options]="options" [focused]="true">
          <ng-template #treeNodeTemplate let-node let-index="index">
            <!-- <input
            type="checkbox"
            [indeterminate]="node.data.indeterminate"
            [checked]="node.data.checked"
            (change)="check(node, !node.data.checked)"
          /> -->

            <!-- <span>&rarr;</span> -->

            <span [class]="node.getClass()"><b>{{ node.data.Titulo }}</b></span>

            <!-- <span>&rarr;Operação</span> -->

            <!-- <div class="form-check form-check-inline" *ngFor="let operation of node.data.Operations; index as ix">
            <input class="form-check-input" type="radio" name="flexRadioDefault{{node.data.Id}}" id="flexRadioDefaul{{node.data.Id}}">
            <label class="form-check-label" for="flexRadioDefault{{node.data.Id}}">
              {{operation.Description}}
            </label>
          </div> -->

            <ul *ngIf="node.data.Rotas.length > 0">
              <li *ngFor="let rota of node.data.Rotas; index as ix">
                <!-- {{rota.SystemUserRoutesOperations[0]?.RouteOperationId | json}} -->
                <div class="input-group input-group-sm">
                  <label class="input-group-text">
                    {{rota.Caminho}}
                  </label>
                  <select class="form-select form-select-sm" placeholder="Selecione a Operação"
                    (change)="selectChange(node.data, rota, $event)" name="Rota_{{rota.MenuCod}}_{{rota.Cod}}">
                    <option [value]="0">Sem Permissão</option>
                    <option *ngFor="let operacao of node.data.Operacoes" [value]="operacao.Cod"
                      [selected]="operacao.Cod == rota.OperacaoCod">
                      {{operacao.Descricao}}
                    </option>
                  </select>
                </div>


              </li>
            </ul>

            <!-- <div class="list-group">
            <label class="list-group-item" *ngFor="let route of node.data.Routes; index as ix">
              {{route.Path}}

              <div class="form-group">
                <ng-select 
                  placeholder="Selecione a Operação"
                  name="Route_{{route.Id}}">
                  <ng-option *ngFor="let operation of node.data.Operations" [value]="operation.Id">{{operation.Description}}</ng-option>
               </ng-select>
              </div> -->

            <!-- <span>&rarr;Operação</span>
              <div class="form-check form-check-inline" *ngFor="let operation of node.data.Operations; index as ix">
                <input class="form-check-input" type="radio" name="flexRadioDefault{{node.data.Id}}_{{route.Id}}" id="flexRadioDefaul{{node.data.Id}}_{{route.Id}}">
                <label class="form-check-label" for="flexRadioDefault{{node.data.Id}}_{{route.Id}}">
                  {{operation.Description}}
                </label>
              </div> -->

            <!-- </label>
          </div> -->
            <!-- <span *ngFor="let route of node.data.Routes">{{route.Path}}</span> -->
          </ng-template>
        </tree-root>
      </div>

      <div class="btn-group mr-2">
        <button (click)="tree.treeModel.expandAll()" class="btn btn-sm btn-outline-primary" ngbTooltip="Expandir">
          <i class="fa fa-expand" aria-hidden="true"></i>
        </button>
        <button (click)="tree.treeModel.collapseAll()" class="btn btn-sm btn-outline-primary" ngbTooltip="Retrair">
          <i class="fa fa-compress" aria-hidden="true"></i>
        </button>
      </div>

      <div class="row">       
        <div class="col-2">
          <div class="d-grid gap-2 py-3">
            <button type="button" class="custom-btn mx-3" (click)="back()" ngbTooltip="Voltar">
              <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>