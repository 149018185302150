import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as jQuery from 'jquery';
import { NgdbSortableHeaderDirective } from '../../../sortable/ngdb-sortable-header.directive';
import { SortEvent, rotate, SortDirection } from '../../../sortable/sort-event';
import { Paginator } from '../../../model/paginator';
import { ToastService } from '../../../toast/toast.service';
import { UsuarioService } from '../../../api/RH/usuario.service';
import { Usuario } from '../../../model/poco/usuario';
import { SistemaService } from 'src/app/api/RH/sistema.service';
import { Sistema } from 'src/app/model/RH/sistema';

@Component({
    selector: 'app-usuario-lista',
    templateUrl: './usuario-lista.component.html',
    styleUrls: ['./usuario-lista.component.scss']
})
export class UsuarioListaComponent implements OnInit {
    optSistema: Sistema[] = [];
    sistCod?: number;

    @ViewChildren(NgdbSortableHeaderDirective) headers: QueryList<NgdbSortableHeaderDirective>;
    constructor(
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        public toastService: ToastService,
        private usuarioService: UsuarioService,
        private sistemaService: SistemaService
    ) { }

    ngOnInit() {
      this.loadData();
      this.getSistema();
    }
  
    getSistema(): void {
      this.sistemaService.getAll()
        .subscribe((res: any) => {
          this.optSistema = res;  
        }, err => {
          console.log(err);
        });
    }
    
    onChangeSistema(event): void {
      this.loadData();
    }

    title = 'Usuários';
    closeResult: string;
    data: Usuario[] = [];
    selected: Usuario;
    page: number = 1;
    pageSize: number = 10;
    maxSize: number = 20
    total = 0;
    search: string = '';
    column: string = 'Numero';
    direction: string = 'desc';
    //selectedQtd: number = 10
    optionsQtd: number[] = [10, 20, 30, 50];

    onRefresh() {
        this.page = 1;
        this.pageSize = 10;
        this.search = '';
        this.loadData();
    }
    onSelectedQtd(value: number): void {
        console.log(value)
        this.pageSize = value;
        this.loadData();
    }
    loadData() {
        this.spinner.show();
        this.usuarioService.getPagination(this.sistCod, this.search, this.column, this.direction, this.page, this.pageSize)
            .subscribe((res: Paginator<Usuario>) => {
                this.data = res.Rows;
                this.total = res.Total;
                this.spinner.hide();
            }, err => {
                this.spinner.hide();
                console.log(err);
            });
    }

    toggleNavbar() {
        jQuery("#wrapper").toggleClass("toggled");
    }

    onSort({ column, direction }: SortEvent) {

        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.column = column;
        this.direction = direction;
        this.loadData();
    }

    open(content, item : Usuario) {
        this.selected = item ;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${item.Numero}`;
            // this.usuarioSistemaService.delete(item.Id)
            //     .subscribe((res: Usuario) => {
            //         this.toastService.show('Registro excluido com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
            //         this.loadData();
            //     }, err => {
            //         this.toastService.show('Registro não pode ser excluído.', { classname: 'bg-danger text-light', delay: 10000 });
            //         console.log(err);
            //     });
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
