<div class="mb-3" *ngIf="show">
    <label for="formFile" class="form-label">Imagem</label>
    <input class="form-control" type="file" id="formFile" name="filefield" (change)="selectFile($event)" accept="image/*">
</div>
<div class="mb-3" *ngIf="fileBase64?.file">
    <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <img [src]="'data:'+ fileBase64.type + ';base64, ' + fileBase64.file" class="img-thumbnail" [alt]="fileToUpload?.name" />
        </div>
    </div>
</div>

<!-- <div class="mb-3" *ngIf="image">
    <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <img [src]="'data:image/png;base64, ' + image" class="img-thumbnail" [alt]="'...'" />
        </div>
    </div>
</div> -->
