import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry, switchMap } from "rxjs/operators";
import { AuthService } from "../services/auth-service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(
      !httpRequest.url.endsWith('Autenticacao') && 
      !httpRequest.url.includes('Autenticacao/Refresh')
    ){
      if(this.authService.isLoggedIn()){
        if(this.authService.isActive()) {
          httpRequest = this.setHeader(httpRequest, this.authService.token);
          return next.handle(httpRequest);
        }
        else{
          this.isRefreshing = true;
          return this.authService.refreshToken().pipe(
            switchMap((data) => {
              this.authService.token = data.access_token;
              httpRequest = this.setHeader(httpRequest, data.access_token);
              this.isRefreshing = false;

              return next.handle(httpRequest);
            }),
            catchError((error) => {
              this.isRefreshing = false;

              if (error.status == '401') {
                this.authService.token = undefined;
                this.authService.logout();
              }
              if (error.status == '404') {
                this.authService.token = undefined;
              }

              return throwError(() => error);
            })
          );
        }
      }
    }
    
    if(httpRequest.url.includes('Autenticacao/Refresh')){
      httpRequest = this.setHeader(httpRequest, this.authService.token);
    }

    return next.handle(httpRequest);
  }

  private setHeader(httpRequest: HttpRequest<any>, token: string){
    httpRequest = httpRequest.clone({ setHeaders: { 'Authorization-Jwt': `Bearer ${token}` }});
    return httpRequest
  }
}