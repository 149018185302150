<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2"> {{title}} </h1>
  </div>
  
  <div class="form-group col-md-12">
    <label for="SistCod">Sistema</label>
    <ng-select appearance="outline" id="SistCod" labelForId="SistCod" [(ngModel)]="sistCod" class="col-md-4"
    (change)="onChangeSistema($event)" placeholder="Selecione...">
    <ng-option *ngFor="let otp of optSistema" [value]="otp.Cod">
      {{ otp.Nome }}
    </ng-option>
  </ng-select>
  <div class="row">
      <!-- <div class="col-md-4">
        <button class="view-btn list-view" (click)="onRefresh()" title="Relatório Sintético">
          <i class="fa fa-solid fa-file-lines"></i>
        </button>
        <button class="view-btn list-view" (click)="onRefresh()" title="Relatório Analítico">
          <i class="fa fa-solid fa-file-lines"></i>
        </button>
      </div> -->
    </div>
  </div>
  <div>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">

    <ngx-spinner size="medium" type="pacman"></ngx-spinner>

    <div class="btn-toolbar mb- mb-md-0">

      <div class="input-wrapper">

        <ngx-spinner size="medium" type="pacman"></ngx-spinner>
  
        <input class="custom-input" (keydown.enter)="loadData()" type="text" placeholder="Pesquisar" name="search"
          [(ngModel)]="search">
  
        <button class="feather feather-search" type="button" (click)="loadData()" placement="top" ngbTooltip="Pesquisar">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
            <defs></defs>
            <circle cx="11" cy="11" r="8"></circle>
            <path d="M21 21l-4.35-4.35"></path>
          </svg>
        </button>
  
      </div>


      <button class="view-btn list-view" (click)="onRefresh()" title="Refresh">
        <i class="fa fa-refresh feather feather-list"></i>
      </button>
  
      <button class="view-btn list-view" [routerLink]="['/usuario-form']" placement="top" ngbTooltip="Novo">
        <i class="fa fa-plus"></i>
      </button>

      <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button class="view-btn list-view p-1" ngbDropdownToggle>{{pageSize}}</button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button *ngFor="let opt of optionsQtd" ngbDropdownItem (click)="onSelectedQtd(opt)">{{ opt }} Itens por página</button>
        </div>
      </div>

    </div>

  </div>

  <div class="table-responsive">
    <table class="customers">

      <tr class="px-2">
        <th scope="col" sortable="Numero" (sort)="onSort($event)">Número</th>
        <th scope="col" sortable="Nome" (sort)="onSort($event)">Nome</th>
        <th scope="col" sortable="Cpf" (sort)="onSort($event)">CPF</th>
        <th scope="col" sortable="Sexo" (sort)="onSort($event)">Sexo</th>
        <th scope="col">Opções</th>
      </tr>
      <tr class="px-2" *ngFor="let item of data">
        <td>{{ item.Numero}}</td>
        <td>{{ item.Nome }}</td>
        <td>{{ item.Cpf}}</td>
        <td>{{ item.Sexo }}</td>
        <td>
          <!-- <button class="btn btn-sm btn-outline-danger me-2" type="button" (click)="open(content, item )"
            placement="top" ngbTooltip="Excluir">
            <i class="fa fa-fw fa-trash"></i>
          </button> -->
          <a [routerLink]="['/usuario-form', item.Numero]" class="btn btn-sm btn-outline-info me-2" placement="top"
            ngbTooltip="Editar">
            <i class="fa fa-fw fa-edit"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>

  <div class="d-flex justify-content-center px-2 py-4">
    <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" [maxSize]="maxSize"
      (pageChange)="loadData()">
    </ngb-pagination>

  </div> 

</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Deseja excluir registro?</h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>

  </div>
  <div class="modal-body">
    <p>Nome: {{selected.Nome}}</p>
    <!-- <p>E-mail: {{selected.Email}}</p> -->
    <p>Número: {{selected.Numero}}</p>
    <p>Status: {{selected.Sexo }}</p>
  </div>
  <div class="modal-footer">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-vermelho" (click)="modal.close()">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn" (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>


  </div>
</ng-template>