import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from '@ng-select/ng-select';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppComponent } from "./app.component";
import { AppCoreModule } from "./app.core.module";
import { AppRoutingModule } from "./app-routing.module";

import { environment } from "src/environments/environment";

import { NgPickerComponent } from './component/ng-picker/ng-picker.component';
import { NgDatePickerComponent } from './component/ng-date-picker/ng-date-picker.component';
import { NgFileImgComponent } from "./component/ng-file-img/ng-file-img.component";

/* imports da aplicação */
import { NgdbSortableHeaderDirective } from './sortable/ngdb-sortable-header.directive';
import { StatusPipe } from './pipes/status.pipe';
import { ToastContainerComponent } from './toast/toast-container/toast-container.component';

/* Layout */
import { AuthComponent } from './layouts/auth/auth.component';
import { AdminComponent } from './layouts/admin/admin.component';

/* Services */
import { LocalStorageService } from './services/local-storage.service'
import { DefaultHttpHeadersService } from './services/default-request-options.service';
import { AuthGuardRouterService } from './services/auth-guard-router.service';

/* Pages */
import { OperacaoRotaListaComponent } from "./page/operacao-rota/operacao-rota-lista/operacao-rota-lista.component";
import { OperacaoRotaFormComponent } from "./page/operacao-rota/operacao-rota-form/operacao-rota-form.component";

import { HomeComponent } from "./page/home/home.component";

import { LoginComponent } from './page/login/login.component';

import { EsqueciMinhaSenhaComponent } from './page/senha-acesso/esqueci-minha-senha/esqueci-minha-senha.component';
import { RecuperarSenhaComponent } from './page/senha-acesso/recuperar-senha/recuperar-senha.component';

import { MenuImagemComponent } from './page/menu/menu-imagem/menu-imagem.component';
import { MenuListaComponent } from './page/menu/menu-lista/menu-lista.component';

import { SistemaFormComponent } from "./page/sistema/sistema-form/sistema-form.component";
import { SistemaListaComponent } from "./page/sistema/sistema-lista/sistema-lista.component";
//import { SistemaLoginComponent } from './page/sistema/sistema-login/sistema-login.component';

import { UsuarioListaComponent } from "./page/usuario/usuario-lista/usuario-lista.component";
import { UsuarioFormComponent } from "./page/usuario/usuario-form/usuario-form.component";
import { UsuarioMenuComponent } from "./page/usuario/usuario-menu/usuario-menu.component";
import { KongInterceptor } from "./providers/kong-interceptor";
import { KongService } from "./services/kong-service";
import { AuthInterceptor } from "./providers/auth-interceptor";
import { CookiesInterceptor } from "./providers/cookie-interceptor";
import { CaptchaInterceptor } from "./providers/captcha-interceptor";

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,

    NgDatePickerComponent,
    
    EsqueciMinhaSenhaComponent,
    RecuperarSenhaComponent,

    OperacaoRotaListaComponent,
    OperacaoRotaFormComponent,

    HomeComponent,
    SistemaListaComponent,
    SistemaFormComponent,
    NgdbSortableHeaderDirective,
    ToastContainerComponent,
    UsuarioListaComponent,
    UsuarioFormComponent,
    UsuarioMenuComponent,

    StatusPipe,
    MenuListaComponent,
    LoginComponent,
    AdminComponent,
    AuthComponent,
    //SistemaLoginComponent,
    NgPickerComponent,
    NgFileImgComponent,
    MenuImagemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AppCoreModule,
    NgxSpinnerModule,
    TreeModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
  ],

  providers: [
    LocalStorageService,
    KongService,
    { provide: HTTP_INTERCEPTORS, useClass: KongInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: QueueInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CaptchaInterceptor, multi: true },
    //DefaultHttpHeadersService,
    AuthGuardRouterService 
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.info("App Module initialised");
    //console.log(environment);
    console.log(`Ambiente => ${environment.ambiente}`);
    console.log(`Mensagem => ${environment.message}`);
  }
}
