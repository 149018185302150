<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>


<div class="limiter">
    <div class="container">
        <div class="p-4 wrap-login100">

                <form class="login100-form m-3" [formGroup]="modelForm" (ngSubmit)="onSubmit()" novalidate>
                    
                        <div class="d-flex justify-content-center align-items-center pb-3" >
                            <div>
                                <img class="brasao" src="./assets/img/brasao-ms.png">
                                <h5 class="h1 font-weight-normal brasao">
                                    <span class="login100-form-title">
                                        <b>SAI</b>
                                    </span>
                                </h5>
                            </div>
                        </div> 
                    

                    <div class="mb-3">
                        
                        <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input type="text" class="datepickers form-control input-login"
                                [ngClass]="{ 'is-invalid': !f.Cpf.pristine && f.Cpf.errors }" id="Cpf" name="Cpf"
                                formControlName="Cpf" placeholder="CPF" mask="000.000.000-00" />
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="fas fa-id-card" aria-hidden="true"></i>
                            </span>
                        </div>

                        <div *ngIf="!f.Cpf.pristine && f.Cpf.errors" class="invalid-feedback">
                            <div *ngIf="f.Cpf.errors.required">
                                Cpf é obrigatório
                            </div>
                            <div *ngIf="f.Cpf.errors.minlength">
                                Cpf deve possuir minímo de {{f.Cpf.errors.minlength.requiredLength}} caracteres.
                            </div>
                            <div *ngIf="f.Cpf.errors.maxlength">
                                Cpf deve possuir máximo de {{f.Cpf.errors.maxlength.requiredLength}} caracteres.
                            </div>
                            <div *ngIf="f.Cpf.errors.cpfInvalido">
                                Cpf inválido.
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <!-- <label for="Senha" class="form-label">
                            Senha
                        </label> -->

                        <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input type="password" class="form-control input-login"
                                [ngClass]="{ 'is-invalid': !f.Senha.pristine && f.Senha.errors }" id="Senha"
                                formControlName="Senha" name="Senha" placeholder="Senha" />
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                            </span>
                        </div>


                        <div *ngIf="!f.Senha.pristine && f.Senha.errors" class="invalid-feedback">
                            <div *ngIf="f.Senha.errors.required">
                                Senha é obrigatório
                            </div>
                            <div *ngIf="f.Senha.errors.minlength">
                                Senha deve possuir minímo de {{f.Senha.errors.minlength.requiredLength}} caracteres.
                            </div>
                            <div *ngIf="f.Senha.errors.maxlength">
                                Senha deve possuir máximo de {{f.Senha.errors.maxlength.requiredLength}} caracteres.
                            </div>
                        </div>
                    </div>

                    <div class="d-grid gap-2">
                        <button class="custom-btn custom-btn-azul" type="submit">Entrar</button>
                    </div>

                    <p class="card-text text-center txt1 mt-2">
                        <a class="txt2" href="EsqueciMinhaSenha">Esqueci minha senha / Primeiro acesso</a>
                    </p>
                    <!-- <p class="card-text text-center">
                        <a href="SenhaDeAcesso/RecuperarSenha">RecuperarSenha?</a>
                    </p> -->
                    <p class="card-text text-center text-danger txt1 mt-3">
                        Ambiente : {{ strAmbiente }}
                    </p>

                </form>

        </div>
    </div>    
</div>