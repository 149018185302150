import { Type } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


export enum EnSituations {
  db = 1,
  modify = 2,
}

export type FileBase64 = {
  situation?: EnSituations,
  file?: string;
  name?: string;
  type?: string;
}

@Component({
  selector: 'ng-file-img',
  templateUrl: './ng-file-img.component.html',
  styleUrls: ['./ng-file-img.component.scss']
})
export class NgFileImgComponent implements OnInit {

  public fileToUpload?: File;
  public fileBase64?: FileBase64;

  @Input() 
  public show?: boolean = true;

  @Input() 
  public image?: string;

  @Input() 
  public file?: FileBase64;

  @Output() 
  fileChange = new EventEmitter<FileBase64>();

  constructor() { }

  ngOnInit(): void {
    this.fileBase64 = {
      file: this.image,
      type: "image/png",
      situation : EnSituations.db,
    };
  }

  public selectFile(event: any) {
    this.fileToUpload = undefined;
    this.fileBase64 = { 
      situation : EnSituations.modify,
      file: undefined,
      type: undefined,
    };
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.fileBase64 = {
          file: btoa(event.target.result),
          name: this.fileToUpload.name,
          type: this.fileToUpload.type,
          situation : EnSituations.modify,
        };
        this.fileChange.emit(this.fileBase64);
      }
      reader.readAsBinaryString(this.fileToUpload);
    } else {
      this.fileChange.emit(this.fileBase64);
    }
  }
}
