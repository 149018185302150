<nav class="sidebar open">

  <div class="logo-details">
    <!-- <i class='bx bxl-c-plus-plus icon'></i> -->
    <div class="" style="max-width: 200px;">
      <img src="../../../assets/img/GOV-MS-horizontal-COLOR.png" alt="" class="img-fluid p-3">
    </div>
    <!-- <div class="logo_name">Teste</div> -->
    <!-- <i class='bx bx-menu' id="btn" ></i> -->
  </div>

  <ul class="nav-list">

    <li class="nav-item">
      <a class="nav-link" routerLink="/home">
        <i class="fa fa-fw fa-house"></i>
        Home
      </a>
    </li>

    <li *ngFor="let menu of menus">
      <a routerLink="{{menu.Rota}}">
        <i class='bx {{menu.Icone}}'></i>
        {{menu.Nome}}
      </a>
      <span class="tooltip">{{menu.Nome}}</span>
    </li>
    <!-- <li class="profile">
      <div class="profile-details">
        <img
        href="https://www.codingnepalweb.com/demos/responsive-side-navigation-bar-in-html-css-javascript/profile.jpg"
          alt="profileImg">
        <div class="name_job">
          <div class="name">Prem Shahi</div>
          <div class="job">Web designer</div>
        </div>
      </div>
      <i class='bx bx-log-out' id="log_out"></i>
    </li> -->
  </ul>

</nav>

<div class="navbar-div">



  <nav class="navbar navbar-expand">
    <i class='bx bx-menu-alt-left' id="btn"></i>

    <div class="container-base">

      <div class="navbar-collapse collapse show" id="navbarCollapse">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown navbar-dropdown">
            <a class="nav-link nav-link-v2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-user-circle" aria-hidden="true"></i> Bem vindo, {{usuarioLogado.Nome.split(' ')[0]}}
            </a>
            <ul class="dropdown-menu dropdown-menu-end sub-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><label class="dropdown-item" href="#">{{usuarioLogado.Nome}}</label></li>
              <li><label class="dropdown-item" href="#">{{usuarioLogado.CPF}}</label></li>
              <li><label class="dropdown-item" href="#">{{usuarioLogado.Numero}}</label></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" (click)="openLogout(modalsair)" data-toggle="modal" data-target="#logoutModal"
                  style="cursor: pointer;">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  Sair
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

  </nav>




</div>

<!-- <nav class="navbar navbar-expand">
    <i class='bx bx-menu-alt-left' id="btn"></i>
  
    <div class="container-base">
  
      <div class="navbar-collapse collapse show" id="navbarCollapse">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown navbar-dropdown">
            <a class="nav-link nav-link-v2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-user-circle" aria-hidden="true"></i> Bem vindo, {{usuarioLogado.Nome.split(' ')[0]}}
            </a>
            <ul class="dropdown-menu dropdown-menu-end sub-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><label class="dropdown-item" href="#">{{usuarioLogado.Nome}}</label></li>
              <li><label class="dropdown-item" href="#">{{usuarioLogado.CPF}}</label></li>
              <li><label class="dropdown-item" href="#">{{usuarioLogado.Numero}}</label></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" (click)="openLogout(modalsair)" data-toggle="modal" data-target="#logoutModal"
                  style="cursor: pointer;">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  Sair
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  
  </nav> -->

<ng-template #modalsair let-modal>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Deseja sair?</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      </button>
    </div>
    <div class="modal-body">
      <p>clique abaixo em "Sair" se você estiver pronto para encerrar sua sessão atual.</p>
    </div>
    <div class="modal-footer p-1">

      <div class="row">
        <div class="col-6">
          <div class="d-grid gap-2 px-2">
            <button type="button" class="custom-btn" (click)="modal.dismiss('Cancelar')">Cancelar</button>

          </div>
        </div>
        <div class="col-6">
          <div class="d-grid gap-2 px-2">
            <button type="button" class="custom-btn custom-btn-vermelho" (click)="modal.close()">Sair</button>

          </div>
        </div>
      </div>
    </div>
</ng-template>


<main class="main">
  <div class="myScrollableDiv">

    <div class="w-100">
      <main role="main" id="page-content-wrapper" class="col">
        <div class="container-fluid mt-2">
          <router-outlet></router-outlet>
        </div>

        <div class="footer-sai container-fluid mt-2">
          <footer class="py-3 my-4 border-top" style="bottom: 0;">
            <div class="text-center text-muted">
              <!-- <span style="font-size: 0.75em">
              Versão
            </span> -->
              <span style="font-size: 0.75em">
                {{getAmbiente()}}
              </span>
            </div>
            <p class="text-center text-muted">Sistema de Acesso Integrado &copy; {{getYear()}}</p>
          </footer>
        </div>

      </main>
    </div>

  </div>
</main>