import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { UsuarioLogado } from 'src/app/model/usuario-logado';
import TokenSai from 'src/app/model/tokenSAI';
import { MenuSAI } from 'src/app/model/menuSAI';
import { MenuStorageService } from 'src/app/services/menu-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss", "./../../../stylesSistema.scss"],
})
export class AdminComponent implements OnInit {

  public usuarioLogado: UsuarioLogado;
  public menus: MenuSAI[];

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private menuStorageService: MenuStorageService
  ) {
    this.usuarioLogado = new TokenSai(this.authService.token);
    this.menus = this.menuStorageService.menus;
  }

  public redirectAfterLogin: string[] = ["/login"];

  ngOnInit() {
    const sidebar = document.querySelector(".sidebar");
    const menu = document.querySelector(".menu-content");
    const menuItems = document.querySelectorAll(".submenu-item");
    const subMenuTitles = document.querySelectorAll(".submenu .menu-title");

    let sidebarClose = document.querySelector("#sidebar-close");
    let closeBtn = document.querySelector("#btn");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("close");
      sidebar.classList.toggle("open");
      menuBtnChange(); //calling the function(optional)
    });

    menuItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        menu.classList.add("submenu-active");
        item.classList.add("show-submenu");
        menuItems.forEach((item2, index2) => {
          if (index !== index2) {
            item2.classList.remove("show-submenu");
          }
        });
      });
    });
    subMenuTitles.forEach((title) => {
      title.addEventListener("click", () => {
        menu.classList.remove("submenu-active");
      });
    });

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange();//calling the function(optional)
    });

    // following are the code to change sidebar button(optional)
    function menuBtnChange() {
      if (sidebar.classList.contains("close")) {
        closeBtn.classList.replace("bx-menu-alt-left", "bx-menu");//replacing the iocns class
      } else {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-left");//replacing the iocns class
      }
    }

    function checkScreenSize(): void {
      if (window.innerWidth < 720) {
        if (!sidebar.classList.contains("close")) {
          sidebar.classList.add("close");
        }
      }
    }

    window.addEventListener('resize', () => checkScreenSize());
  }

  openLogout(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.authService.logout();
          this.router.navigate(this.redirectAfterLogin);
          console.log("sair");
        },
        (reason) => {
          console.log("cancelar");
        }
      );
  }
  toggleNavbar() {
    $("#wrapper").toggleClass("toggled");
  }
  getAmbiente() {
    return `Ambiente: ${environment.ambiente}`;
  }
  getYear() {
    return new Date().getFullYear();
  }
}