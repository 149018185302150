import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService } from "../services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class CaptchaInterceptor implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(httpRequest.url.includes('SenhaDeAcesso')
    && httpRequest.method == 'POST') {
      httpRequest = httpRequest.clone({ 
        setHeaders: { 
          'Access-Control-Allow-Headers': 'Captcha',
          'X-Captcha': this.localStorageService.get('Captcha') 
        }
      });
    }
    return next.handle(httpRequest);
  }
}