import { Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpCustomService } from '../../services/http-custom.service';
import { Paginator } from '../../model/paginator';
import { ErrorService } from './../error.service';
import { OperacaoRota } from 'src/app/model/RH/operacao-rota';

@Injectable({ providedIn: 'root' })

export class OperacaoRotaService {

  private readonly apiUrl = `${ environment.apiUrl }/OperacaoRota`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
  ) { }  

  getAll(): Observable<OperacaoRota[]> {
    return this.http.get<OperacaoRota[]>(this.apiUrl).pipe(
      tap((sistema: OperacaoRota[]) => console.log('Obter OperacaoRota')),
      catchError(this.errorService.handleError('getAll', []))
    );    
  }

  getPagination(search: string, sortColumn: string, sortDirection: string, page: number, perPage: number, sistCod: number|undefined)
    : Observable<Paginator<OperacaoRota>> {

    let params = new HttpParams()
      .set('search', search )
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('page', page)
      .set('perPage', perPage);
      console.log('params',sistCod);
    if(sistCod)
      params = params.set('sistCod', sistCod );

    const url = `${ this.apiUrl }/Pagination`;

    return this.http.get<Paginator<OperacaoRota>>(url, { params }).pipe(
      tap((resp: any) => console.log('Obter OperacaoRota Pagination', resp as Paginator<OperacaoRota>)),
      catchError(this.errorService.handleError('getPagination', []))
    );
  }

  getById(sistCod: number, cod: number): Observable<OperacaoRota> {
    return this.http.get<OperacaoRota>(`${ this.apiUrl }/${ sistCod }/${ cod }`).pipe(
      tap((sistema: OperacaoRota) => console.log(`Obter OperacaoRota cod=${cod}`)),
      catchError(this.errorService.handleError<OperacaoRota>(`getById cod=${cod}`))
    );
  }

  create(sistema: OperacaoRota): Observable<OperacaoRota> {
    return this.http.post<OperacaoRota>(this.apiUrl, sistema).pipe(
      tap((sistema: OperacaoRota) => console.log(`OperacaoRota incluído w/ Cod = ${sistema.Cod}`)),
      catchError(this.errorService.handleError<OperacaoRota>('Inserindo OperacaoRota'))
    );    
  }

  update(sistCod: number, cod: number, sistema: OperacaoRota): Observable<OperacaoRota> {
    return this.http.put(`${ this.apiUrl }/${ sistCod }/${ cod }`, sistema).pipe(
      tap((sistema: OperacaoRota) => console.log(`OperacaoRota alterado Cod = ${cod}`)),
      catchError(this.errorService.handleError<any>('Alterando OperacaoRota'))
    );      
  }

  delete(sistCod: number, cod: number): Observable<OperacaoRota> {
    return this.http.delete<OperacaoRota>(`${ this.apiUrl }/${ sistCod }/${ cod }`).pipe(
      tap((sistema: OperacaoRota) => console.log(`OperacaoRota deletado Cod = ${sistema.Cod}`)),
      catchError(this.errorService.handleError<OperacaoRota>('Deletando OperacaoRota'))
    );
  }

}
