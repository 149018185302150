import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpCustomService } from "src/app/services/http-custom.service";
import { environment } from "src/environments/environment";
import { EsqueciMinhaSenha } from "../model/esqueci-minha-senha";
import { RecuperarSenha } from "../model/recuperar-senha";

@Injectable({
  providedIn: 'root'
})
export class SenhaDeAcessoService {

  private readonly apiUrl = `${ environment.apiUrl }/SenhaDeAcesso`;

  constructor(
    private http: HttpCustomService
  ) { }
  
  Captcha(): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }/Captcha/Show`);
  }
  
  ExistePessoaFisica(cpf: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/ExistePessoaFisica/${cpf}`);
  }

  EsqueciMinhaSenha(data: EsqueciMinhaSenha): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/EsqueciMinhaSenha`, data);
  }

  ValidarRecuperarSenha(token: string|undefined, email: string|undefined): Observable<any> {
    var params = '';
    if(token)
      params += `/${token}`;
    if(email)
      params += `/${email}`;
    return this.http.get<any>(`${this.apiUrl}/RecuperarSenha${params}`);
  }
  RecuperarSenha(data: RecuperarSenha): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/RecuperarSenha`, data);
  }
}