<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ngx-spinner size="medium" type="pacman"></ngx-spinner>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2"> {{title}} </h1>
  </div>

  <div [hidden]="saved">
    <form class="custom-form-center" [formGroup]="reactiveForm" (ngSubmit)="onSubmit()" novalidate>
      
      <div class="mb-3 row">
        <label for="Numero" class="col-sm-2 col-form-label">Número:</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control-plaintext" id="Numero" formControlName="Numero">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="Cpf" class="col-sm-2 col-form-label">CPF:</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control-plaintext" id="Cpf" formControlName="Cpf" mask="000.000.000-00">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="Nome" class="col-sm-2 col-form-label">Nome:</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control-plaintext" id="Nome" formControlName="Nome">
        </div>
      </div>

      <br />

      <!-- <div class="justify-content-center row">
        <div class="center-button col-6">
          <div class="d-grid gap-2">

            <button type="submit" class="my-2 custom-btn custom-btn-azul">
              <i class="fa fa-save px-2"></i>
              Gravar
            </button>

          </div>
        </div>
        <div class="center-button col-6">
          <div class="d-grid gap-2">

            <button class="my-2 custom-btn" [routerLink]="['/usuario-lista']">
              Cancelar
            </button>

          </div>
        </div>
      </div>

      <br /> -->
    </form>
  </div>

  <div [hidden]="!reactiveForm.value.Numero || saved">
    <hr />
    <br />
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
      <h5 class="h5">Sistemas associados a este usuário</h5>
    </div>
    <div class="float-right d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
      <div class="btn-toolbar mb- mb-md-0">

        <div class="input-wrapper me-2">

          <input class="custom-input" (keydown.enter)="loadData()" type="text" placeholder="Pesquisar" name="search"
            [(ngModel)]="search">

          <button class="feather feather-search" type="button" (click)="loadData()" placement="top"
            ngbTooltip="Pesquisar">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
              <defs></defs>
              <circle cx="11" cy="11" r="8"></circle>
              <path d="M21 21l-4.35-4.35"></path>
            </svg>
          </button>

        </div>

        <button class="view-btn list-view" type="button" (click)="onRefresh()" placement="top" ngbTooltip="Refresh">
          <i class="fa fa-refresh"></i>
        </button>

        <button class="view-btn list-view" (click)="onAdicionarSistemaUsuario(adiciona)" placement="top"
          ngbTooltip="Adicionar Sistema">
          <i class="fa fa-plus"></i>
        </button>

        <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
          <button class="view-btn list-view" ngbDropdownToggle
            ngbTooltip="Itens por página">{{pageSize}}
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button *ngFor="let opt of optionsQtd" ngbDropdownItem (click)="onSelectedQtd(opt)">{{ opt }}
            </button>
          </div>
        </div>

      </div>

    </div>
    <div class="table-responsive d-flex justify-content-center align-items-center">
      <table class="customers">
        <tr>
          <th scope="col" sortable="Description" (sort)="onSort($event)">Sistema</th>
          <th style="text-align: center;" scope="col">Opções</th>
        </tr>
        <tr *ngFor="let item of tableSisUsuAss.rows">
          <td>
            {{ item.Sistema.Nome }}
          </td>
          <td style="text-align: center;">

            <a class="btn-sistemas custom-btn-light me-2" routerLink="/usuario-menu" [state]="{ userMenu: item }"
              placement="top" ngbTooltip="Associar Menu">
              <i class="fa fa-bars"></i>
            </a>

            <button class="btn-sistemas custom-btn-light me-2" (click)="onRemoverSistemaUsuario(remove, item )"
              placement="top" ngbTooltip="Remover registro">
              <i class="fa fa-fw fa-trash"></i>
            </button>

          </td>
        </tr>
      </table>

    </div>
    <div class="d-flex justify-content-center p-2">
      <ngb-pagination [collectionSize]="tableSisUsuAss.total" [(page)]="tableSisUsuAss.page"
        [pageSize]="tableSisUsuAss.perPage" (pageChange)="getUsuarioSistemaAssoPagination()">
      </ngb-pagination>
    </div>
    <br />
  </div>

  <div [hidden]="!saved">
    <div class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>Registro!</strong> salvo com sucesso.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      <hr>
      <p class="mb-0">
        <button class="btn btn-success" type="button" (click)="newSistema()">
          <i class="fa fa-plus"></i>
          Novo
        </button>
      </p>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Pesquisar Servidor</h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>

  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">

      <ngx-spinner size="medium" type="pacman"></ngx-spinner>

      <div class="btn-toolbar mb- mb-md-0">


        <div class="input-wrapper">

          <ngx-spinner size="medium" type="pacman"></ngx-spinner>

          <input class="custom-input" (keydown.enter)="loadData()" type="text" placeholder="Pesquisar" name="search"
            [(ngModel)]="search">

          <button class="feather feather-search" type="button" (click)="loadData()" placement="top"
            ngbTooltip="Pesquisar">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
              <defs></defs>
              <circle cx="11" cy="11" r="8"></circle>
              <path d="M21 21l-4.35-4.35"></path>
            </svg>
          </button>

        </div>

        <div class="btn-group mr-2">

          <button class="view-btn list-view" type="button" (click)="onRefresh()">
            <i class="fa fa-refresh"></i>
          </button>

          <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
            <button class="view-btn list-view p-1" ngbDropdownToggle>{{pageSize}}</button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button *ngFor="let opt of optQtd" ngbDropdownItem (click)="onSelectedQtd(opt)">{{ opt }}
                Itens por página
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col" sortable="NUMERO" (sort)="onSort($event)">Número</th>
            <th scope="col" sortable="CPF" (sort)="onSort($event)">CPF</th>
            <th scope="col" sortable="NOME_COMPLETO" (sort)="onSort($event)">Nome</th>
            <th scope="col" sortable="EMAIL" (sort)="onSort($event)">E-mail</th>
            <th scope="col">Opções</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td>{{ item.Numero}}</td>
            <td>{{ item.CPF }}</td>
            <td>{{ item.NOME_COMPLETO }}</td>
            <td>{{ item.EMAIL }}</td>
            <td>
              <button class="btn btn-sm btn-outline-secondary ml-2" type="button" (click)="modal.close(item)"
                placement="top" ngbTooltip="Selecionar">
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="total" [maxSize]="3" [boundaryLinks]="true" [(page)]="page"
        [pageSize]="pageSize" (pageChange)="loadData()">
      </ngb-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-cinza"
            (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #adiciona let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Deseja adicionar Sistema?
    </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="formNovoSistemaUsuario">
      <div class="form-group">
        <label class="my-1 mr-2" for="sistema">Sistema</label>
        <select class="form-select form-control my-1 mr-sm-2" id="SistCod" formControlName="SistCod"
          [ngClass]="{ 'is-invalid': mSubmitted && m.SistCod.errors }" name="SistCod">
          <option *ngFor="let otp of optSistema" [ngValue]="otp.Cod">{{ otp.Nome }}</option>
        </select>
        <div *ngIf="mSubmitted && m.SistCod.errors" class="invalid-feedback">
          <div *ngIf="mSubmitted && m.SistCod.errors.required">Sistema é obrigatório</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label px-2" for="Mestre">Master</label>
        <input type="checkbox" [ngClass]="{ 'is-invalid': submitted && m.Mestre.errors }" id="Mestre"
          formControlName="Mestre" name="Mestre" />
        <div *ngIf="submitted && m.Mestre.errors" class="invalid-feedback">
          <div *ngIf="submitted && m.Mestre.errors.required">Mestre é obrigatório</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label px-2" for="Ativo">Ativo</label>
        <input type="checkbox" [ngClass]="{ 'is-invalid': submitted && m.Ativo.errors }" id="Ativo"
          formControlName="Ativo" name="Ativo" />
        <div *ngIf="submitted && m.Ativo.errors" class="invalid-feedback">
          <div *ngIf="submitted && m.Ativo.errors.required">Ativo é obrigatório</div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-azul" (click)="onSubmitAddModal(modal)">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn" (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- 
<ng-template #edita let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deseja Editar registro?</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formNovoSistemaUsuario">
      <div class="form-group">
        <label class="my-1 mr-2" for="sistema">Sistema</label>
        <select class="form-select form-control my-1 mr-sm-2" id="sistema" formControlName="SystemId"
          [ngClass]="{ 'is-invalid': mSubmitted && m.SystemId.errors }" name="sistema" [attr.disabled]="true">
          <option *ngFor="let otp of optSistema" [ngValue]="otp.Cod">{{ otp.Nome}}</option>
        </select>
        <div *ngIf="mSubmitted && m.SystemId.errors" class="invalid-feedback">
          <div *ngIf="mSubmitted && m.SystemId.errors.required">Sistema é obrigatório</div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-azul" (click)="onSubmitEditModal(modal)">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-cinza"
            (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>

  </div>
</ng-template> -->

<ng-template #editmenu let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deseja associar menu?</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Selecione o(s) menu(s)</p>

    <tree-root #tree [nodes]="nodes" [options]="options">
      <ng-template #treeNodeTemplate let-node="node" let-index="index">
        <input (change)="check(node, !node.data.checked)" type="checkbox" [indeterminate]="node.data.indeterminate"
          [checked]="node.data.checked">
        {{ node.data.Title }}
      </ng-template>
    </tree-root>
    <br />
    <button (click)="tree.treeModel.expandAll()" class="btn btn-sm btn-outline-primary ml-2" ngbTooltip="Expandir">
      <i class="fa fa-expand" aria-hidden="true"></i>
    </button>
    <button (click)="tree.treeModel.collapseAll()" class="btn btn-sm btn-outline-primary ml-2" ngbTooltip="Retrair">
      <i class="fa fa-compress" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-footer">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-azul" (click)="modal.close()">Confirmar</button>

        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-cinza"
            (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #remove let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deseja excluir registro?</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Sistema: {{selected.Sistema.Nome}}</p>
  </div>
  <div class="modal-footer">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-vermelho" (click)="modal.close()">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" class="custom-btn custom-btn-cinza"
            (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>

  </div>
</ng-template>