import { Component } from "@angular/core";
import * as $ from "jquery";
import { environment } from "../../src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor() {
    console.log(`Ambiente de ${environment.ambiente}`);
  }

  title = "Sistema de Gestão de Menu";

  navbarOpen = false;

  toggleNavbar() {
    //this.navbarOpen = !this.navbarOpen
    $("#wrapper").toggleClass("toggled");
  }
}
