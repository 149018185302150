<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2">{{ title }}</h1>
  </div>

  <div class="form-group col-md-12">
    <label for="description">Sistema</label>
    <ng-select class="position: relative !important;" appearance="outline" id="description" labelForId="description" [(ngModel)]="SistCod"
      (change)="onChangeSistema($event)" placeholder="Selecione...">
      <ng-option *ngFor="let otp of optSistema" [value]="otp.Cod">
        {{ otp.Nome }}
      </ng-option>
    </ng-select>
  </div>
  <div>
    <tree-root #tree [nodes]="nodes" [options]="options" [focused]="true" (moveNode)="onMoveNode($event)"
      (toggleExpanded)="onEvent($event)" (activate)="onEvent($event)" (focus)="onEvent($event)" (blur)="onEvent($event)">
      <ng-template #treeNodeTemplate let-node let-index="index">
        <span [class]="node.getClass()"> <i [ngClass]="node.data?.IconeMenu?.NomeIcone ? node.data?.IconeMenu?.NomeIcone: ''"
            aria-hidden="true"></i> {{ node.data.Titulo }}</span>
      </ng-template>
    </tree-root>
  </div>
  <div class="btn-group mr-2" *ngIf="SistCod">
    <button (click)="addNode(add, tree.treeModel)" class="btn btn-sm btn-outline-success" ngbTooltip="Adicionar">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
    <button (click)="editNode(edit, tree.treeModel)" class="btn btn-sm btn-outline-secondary" ngbTooltip="Editar">
      <i class="fa fa-edit"></i>
    </button>
    <button (click)="removeNode(remove, tree.treeModel)" class="btn btn-sm btn-outline-danger" ngbTooltip="Excluir">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>
    <button (click)="tree.treeModel.expandAll()" class="btn btn-sm btn-outline-primary" ngbTooltip="Expandir">
      <i class="fa fa-expand" aria-hidden="true"></i>
    </button>
    <button (click)="tree.treeModel.collapseAll()" class="btn btn-sm btn-outline-primary" ngbTooltip="Retrair">
      <i class="fa fa-compress" aria-hidden="true"></i>
    </button>
  </div>

</div>

<ng-template #remove let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deseja excluir registro?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="Description">Título</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="Title" required [(ngModel)]="selected.Titulo" name="Titulo"
          aria-describedby="button-picker" disabled />
        <ng-picker [(icon)]="selected?.IconeMenu.NomeIcone" iconClass="btn btn-outline-primary btn-group-custon"
          [id]="'button-picker'" [disabled]="true"></ng-picker>
      </div>
    </div>
    <menu-imagem [(file)]="selected?.IconeMenu.Binario" [menuid]="selected.Id" [show]="false"></menu-imagem>
    <div class="form-group">
      <label for="Descricao">Descrição</label>
      <p class="form-control form-control-sm my-1 mr-sm-2">
        {{ selected.Descricao }}
      </p>
    </div>
    <!-- <div class="form-group">
      <label for="Route">Rota</label>
      <p class="form-control form-control-sm my-1 mr-sm-2">
        {{ selected.Route }}
      </p>
    </div> -->
    <div class="form-group">
      <!-- <div class="btn btn-group btn-group-toggle">
        <label class="btn-primary" for="Ativo" ngbButtonLabel>
          <input type="checkbox" id="Ativo" ngbButton [(ngModel)]="selected.Ativo" name="Ativo" disabled />
          {{ selected.Ativo == true ? "Ativo" : "Inativo" }}
        </label>
      </div> -->

      <div class="btn btn-group btn-group-toggle">
        <label class="btn-primary" for="Publico" ngbButtonLabel>
          <input type="checkbox" id="Publico" ngbButton [(ngModel)]="selected.Publico" name="Publico" disabled />
          {{ selected.Publico == true ? "Publico" : "Privado" }}
        </label>
      </div>
    </div>

    <div>
      <label for="Rotas" class="form-label">Rotas</label>
    </div>

    <div class="list-group">
      <label class="list-group-item" *ngFor="let rota of selected.Rotas; index as ix">
        <!-- <input class="form-check-input me-1" type="checkbox" value=""> -->
        {{rota.Caminho}}
      </label>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">
      Confirmar
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancelar')">
      Cancelar
    </button>
  </div>
</ng-template>

<ng-template #edit let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar registro</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="Titulo">Título</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" id="Titulo" required [(ngModel)]="node.Titulo" name="Titulo"
            aria-describedby="button-picker" />
          <ng-picker [(icon)]="node?.IconeMenu.NomeIcone" iconClass="btn btn-outline-primary btn-group-custon"
            [id]="'button-picker'"></ng-picker>
        </div>
      </div>

      <menu-imagem [(file)]="node?.IconeMenu.ImageFile" [sistCod]="node.SistCod"[menuCod]="node.Cod"></menu-imagem>
      <!-- <pre>{{node?.IconMenu|json}}</pre> -->
      <div class="form-group">
        <label for="Descricao">Descrição</label>
        <input
          type="text"
          class="form-control"
          id="Descricao"
          name="Descricao"
          [(ngModel)]="node.Descricao"
          required
        />
      </div>
      <div class="form-group">
        <div class="btn btn-group btn-group-toggle">
          <label class="btn-primary" for="Publico" ngbButtonLabel>
            <input type="checkbox" id="Publico" ngbButton [(ngModel)]="node.Publico" name="Publico" />
            {{ node.Publico == true ? "Publico" : "Privado" }}
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="RouteId">Rota Principal</label>
        <ng-select class="form-select" [(ngModel)]="node.RotaPrincipal" placeholder="Selecione a Rota Principal" name="Route">
          <ng-option *ngFor="let rota of node.Rotas" [value]="rota.Caminho">{{rota.Caminho}}</ng-option>
        </ng-select>
      </div>
      <div>
        <label for="Rotas" class="form-label">Rotas</label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Nova rota" aria-label="Nova rota"
            aria-describedby="button-addon2" id="novaRota" name="novaRota" [(ngModel)]="novaRota" />
          <button class="btn btn-outline-primary" type="button" (click)="addRoute(node)">Add</button>
        </div>
      </div>

      <div class="list-group">
        <label class="list-group-item" *ngFor="let rota of node.Rotas; index as ix">
          <!-- <input class="form-check-input me-1" type="checkbox" value=""> -->
          {{rota.Caminho}}
          <button class="btn btn-outline-danger" type="button" (click)="removeRoute(node, ix)">Del</button>
        </label>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close()">
      Confirmar
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancelar')">
      Cancelar
    </button>
  </div>
</ng-template>

<ng-template #add let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="selected.Cod">
      Deseja adicionar um novo nó filho ao {{ selected.Titulo }}
    </h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="!selected.Cod">
      Deseja adicionar um novo nó filho a Raíz
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="Titulo">Título</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" id="Titulo" required [(ngModel)]="newNode.Titulo" name="Titulo" />
          <ng-picker [(icon)]="newNode.IconeMenu.NomeIcone" iconClass="btn btn-outline-primary btn-group-custon"
            [id]="'button-picker'"></ng-picker>
        </div>
      </div>
      <ng-file-img [(file)]="newNode.IconeMenu.Binario"></ng-file-img>
      <div class="form-group">
        <label for="Descricao">Descrição</label>
        <input
          type="text"
          class="form-control"
          id="Descricao"
          [(ngModel)]="newNode.Descricao"
          name="Descricao"
          required
        />
      </div>
      <div class="form-group">
        <div class="btn btn-group btn-group-toggle">
          <label class="btn-primary" for="Publico" ngbButtonLabel>
            <input type="checkbox" id="Publico" ngbButton [(ngModel)]="newNode.Publico" name="Publico" />
            {{ newNode.Publico == true ? "Publico" : "Privado" }}
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="RouteId">Rota Principal</label>
        <ng-select class="form-select" [(ngModel)]="newNode.RotaPrincipal" placeholder="Selecione a Rota Principal" name="Rota">
          <ng-option *ngFor="let rota of newNode.Rotas" [value]="rota.Caminho">{{rota.Caminho}}</ng-option>
        </ng-select>
      </div>

      <div>
        <label for="Routes" class="form-label">Rotas</label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Nova rota" aria-label="Nova rota"
            aria-describedby="button-addon2" id="novaRota" name="novaRota" [(ngModel)]="novaRota" />
          <button class="btn btn-outline-primary" type="button" (click)="addRoute(newNode)">Add</button>
        </div>
      </div>

      <div class="list-group">
        <label class="list-group-item" *ngFor="let rota of newNode.Rotas; index as ix">
          <!-- <input class="form-check-input me-1" type="checkbox" value=""> -->
          {{rota.Caminho}}
          <button class="btn btn-outline-danger" type="button" (click)="removeRoute(newNode, ix)">Del</button>
        </label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close()">
      Confirmar
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancelar')">
      Cancelar
    </button>
  </div>
</ng-template>

<ng-template #move let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Deseja mover {{ moved.node.Titulo }} para {{ moved.parent.Titulo }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close()">
      Confirmar
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancelar')">
      Cancelar
    </button>
  </div>
</ng-template>