<ng-template #popContent>
    <ng-container>
        <table class="table table-borderless">
            <tbody>
                <tr>
                    <td>
                        <button type="button" class="btn btn-primary" (click)="previous()" [disabled]="(page+1) == 1"
                            ngbTooltip="anterior">
                            <i class="fa-solid fa-arrow-left"></i>
                        </button>
                    </td>
                    <td colspan="2" class="text-center">
                        {{(page+1)}}/{{totalPaginate}}
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" (click)="next()"
                            [disabled]="(page+1) == totalPaginate" ngbTooltip="próximo">
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <input id="table-filtering-search" class="form-control" type="text" [(ngModel)]="inputSearch"
                            placeholder="pesquisar..." (ngModelChange)="search()" />
                    </td>
                </tr>
                <tr *ngFor="let row of data">
                    <td *ngFor="let col of row">
                        <button type="button" class="btn"
                            [ngClass]="{ 'btn-secondary': (iconSelected && iconSelected.id == col.id), 'btn-light': (!iconSelected || iconSelected.id != col.id)}"
                            (click)='selected(col)' [ngbTooltip]="col?.name">
                            <i [ngClass]="col?.id"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</ng-template>

<button #popoverButton type="button" class="btn dropdown-toggle"
    [ngClass]="iconClass ? iconClass : 'btn-outline-secondary'" [ngbPopover]="popContent" [autoClose]="false"
    triggers="manual" #p1="ngbPopover" (click)="toggle()" aria-expanded="false" [id]="id" [disabled]="disabled">
    <span *ngIf="iconSelected; else anonymousUser">
        <i [ngClass]="iconSelected?.id"></i> <!-- {{iconSelected?.name}} -->
    </span>
    <ng-template #anonymousUser>
        Selecione o icone
    </ng-template>
</button>