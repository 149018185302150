<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="projects-section">

  <div class="section-header">
    <h1 class="h2"> {{title}} </h1>
  </div>

  <div class="view-actions pb-3">

    <div class="input-wrapper">

      <ngx-spinner size="medium" type="pacman"></ngx-spinner>

      <input class="custom-input" (keydown.enter)="loadData()" type="text" placeholder="Pesquisar" name="search"
        [(ngModel)]="search">

      <button class="feather feather-search" type="button" (click)="loadData()" placement="top" ngbTooltip="Pesquisar">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <defs></defs>
          <circle cx="11" cy="11" r="8"></circle>
          <path d="M21 21l-4.35-4.35"></path>
        </svg>
      </button>

    </div>

    <button class="view-btn list-view" (click)="onRefresh()" title="Refresh">
      <i class="fa fa-refresh feather feather-list"></i>
    </button>

    <button class="view-btn list-view" [routerLink]="['/sistema-form']" placement="top" ngbTooltip="Novo Sistema">
      <i class="fa fa-plus"></i>
    </button>

  </div>

  <!-- <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 ">

    <ngx-spinner size="medium" type="pacman"></ngx-spinner>

    <div class="btn-toolbar mb- mb-md-0">

      <div class="input-group me-2">
        <input type="text" class="form-control form-control-sm ml-2" placeholder="Pesquisar" name="search"
          [(ngModel)]="search" />

        <button class="btn btn-sm btn-outline-secondary" type="button" (click)="loadData()" placement="top"
          ngbTooltip="Pesquisar">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>

      </div>

      <div class="btn-group me-2">

        <button class="btn btn-sm btn-outline-primary" type="button" (click)="onRefresh()" placement="top"
          ngbTooltip="Refresh">
          <i class="fa fa-refresh"></i>
        </button>

        <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
          <button class="btn btn-sm btn-outline-secondary" ngbDropdownToggle>{{pageSize}}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button *ngFor="let opt of optionsQtd" ngbDropdownItem (click)="onSelectedQtd(opt)">{{ opt }}</button>
          </div>
        </div>

        <button class="btn btn-sm btn-outline-success" [routerLink]="['/sistema-form']" placement="top"
          ngbTooltip="Novo">
          <i class="fa fa-plus"></i>
        </button>

      </div>

    </div>
  </div> -->


  <div class="project-boxes jsListView">

    <div *ngIf="data.length > 0; else noData">

      <div class="project-box-wrapper" *ngFor="let item of data ">
        <div class="row project-box mx-0" style="background-color: #d5deff;">

          <div class="col-md-1 col-xs-12 project-box-content-header" >
            <p class="box-content-header">{{item.Cod}}</p>
          </div>

          <div class="col-md-3 col-xs-12 project-box-content-header" >
            <p class="box-content-header">{{item.Nome}}</p>
          </div>

          <div class="col-md-5 col-xs-12 project-box-content-header" >
            <p class="box-content-header">{{item.Dominio}}</p>
          </div>

          <div class="col-md-3 col-xs-12 project-box-footer">

            <a [routerLink]="['/sistema-form', item.Cod]" class="btn-sistemas custom-btn-light mx-2 my-2" placement="top" ngbTooltip="Editar"
              style="border-radius: 20px;">
              <i class="fa fa-fw fa-edit"></i>
            </a>

            <button class="btn-sistemas custom-btn-light mx-2 my-2" (click)="open(content, item)" placement="top" ngbTooltip="Excluir"
              style="border-radius: 20px;">
              <i class="fa fa-fw fa-trash"></i>
            </button>


          </div>

        </div>
      </div>

    </div>

    <ng-template #noData>
      <div class="project-box-wrapper">
        <div class="project-box" style="background-color: #d5deff;">

          <div class="box-dominio-wrapper">
            <p class="box-progress-header">Não há sistema disponíveis.</p>
          </div>

        </div>
      </div>
    </ng-template>

  </div>

  <!-- <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
      (pageChange)="loadPage($event)">
    </ngb-pagination>
  </div> -->

</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Deseja excluir registro?</h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>

  </div>
  <div class="modal-body">
    <p>Nome: {{selected.Nome}}</p>
    <p>Domínio: {{selected.Dominio}}</p>
  </div>
  <div class="modal-footer">

    <div class="row">
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" style="height: 40px;" class="custom-btn custom-btn-vermelho" (click)="modal.close()">Confirmar</button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-grid gap-2">
          <button type="button" style="height: 40px;" class="custom-btn "
            (click)="modal.dismiss('Cancelar')">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>