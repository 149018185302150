import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PessoaFisica } from '../../model/RH/pessoa-fisica';
import { Paginator } from '../../model/paginator';
import { tap, catchError } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { HttpCustomService } from 'src/app/services/http-custom.service';

@Injectable({
  providedIn: 'root'
})
export class PessoasFisicasService {

  private readonly apiUrl = `${ environment.apiUrl }/PessoaFisica`;

  constructor(
    private http: HttpCustomService, 
    private errorService: ErrorService
  ) { }

  // getById(numero: number): Observable<PessoasFisicas> {
  //   return this.http
  //     .get<PessoasFisicas>(`${ this.apiUrl }/${ numero }`)
  //     .pipe(
  //       tap((data: any) => console.log(`Obter sistemas usuario numero = ${numero}`)),
  //       catchError(this.errorService.handleError<PessoasFisicas>(` erro sistemas usuario getById`))
  //     );
  // }

  getPagination(search: string, sortColumn: string, sortDirection: string, page: number, perPage: number)
    : Observable<Paginator<PessoaFisica>> {

    const params = new HttpParams()
      .set('search', search)
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection)
      .set('perPage', perPage.toString())
      .set('page', page.toString());

    return this.http.get<Paginator<PessoaFisica>>(`${ this.apiUrl }/Pagination`, { params }).pipe(
      tap((data: any) => console.log('Obter PessoaFisica Pagination', data as Paginator<PessoaFisica>)),
      catchError(this.errorService.handleError('getPagination', []))
    );
  }

}
