import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { Sistema } from '../../../model/RH/sistema';
import { SistemaService } from 'src/app/api/RH/sistema.service';
import { CustomLogging } from '../../../utils/CustomLogging';

@Component({
  selector: 'app-sistema-form',
  templateUrl: './sistema-form.component.html',
  styleUrls: ['./sistema-form.component.scss']
})

export class SistemaFormComponent implements OnInit {

  cod: number;
  saved: boolean = false;
  submitted = false;
  title = "Novo Sistema";
  msgErro = "Erro ao recuperar os dados";

  modelForm = this.formBuilder.group(
    {
      Cod: [0],
      Nome: ['', [Validators.required, Validators.maxLength(200)]],
      Dominio: ['', [Validators.required, Validators.maxLength(200)]],
      Ativo: [false, [Validators.required, Validators.maxLength(200)]],
    }
  );

  get f() { return this.modelForm.controls; }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private sistemaService: SistemaService
  ) { }

  ngOnInit() {
    this.cod = this.route.snapshot.params['id'];

    if (!this.cod) {
      this.title = "Novo Sistema"
      return;
    }

    if (this.cod) {
      this.sistemaService.getById(this.cod).subscribe(
        (data) => {
          if (data != undefined) {
            this.title = "Editar Sistema"
            this.modelForm.patchValue(data);
          }
          else {
            new CustomLogging(this.msgErro, 'red', '0.8rem').log();
          }
        },
        (error) => { //Called when error    
          new CustomLogging("Erro do subscribe de dados", 'red', '0.8rem').log();
        });
      // this.router.navigateByUrl('/sistema-form', { replaceUrl: true })   
    }
  }

  toggleNavbar() {
    $("#wrapper").toggleClass("toggled");
  }

  newSistema(): void {
    if (this.cod) {
      this.router.navigate(['/sistema-form']);
    }
    else {
      this.submitted = false;
      this.saved = false;
      this.title = "Novo Sistema";
      this.modelForm.reset();
      this.modelForm.patchValue({ Cod: 0 });
    }
  }

  save() {
    this.sistemaService.create(this.modelForm.value).subscribe(
      (data) => { this.saved = true; },
      (erro) => { console.log(erro) }
    );
  }

  update() {
    this.sistemaService.update(this.modelForm.value.Cod, this.modelForm.value).subscribe(
      (data) => { this.saved = true; },
      (erro) => { console.log(erro) }
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.modelForm.invalid) {
      return;
    }

    if (this.modelForm.value.Cod) {
      this.update();
    }
    else {
      this.save();
    }
  }

}