// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ambiente: "Homologação", // não retirar uso para informação
  message: "welcome to staging",
  apiUrl: "https://hom.gw.sgi.ms.gov.br/k0525/sai/v1",
  BASE_URL: "https://hom.sai.ms.gov.br",
  api_token_credentials: {
    api_token:"https://hom.id.ms.gov.br/auth/realms/ms/protocol/openid-connect/token",
    grant_type: "client_credentials",
    client_id: "k0586",
    client_secret: "8f0ead42-80a3-4a85-9863-09f69db606fa",
  },
};