import * as $ from 'jquery';
import { Component, OnInit, ViewChild } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuService } from 'src/app/api/RH/menu.service';
import { ToastService } from 'src/app/toast/toast.service';
import { ITreeOptions, TreeNode, IActionMapping } from '@circlon/angular-tree-component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SistemaUsuarioRotaOperacao } from 'src/app/model/RH/sistema-usuario-rota-operacao';
import { UsuarioSistema } from 'src/app/model/RH/usuario-sistema';
import { RotaUsuarioService } from 'src/app/api/RH/rota-usuario.service';
import { RotaResponse } from 'src/app/model/poco/rota-response';
import { MenuUsuarioResponse } from 'src/app/model/poco/menu-usuario-response';

@Component({
  selector: 'app-usuario-menu',
  templateUrl: './usuario-menu.component.html',
  styleUrls: ['./usuario-menu.component.scss']
})
export class UsuarioMenuComponent implements OnInit {

  constructor( 
    private route: ActivatedRoute,
    private router:Router,
    private menuService: MenuService,
    private rotaUsuarioService: RotaUsuarioService,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    private formBuilder: UntypedFormBuilder
    ) { }

  //@ViewChild('tree', {static: false}) tree;
  state$: any;
  item: UsuarioSistema;
  title ="Usuário Menu";
  selected: any;
  nodes: MenuUsuarioResponse[];
  selectedNode: MenuUsuarioResponse;
  //menuPerfil$: Observable<SistemaUsuarioMenuPerfil[]>;
  //sistemaUsuarioMenuPerfil: SistemaUsuarioMenuPerfil[];
  deep: boolean = false;
  submitted: boolean = false;
  
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node) => {
          this.nodeSelected(node);
        }
      }
    },
    idField: 'Cod',
    displayField: 'Descricao',
    childrenField: 'Children',
    nodeClass: (node: TreeNode) => {
      return node.data.IsPublic ? "public-class" : "";
    },
  };
  
  reactiveForm = this.formBuilder.group(
    {
      Id: [0],
      Name: ['', [Validators.required, Validators.maxLength(80)]],
      Email: ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
      Number: ['', [Validators.required, Validators.maxLength(15)]],
      IsActive: [false, Validators.required],
    }
  );

  get f() { return this.reactiveForm.controls; }

  ngOnInit() {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.state$.subscribe(data => {

      this.item = data["userMenu"];

      if(!this.item){
        this.router.navigate(['/usuario-lista']);
        return;
      }

      this.carregaSistemaUsuarioMenu();
   
    });
  }

  back(): void {

    if(!this.item){
      this.router.navigate(['/usuario-lista']);
      return;
    }
    this.router.navigate([`/usuario-form/${this.item.PfisNumero}`]);
  }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  nodeSelected(node): void {

    this.selectedNode = node.data;
    //this.spinner.show();

    //this.menuPerfil$ = this.loadTipoPerfilToSistemaUsuarioMenuPerfil(node.data.Id, this.item.SistCod, this.item.PfisNumero);

    // this.sistemaUsuarioMenuPerfilService
    //   .getProfileAll(this.item.SistCod, this.item.PfisNumero, this.selectedNode.Id)
    //   .subscribe(data => {

    //     this.spinner.hide();
    //     this.sistemaUsuarioMenuPerfil = data;

    //   }, err => {

    //     this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });
    //     this.spinner.hide();

    //   });
  }

  carregaSistemaUsuarioMenu(): void {

    this.spinner.show();

    this.menuService
      .getByUser(this.item.PfisNumero, this.item.SistCod)
      .subscribe((data: MenuUsuarioResponse[]) => {

        this.nodes = data;
        this.selectedNode = {} as MenuUsuarioResponse;
        this.spinner.hide();

      }, err => {

        this.selectedNode = {} as MenuUsuarioResponse;
        this.spinner.hide();
        this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });

      });
  }

  selectChange(menu: MenuUsuarioResponse, rota: RotaResponse, select: any) : void {
    console.log('selectChange:'+select.target.value);
    this.spinner.show();

    let instance : SistemaUsuarioRotaOperacao = {
      PfisNumero: this.item.PfisNumero,
      SistCod: this.item.SistCod,
      RotaMenuCod: menu.Cod,
      RotaCod: rota.Cod,
      OperRotaCod: select.target.value
    } as SistemaUsuarioRotaOperacao;

    if(!rota.OperacaoCod){
      if(instance.RotaMenuCod != null){
        this.rotaUsuarioService
        .postTree(instance)
        .subscribe(data => {
  
          this.spinner.hide();
          this.toastService.show('Registro incluído com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.carregaSistemaUsuarioMenu();
  
        }, err => {
  
          this.spinner.hide();
          this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });
  
        });
      }
    }
    else{
      if(instance.OperRotaCod != 0){
        this.rotaUsuarioService
        .putTree(instance.PfisNumero, instance.SistCod, instance.RotaMenuCod, instance.RotaCod, instance)
        .subscribe(data => {
  
          this.spinner.hide();
          this.toastService.show('Registro alterado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.carregaSistemaUsuarioMenu();
  
        }, err => {
  
          this.spinner.hide();
          this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });
  
        });
      }
      else{
        this.rotaUsuarioService
        .deleteTree(instance.PfisNumero, instance.SistCod, instance.RotaMenuCod, instance.RotaCod)
        .subscribe(data => {
  
          this.spinner.hide();
          this.toastService.show('Registro deletado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
          this.carregaSistemaUsuarioMenu();
  
        }, err => {
  
          this.spinner.hide();
          this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });
  
        });
      }
    }

    
  }

  check(node: any, checked : boolean): void {

    this.spinner.show();

    // if(checked){

    //   let instance : SistemaUsuarioMenu = {
    //     MenuId : node.id,
    //     SystemUserEmpCod : this.item.EmpCod,
    //     SystemUserSystemId: this.item.SystemId,
    //     SystemUserUorgCod: this.item.UorgCod,
    //     SystemUserUserId: this.item.UserId
    //   } as SistemaUsuarioMenu;

    //   this.sistemaUsuarioMenuService
    //     .postTree(instance)
    //     .subscribe(data => {

    //       this.spinner.hide();
    //       this.toastService.show('Registro alterado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
    //       this.carregaSistemaUsuarioMenu();

    //     }, err => {

    //       this.spinner.hide();
    //       this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });

    //     });
    // }
    // else{

    //   this.sistemaUsuarioMenuService
    //     .deleteTree(this.item.SystemId, this.item.UserId, this.item.EmpCod, this.item.UorgCod, node.id)
    //     .subscribe(data => {

    //       this.spinner.hide();
    //       this.toastService.show('Registro alterado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
    //       this.carregaSistemaUsuarioMenu();
          
    //     }, err => {

    //       this.spinner.hide();
    //       this.toastService.show('Registro não pode ser carregado.', { classname: 'bg-danger text-light', delay: 10000 });

    //     });
    // }
    
  }

  // loadTipoPerfilToSistemaUsuarioMenuPerfil(menuId: number, systemId: number, empCod: number, uorgCod: number, userId: number): Observable<SistemaUsuarioMenuPerfil[]> {
  //   return this.tipoPerfilService.getAll()
  //     .pipe(
  //       map((e: TipoPerfil[], i: number) => {
  //         return e.map(x => {
  //           return {
  //             SystemUserMenuMenuId: menuId,
  //             SystemUserMenuSystemId: systemId,
  //             SystemUserMenuUserId: userId,
  //             SystemUserMenuEmpCod: empCod,
  //             SystemUserMenuUorgCod: uorgCod,
  //             TypeProfileId: x.Id,
  //             TypeProfile: x
  //           } as SistemaUsuarioMenuPerfil
  //         })
  //       },
  //       catchError((e: any[]) => throwError(e)))
  //     );


  // }

}
