import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCustomService {

  constructor(
    private http: HttpClient
  ) { }

  private retryError<T> (subscriber: Subscriber<T>, method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }){
    return (error: HttpErrorResponse) => {
      switch (error instanceof HttpErrorResponse) {
        case error.status == 401: {
          // subscriber.error(error);
          // subscriber.complete(); 
          console.log('http-custom retry')
          this.retry<T>(subscriber, method, url, options);
          break;
        }
        default: {
          subscriber.error(error);
          subscriber.complete(); 
          break;
        }
      }
    }
  }
  private retry<T> (subscriber: Subscriber<T>, method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }){
    this.http.request<T>(method, url, options)
      .subscribe(
        (retryResult) => {    
          subscriber.next(retryResult);
          subscriber.complete();    
        },
        (retryError) => {
          subscriber.error(retryError);
          subscriber.complete();    
        } 
      ); 
  }

  request<T>( method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }): Observable<T>  {

    const observable = Observable.create(

      (subscriber: Subscriber<T>) => {

        options = options ? options : {};

        // if(options.withCredentials){
        //   options.headers =  this.defaultHttpHeaders.httpOptionsCors().headers;
        //   options.withCredentials = false;
        // }
        // else
          //options.headers =  this.defaultHttpHeaders.httpOptions().headers;

        this.http.request<T>(method, url, options)
          .subscribe(
            (result) => {

              subscriber.next(result);
              subscriber.complete();

            }, 
            this.retryError(subscriber, method, url, options)
            
          );
      }

    );

    return observable;
  }

  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.request<T>('GET', url, options );
  }

  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }){

    let option : {
      body?: any;
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
        [param: string]: string | string[];
      };
      responseType?: 'json';
      reportProgress?: boolean;
      withCredentials?: boolean;
    } = {};

    option.body = body;

    if(options) {

      option.headers = options.headers;
      option.observe = options.observe;
      option.params = options.params;
      option.responseType = options.responseType;
      option.reportProgress = options.reportProgress;
      option.withCredentials = options.withCredentials;
      
    }

    return this.request<T>('POST', url, option );
  }

  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    
    let option : {
      body?: any;
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
        [param: string]: string | string[];
      };
      responseType?: 'json';
      reportProgress?: boolean;
      withCredentials?: boolean;
    } = {};

    option.body = body;
    
    if(options) {

      option.headers = options.headers;
      option.observe = options.observe;
      option.params = options.params;
      option.responseType = options.responseType;
      option.reportProgress = options.reportProgress;
      option.withCredentials = options.withCredentials;

    }

    return this.request<T>('PUT', url, option );
  }

  delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.request<T>('DELETE', url, options );
  }
  
}
