import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SenhaDeAcessoService } from 'src/app/api/senha-de-acesso.service';
import { ValidateResponse } from 'src/app/model/poco/validate-response';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToastService } from 'src/app/toast/toast.service';
import { CustomValidations } from 'src/app/utils/CustomValidations';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-esqueci-minha-senha",
  templateUrl: "./esqueci-minha-senha.component.html",
  styleUrls: ["./esqueci-minha-senha.component.scss", "./../../../../stylesLogin.scss"],
})
export class EsqueciMinhaSenhaComponent implements OnInit {

  typeSelected: string;
  public modelForm: UntypedFormGroup;
  captcha: string;

  locationPermission: boolean = false;
  existePessoaFisica: boolean = false;
  sucessoRecuperarSenha: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public toastService: ToastService,
    private senhaDeAcessoService: SenhaDeAcessoService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService
  ) {
    this.typeSelected = "pacman";

    this.modelForm = this.formBuilder.group({
      Cpf: new FormControl<string|null>(null, [Validators.required, CustomValidations.ValidaCpf]),
      RG: new FormControl<string|null>(null, [Validators.required]),
      DataNascimento: new FormControl<Date|null>(null),
      Captcha: new FormControl<number|null>(null, [Validators.required]),
      Latitude: new FormControl<number|null>(null, [Validators.required]),
      Longitude: new FormControl<number|null>(null, [Validators.required])
    });
  }
  
  get f() {
    return this.modelForm.controls;
  }

  ngOnInit() {
    this.getLocation();
  }

  getLocation = function () {

    if(environment.ambiente == 'Desenvolvimento'){
      this.locationPermission = true;
      this.modelForm.patchValue({
        Latitude: -20.4537856,
        Longitude: -54.591488
      });
      //console.log('Geolocation fixo')
      return;
    }
    console.log(navigator.geolocation)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locationPermission = true;
          this.modelForm.patchValue({
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude
          });
        }, 
        (error) => {
          this.locationPermission = false;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("Usuário rejeitou a solicitação de Geolocalização.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Localização indisponível.");
              break;
            case error.TIMEOUT:
              console.log("A requisição expirou.");
              break;
            default:
              console.log("Algum erro desconhecido aconteceu.");
              break;
          }
        });
    }
    else {
      this.locationPermission = false;
      console.log("Geolocation is not supported by this browser.");
    }
  }

  changeCPF(){
    if(this.modelForm.controls.Cpf.errors == null){
      this.spinner.show();
      this.senhaDeAcessoService.ExistePessoaFisica(this.modelForm.value.Cpf)
      .subscribe(
        (value: ValidateResponse): void => {
          this.spinner.hide();
          this.existePessoaFisica = value.Sucesso;
          if(this.existePessoaFisica)
            this.getCaptcha();
        },
        (erro: any) => {
          this.spinner.hide();
          this.toastService.show('Ocorreu um erro inesperado', { classname: 'bg-warning ', delay: 10000 });
        }
      );
    }
    else{
      this.existePessoaFisica = false;
    }
  }

  getCaptcha(){
    this.spinner.show()
    this.senhaDeAcessoService.Captcha()
      .subscribe(
        (value: any): void => {
          this.localStorageService.set('Captcha', value.Key)
          this.captcha = value.captcha;
          this.spinner.hide();

          setTimeout(()=>{
            this.getCaptcha();
          }, 60000);
        },
        (erro) => {
          this.localStorageService.remove('Captcha')
          this.spinner.hide();
        }
      );
  }

  onSubmit() {
    if (this.modelForm.invalid)
      return;
    this.spinner.show();
    this.senhaDeAcessoService.EsqueciMinhaSenha(this.modelForm.value)
    .subscribe(
      (resp: ValidateResponse): void => {
        if(resp.Sucesso){
          this.sucessoRecuperarSenha = true;
          //this.toastService.show('Sucesso modelo', { classname: 'bg-success text-light', delay: 10000 });
        }
        else{
          this.toastService.show(resp.Erros[0], { classname: 'bg-warning ', delay: 10000 });
          this.getCaptcha();
        }
        this.spinner.hide();
      },
      (erro) => {
        this.spinner.hide();
        console.log(erro)
        for(var key in erro.error){
          if(key == 'errors'){
            for(var errors in erro.error[key])
            this.toastService.show(erro.error[key][errors], { classname: 'bg-warning ', delay: 10000 });
          }
        }
        this.getCaptcha();
      },
      () => {
        this.spinner.hide();
        //this.toastService.show('Sucesso', { classname: 'bg-warning ', delay: 10000 });
      }
    );
  }

  back() {
    this.router.navigate(["/login"]);
  }
}