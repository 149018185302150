import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SenhaDeAcessoService } from 'src/app/api/senha-de-acesso.service';
import { ValidateResponse } from 'src/app/model/poco/validate-response';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToastService } from 'src/app/toast/toast.service';
import { CustomValidations } from 'src/app/utils/CustomValidations';

@Component({
  selector: "app-recuperar-senha",
  templateUrl: "./recuperar-senha.component.html",
  styleUrls: ["./recuperar-senha.component.scss"],
})
export class RecuperarSenhaComponent implements OnInit {

  typeSelected: string;
  
  locationPermission: boolean = false;
  captcha: string;

  modelForm: UntypedFormGroup = this.formBuilder.group({
    Email: new FormControl<string|null>(null, Validators.required),
    Token: new FormControl<string|null>(null, Validators.required),
    Cpf: new FormControl<string|null>(
      null,
      [Validators.required, CustomValidations.ValidaCpf]
    ),
    NovaSenha: new FormControl<string|null>(
      null,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8),
        CustomValidations.PadraoSenha
      ]
    ),
    NovaSenhaConfirma: new FormControl<string|null>(
      null,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      ],
    ),
    Captcha: new FormControl<number|null>(null, Validators.required),
    Latitude: new FormControl<number|null>(null, Validators.required),
    Longitude: new FormControl<number|null>(null, Validators.required)
  },
  {validators: CustomValidations.SenhasCombinam} as AbstractControlOptions);

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public toastService: ToastService,
    private senhaDeAcessoService: SenhaDeAcessoService,
    private localStorageService: LocalStorageService
  ) {
    this.typeSelected = "pacman";
  }

  ngOnInit() {
    this.modelForm.patchValue({
      Email: this.route.snapshot.queryParams['email'],
      Token: this.route.snapshot.queryParams['token']
    });
    this.ValidarRecuperarSenha();
    // if(
    //   (this.modelForm.value.Email??'').length == 0 ||
    //   (this.modelForm.value.Token??'').length == 0
    //   )
    //   this.router.navigate(['/esqueci-minha-senha']);
    // this.modelForm.value.Email = this.route.snapshot.queryParams['token'];
    // this.modelForm.value.Token = this.route.snapshot.queryParams['email'];

    //this.router.navigate(['/login']);
    this.getLocation();
  }

  getLocation = function () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locationPermission = true;
          this.modelForm.patchValue({
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude
          });
          this.getCaptcha();
        }, 
        (error) => {
          this.locationPermission = false;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("Usuário rejeitou a solicitação de Geolocalização.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Localização indisponível.");
              break;
            case error.TIMEOUT:
              console.log("A requisição expirou.");
              break;
            default:
              console.log("Algum erro desconhecido aconteceu.");
              break;
          }
        });
    }
    else {
      this.locationPermission = false;
      console.log("Geolocation is not supported by this browser.");
    }
  }
  
  get f() {
    return this.modelForm.controls;
  }
  
  ValidarRecuperarSenha() {
    this.spinner.show();
    this.senhaDeAcessoService.ValidarRecuperarSenha(this.modelForm.value.Token, this.modelForm.value.Email)
    .subscribe(
      (resp: ValidateResponse): void => {        
        if(resp.Sucesso){
          this.modelForm.patchValue({
            Cpf: resp.Resposta
          });
          //this.toastService.show('Sucesso modelo', { classname: 'bg-success text-light', delay: 10000 });
        }
        else{
          this.toastService.show(resp.Erros[0], { classname: 'bg-warning ', delay: 10000 });
          this.router.navigate(['/login']);
        }
        //this.router.navigate(this.redirectAfterLogin);
        this.spinner.hide();
      },
      (erro) => {
        this.router.navigate(['/EsqueciMinhaSenha']);
        this.spinner.hide();
      }
    );
  }

  getCaptcha(){
    this.senhaDeAcessoService.Captcha().subscribe(
      (value: any): void => {
        console.log(value);
        this.localStorageService.set('Captcha', value.Key)
        this.captcha = value.captcha;
        this.spinner.hide();

        setTimeout(()=>{
          this.getCaptcha();
        }, 60000);
      },
      (erro) => {
        this.spinner.hide();
      }
    );
  }

  onSubmit() {
    if (this.modelForm.invalid)
      return;
    this.spinner.show();
    this.senhaDeAcessoService.RecuperarSenha(this.modelForm.value)
    .subscribe(
      (resp: ValidateResponse): void => {
        this.spinner.hide();
        
        if(resp.Sucesso) {
          this.toastService.show('Senha alterada com Sucesso!', { classname: 'bg-success text-light', delay: 10000 });
          this.router.navigate(['/login']);
        }
        else {
          this.toastService.show(resp.Erros[0], { classname: 'bg-warning ', delay: 10000 });
          this.getCaptcha();
        }
      },
      (erro) => {
        this.spinner.hide();
        this.toastService.show(erro.erros[0], { classname: 'bg-warning ', delay: 10000 });
        this.getCaptcha();
      }
    );
  }

  back() {
    this.router.navigate(["/login"]);
  }
}